import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import { Strings } from '../constants/Localization';
import FooterMenu from '../Components/FooterMenu';
import Constant from '../constants/baseConstants';
import baseConstants from '../constants/baseConstants';
import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import AlertDialog from '../Components/AlertDialog';
import { getPriceFormat } from "../Utils/utils";
import {setSecureData, getSecureData} from '../Utils/utils';

import AOS from 'aos';
import 'aos/dist/aos.css';

var mOrderId = 0;
class OrderDetails extends Component {

    constructor(props) {
        super(props)

        //Get order id from order listing page
        //mOrderId = this.props.location.orderId;
        //Getting order id from local storage
        // mOrderId = localStorage.getItem(baseConstants.PRE_ORDER_ID);
        mOrderId = getSecureData(baseConstants.PRE_ORDER_ID);
        console.log("orderId :" + mOrderId)
        this.state = {
            orderDetailArray: [],
            errorMessage: '',
            languageType: 1, //default language type

            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            shouldMove: false,
            objItem: {},

        }
    }

    componentDidMount() {
        //Init animation duration
        AOS.init({
            duration: 1000
        })
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // let userObject = localStorage.getItem(Constants.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
        }
        else {
            this.props.history.push({ pathname: '/' })
            return
        }
        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        setTimeout(() => {
            console.log("call order details api :")
            this.props.orderDetailsApi(mOrderId, this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var result = this.props.data.result;
                    if (result != null) {
                        var orderArray = result.orderedItems;
                        if (orderArray != null && orderArray.length > 0) {
                            this.setState({ orderDetailArray: orderArray })
                        }
                        else {
                            this.setState({ errorMessage: Strings.lbl_no_order_react })
                        }
                    }
                }
                else {
                    this.setState({ errorMessage: this.props.error })
                }
            })
        }, 500);
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my order page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        console.log('language type  :' + languageType);
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType });
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    onReorderClick() {
        this.props.callReOrderInfoAPI(mOrderId, this.state.languageType).then(() => {
            if (this.props.data.status == 1) {
                var result = this.props.data.result;

                if (result.orderedItems.length > 0) {
                    //check cart item location and reorder item location 
                    // if (result.orderedItems[0].locationId == JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT)).locationId) {
                    if (result.orderedItems[0].locationId == getSecureData(Constant.LOCATIONOBJECT).locationId) {
                        result.orderedItems.map(items => {
                            // let cartData = JSON.parse(localStorage.getItem(Constant.USERCART))
                            let cartData = getSecureData(Constant.USERCART)

                            items.PriceList[0].isSelect = true
                            items['menuItemName'] = items['itemname']
                            items['menuitemId'] = items['orderitemId']
                            items['imgurl'] = items['imageurl']
                            items['totalQty'] = items['qty']
                            items['totalPrice'] = items['totalprice']
                            items['isReOrderedItem'] = true

                            if (cartData != null) {
                                if (cartData.length > 0) {
                                    cartData.push(items)
                                    // localStorage.setItem(Constant.USERCART, JSON.stringify(cartData))
                                    setSecureData(Constant.USERCART, cartData)
                                } else {
                                    // localStorage.setItem(Constant.USERCART, JSON.stringify([items]))
                                    setSecureData(Constant.USERCART, [items])
                                }
                            } else {
                                // localStorage.setItem(Constant.USERCART, JSON.stringify([items]))
                                setSecureData(Constant.USERCART, [items])
                            }

                        })
                        this.setState({ shouldMove: true, isAlertVisible: true, alertMessage: Strings.error_msg_item_added_react, isMultiButton: true, btnLeft: Strings.Ok_web, btnRight: Strings.btn_view_order_react })
                        // if (!JSON.parse(localStorage.getItem(Constant.ORDERTYPE))) {
                        if (!getSecureData(Constant.ORDERTYPE)) {
                            // localStorage.setItem(Constant.ORDERTYPE, JSON.stringify('Delivery'))
                            setSecureData(Constant.ORDERTYPE, 'Delivery')
                        }
                    } else {

                        result.orderedItems.map(items => {

                            items.PriceList[0].isSelect = true
                            items['menuItemName'] = items['itemname']
                            items['menuitemId'] = items['orderitemId']
                            items['imgurl'] = items['imageurl']
                            items['totalQty'] = items['qty']
                            items['totalPrice'] = items['totalprice']
                            items['isReOrderedItem'] = true

                            this.setState({ objItem: items })
                        })

                        this.setState({ isAlertVisible: true, alertMessage: Strings.cart_from_different_location, isMultiButton: true, btnLeft: Strings.str_no, btnRight: Strings.yes })
                    }
                }

            } else {
                alert(this.props.data.message)
            }
        })
    }

    onRightClick() {
        if (this.state.shouldMove) {
            this.props.history.push({ pathname: '/CheckoutDelivery' })
        } else {
            localStorage.removeItem(Constant.USERCART)
            // localStorage.setItem(Constant.USERCART, JSON.stringify([this.state.objItem]))
            setSecureData(Constant.USERCART, [this.state.objItem])

            // let locationList = JSON.parse(localStorage.getItem(Constant.LOCATIONLISTOBJECT))
            let locationList = getSecureData(Constant.LOCATIONLISTOBJECT)
            if (locationList) {
                let obj = locationList.filter(location => (location.locationId == this.state.objItem.locationId))
                console.log('location list ------ ', obj)
                if (obj.length > 0) {
                    // localStorage.setItem(Constant.LOCATIONOBJECT, JSON.stringify(obj[0]))
                    setSecureData(Constant.LOCATIONOBJECT, obj[0])
                }
            }

            // if (!JSON.parse(localStorage.getItem(Constant.ORDERTYPE))) {
            if (!getSecureData(Constant.ORDERTYPE)) {
                // localStorage.setItem(Constant.ORDERTYPE, JSON.stringify('Delivery'))
                setSecureData(Constant.ORDERTYPE, 'Delivery')
            }
            this.setState({ isAlertVisible: false })
        }

    }

    render() {
        return (
            <div className="white dishes">

                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}

                {/* Old header menu */}
                {/* <HeaderMenu /> */}
                <HeaderWhiteMenu logoType={1}
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true} />
                <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={this.state.isMultiButton} btnLeftClick={() => { this.setState({ isAlertVisible: false }) }} btnRightClick={() => { }} btnLeftTitle={this.state.btnLeft} btnRightTitle={this.state.btnRight} btnRightClick={() => { this.onRightClick() }} />
                <div className="inner-banner">
                    <img src="/images/dishes-banner.jpg" className="w-100" />
                </div>
                <div className="content_wrap">
                    <div className="dishes-page">
                        <div className="row">
                            <div className="col-md-12 col-md-12">
                                {/* <!-- Tab panes --> */}
                                <div className="tab-content">
                                    <div className="tab-pane active" id="home-v">
                                        <div className="row">
                                            {
                                                this.setOrderDetailsView()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.setSubTotalView()
                        }
                        {
                            this.setDiscount()
                        }
                        {
                            this.setCompanyDiscountView()
                        }
                        {
                            this.setDeliveryCharges()
                        }
                        {
                            this.setAvocadoDiscount()
                        }
                        {
                            this.setPackagingCharges()
                        }
                        {
                            this.setTotalAmount()
                        }
                        <div className="buttons-checkout">
                            <a href="#" className="btn btn-primary btn-medium" onClick={() => this.onReorderClick()}>{Strings.str_reorder}</a>
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }

    //Setting SubTotal Amount
    setSubTotalView() {
        let mSubTotal = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            for (let i = 0; i < this.state.orderDetailArray.length; i++) {
                if (mSubTotal == 0) {
                    mSubTotal = this.state.orderDetailArray[i].totalprice;
                }
                else {
                    mSubTotal = mSubTotal + this.state.orderDetailArray[i].totalprice;
                }
            }

            return (
                <div>
                    <h3 className='totalAmount'>{Strings.sub_total_order_amount_web + " : " + getPriceFormat(mSubTotal.toFixed(2))} </h3>
                </div>
            )
        }
    }

    //Setting First Order Discount
    setDiscount() {
        let discount = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            if (this.state.orderDetailArray[0].discount > 0) {
                discount = this.state.orderDetailArray[0].discount;
                return (
                    <div>
                        <h3 className='totalAmount'>{Strings.lbl_discount_react + " : -" + getPriceFormat(discount)} </h3>
                    </div>
                )
            }
            else {
                return;
            }
        }
    }

    //Setting company discount
    setCompanyDiscountView() {
        let totalAmount = 0;
        let discount = 0;
        let companyDiscount = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            discount = this.state.orderDetailArray[0].discount;
            companyDiscount = this.state.orderDetailArray[0].payAmountByCompany;
            for (let i = 0; i < this.state.orderDetailArray.length; i++) {
                if (totalAmount == 0) {
                    totalAmount = this.state.orderDetailArray[i].totalprice;
                }
                else {
                    totalAmount = totalAmount + this.state.orderDetailArray[i].totalprice;
                }
            }
            totalAmount = totalAmount - discount;
            if (totalAmount >= companyDiscount) {
                if (companyDiscount > 0) {
                    return (
                        <div>
                            <h3 className='totalAmount'>{Strings.str_company_discount + " : -" + getPriceFormat(companyDiscount.toFixed(2))} </h3>
                        </div>
                    )
                }
                else {
                    return;
                }
            }
            else {
                return (
                    <div>
                        <h3 className='totalAmount'>{Strings.str_company_discount + " : -" + getPriceFormat(totalAmount.toFixed(2))} </h3>
                    </div>
                )
            }
        }
    }

    setAvocadoDiscount() {
        if(this.state.orderDetailArray.length > 0) {
            let disc = this.state.orderDetailArray[0].avacadoRedeemDiscount
            if(disc > 0) {
                return (
                    <div>
                            <h3 className='totalAmount'>{Strings.str_redeemed_avocados + " : -" + getPriceFormat(disc.toFixed(2))} </h3>
                        </div>
                )
            }
        }

    }

    //Setting Delivery Charges
    setDeliveryCharges() {
        let deliveryCharges = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            if (this.state.orderDetailArray[0].deliveryCharges > 0) {
                deliveryCharges = this.state.orderDetailArray[0].deliveryCharges;
                return (
                    <div>
                        <h3 className='totalAmount'>{Strings.lbl_delivery_charge_react + " : " + getPriceFormat(deliveryCharges.toFixed(2))} </h3>
                    </div>
                )
            } else {
                return;
            }
        }

    }

    //Setting Packaging Charges
    setPackagingCharges() {
        let packgingCharges = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            if (this.state.orderDetailArray[0].packagingCharge > 0) {
                packgingCharges = this.state.orderDetailArray[0].packagingCharge;
                return (
                    <div>
                        <h3 className='totalAmount'>{Strings.packaging_charge_web + " : " + getPriceFormat(packgingCharges.toFixed(2))} </h3>
                    </div>
                )
            }
            else {
                return;
            }
        }
    }

    //Setting total amount
    setTotalAmount() {
        let totalAmount = 0;
        let deliveryCharges = 0;
        let packgingCharges = 0;
        let discount = 0;
        let companyDiscount = 0;
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            deliveryCharges = this.state.orderDetailArray[0].deliveryCharges;
            packgingCharges = this.state.orderDetailArray[0].packagingCharge;
            discount = this.state.orderDetailArray[0].discount;
            companyDiscount = this.state.orderDetailArray[0].payAmountByCompany;
            let avocadoDiscount =  this.state.orderDetailArray[0].avacadoRedeemDiscount;
            for (let i = 0; i < this.state.orderDetailArray.length; i++) {
                if (totalAmount == 0) {
                    totalAmount = this.state.orderDetailArray[i].totalprice;
                }
                else {
                    totalAmount = totalAmount + this.state.orderDetailArray[i].totalprice;
                }
            }

            totalAmount = totalAmount - discount;
            if (totalAmount >= companyDiscount) {
                totalAmount = totalAmount - companyDiscount - avocadoDiscount + deliveryCharges + packgingCharges;
                return (
                    <div>
                        <h3 className='totalAmount'>{Strings.total_order_amount_web + " : " + getPriceFormat(totalAmount.toFixed(2))} </h3>
                    </div>
                )
            }
            else {
                totalAmount = 0;
                totalAmount = totalAmount - avocadoDiscount + deliveryCharges + packgingCharges;
                return (
                    <div>
                        <h3 className='totalAmount'>{Strings.total_order_amount_web + " : " + getPriceFormat(totalAmount.toFixed(2))} </h3>
                    </div>
                )
            }
        }
    }

    //This method use bind order details 
    //like menuname,price,qty
    setOrderDetailsView() {
        if (this.state.orderDetailArray != null && this.state.orderDetailArray.length > 0) {
            {
                return (
                    this.state.orderDetailArray.map((item, index) => (
                        <div className="col-lg-4 col-md-6 all-product" key={index}>
                            <div className="prd-s" data-aos='zoom-in'>
                                <div className="prd-img"><a href="#"><img src={item.imageurl} /></a></div>
                                <div className="prd-cont">
                                    <h3>{item.itemname}</h3>
                                    {/* <p>{item.description}</p> Description */}
                                    <p>{getPriceFormat(item.subtotal.toFixed(2)) + " X " + item.qty}</p>
                                    <h6>{item.PriceList[0].type}</h6>
                                    {
                                        this.setOptionListView(item.PriceList[0].OptionList)
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                )
            }
        }
        else {
            return (
                <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <h2 style={{ textAlign: 'center' }}>{this.state.errorMessage}</h2>
                </div>
            )
        }
    }

    //This method use bind option parameter list with sorting
    setOptionListView = (OptionList) => {
        if (OptionList != null && OptionList.length > 0) {
            var subArray = [];
            for (let j = 0; j < OptionList.length; j++) {
                if (j == 0) {
                    subArray.push(OptionList[j].labelText)
                } else if (subArray.includes(OptionList[j].labelText) == false) {
                    subArray.push(OptionList[j].labelText)
                }
            }

            var arrReturn = []
            for (let k = 0; k < subArray.length; k++) {
                let myOptionList = OptionList.filter(data => (subArray[k] == data.labelText))
                var arrOpt = []
                myOptionList.map(opt => (
                    arrOpt.push(opt.type)
                ))
                arrReturn.push(
                    <div className="prod-cont" key={k}>
                        <div className="prd-s">
                            <div className="prd-img"></div>
                            <div className="prd-cont">
                                <h6>{subArray[k]}</h6>
                                <p>{arrOpt.join(',')}</p>
                            </div>
                        </div>
                    </div>
                )
            }
            return arrReturn
        }
        else {
            return;
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}
const mapDispatchToProps = dispatch => ({
    orderDetailsApi: (mOrderId, languageType) => dispatch(HGActions.GetOrderDetailsApi(mOrderId, languageType)),
    callReOrderInfoAPI: (OrderId, languageType) => dispatch(HGActions.initGetReOrderInfoAPI(OrderId, languageType)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);



import React, {Component} from 'react';
import { Strings } from '../constants/Localization';

export default class FooterMenu extends Component {

    render() {
        return (
            <footer>
                <div className="hg_footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-3 ftr_left">
                                <a href="#">
                                    <img src="/images/logo_white.png"/>
                                </a>
                                <div className="cl-lg-ft">
                                    <p>{Strings.lbl_part_group_react}</p>
                                    <a href="#" className="footerlogo">
                                        <img src="/images/logo-urban-gourmet-2.png" alt="logo urban gourmet" width="140" height="33"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 ftr_md">
                                <h6>{Strings.lbl_more_info_react}</h6>
                                <ul>
                                    <li><a href="https://honestgreens.com/contacto/" target="-blank">{Strings.lbl_contact_us_react}</a></li>
                                    <li><a href="mailto:jobs@honestgreens.com" target="-blank">{Strings.lbl_career_react}</a></li>
                                    <li><a href="https://honestgreens.com/privacy-policy/" target="-blank">{Strings.lbl_privacy_policy_react}</a></li>	 
                                    <li><a href="/OfficeRegistration">{Strings.lbl_apply_office_catering}</a></li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-3 ftr_sub">
                                <h6>{Strings.lbl_newslatter_react}</h6>
                                <a href="https://honestgreens.com/newsletter/" target="-blank" className="subscribe_btn">{Strings.lbl_subscribe_react}</a>
                            </div>
                            <div className="col-12 col-md-3 ftr_right">
                                <h6>{Strings.lbl_stay_connected_react}</h6>
                                <ul>
                                    <li><a href="https://www.instagram.com/honestgreens/" target="_blank"><img src="/images/insta.png" width="20" /></a></li>
                                    <li><a href="https://www.facebook.com/honestgreens.spain/" target="_blank">
                                        <img src="/images/fbicon.png" width="12"/></a></li>
                                </ul>
                                <a className="mail_link" href="mailto:hello@honestgreens.com">{Strings.lbl_honest_react}</a>
                            </div>
                        </div>    
                    </div>   
                </div>    
                <div className="copyright">
                    <div className="container">
                        <p><b>{Strings.lbl_honest_green_footer_react}</b> - {Strings.lbl_all_right_react}</p>
                    </div>
                </div>  
            </footer>
        )
    }
}




import React, {Component} from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";

import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';

import Constants from '../constants/baseConstants';
import {Strings} from '../constants/Localization';
import {getPriceFormat} from "../Utils/utils";
import {setSecureData, getSecureData} from '../Utils/utils';

class PaymentCardList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            arrayCards: [],
            selectedIndex: 0,

            cardName: '',
            cardNumber: '',
            mm: 0,
            yyyy: 0,
            cvv: '',
            errors : {
                cardNameError:'',
                cardNumberError:'',
                cardMMError:'',
                cardYYYYError:'',
                cardCVVError:'',
            },
        }
    }
      

    componentDidMount() {

        if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
            // let userObject = localStorage.getItem(Constants.USEROBJECT);
            let userObject = getSecureData(Constants.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
        }
        else {
            this.props.history.push({ pathname: '/' })
            return
        }

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constants.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
        // let mLanguageCode = localStorage.getItem(Constants.PRE_LANGUAGE_CODE);    
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType,})
            }
            else {
                if (mLanguageType != null) {
                Strings.setLanguage('es'); //Setting selected language code here
                this.setState({ languageType: mLanguageType,})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }
        setSecureData(Constants.ISCANCEL,true)
        // this.setState({arrayCards: getSecureData(Constants.PLACEDORDEROBJECT).lstcardDetails})
        

        // this.props.getSavedCards(getSecureData(Constants.USEROBJECT).customerId).then(() => {
        //     console.log("getSavedCards RESPONSE --------- ",this.props.data)
        //     if (this.props.data.status == 1) {
        //         if (this.props.data.result != null) {
        //             setTimeout(() => {
        //                 if (this.props.data.result.length > 0) {
        //                     // this.setState({arrayCards:this.props.data.result})
        //                 }
        //             }, 100)
        //         }
        //     } else {
        //         alert(this.props.data.message)
        //     }
        // })
        
    }

    onCheckoutClick() {
        //Check for checkout with card selection or with new card 
        if (this.state.arrayCards.length > 0) {

        } else {
            
            var errName = this.state.cardName.length == 0 ? Strings.str_card_name_error : '';
            var errNumber = this.state.cardNumber.length == 0 ? Strings.entercardnumber_web : '';
            // (errNumber == '') ? [errNumber = this.state.cardNumber.length < 16 ? Strings.Validcreditcardnumber_web : ''] : ''
            if (errNumber == '') {
                errNumber = this.state.cardNumber.length < 16 ? Strings.Validcreditcardnumber_web : ''
            }
            var errMm = this.state.mm == 0 ? Strings.selectexpirymonth_web : '';
            var errYYYY = this.state.yyyy == 0 ? Strings.expiryyear_web : '';
            var errCVV = this.state.cvv.length == 0 ? Strings.str_card_cvv_error : '';

            this.setState({ errors: {
                cardNameError:errName,
                cardNumberError:errNumber,
                cardMMError:errMm,
                cardYYYYError:errYYYY,
                cardCVVError:errCVV,
            } })

            setTimeout(() => {
                if (this.state.errors.cardNameError == '' && this.state.errors.cardNumberError == '' && this.state.errors.cardMMError == '' && this.state.errors.cardYYYYError == '' && this.state.errors.cardCVVError == '') {
                    console.info('Valid form')
                } else {
                    console.error('Not valid form')
                }
            }, 100)

        }
    }


    renderCardView() {
        
        //Check for card selection view or add new card details view
        if (this.state.arrayCards.length > 0) {
            var arrReturn = []
            this.state.arrayCards.map((card,index) => {
                arrReturn.push(
                    <li>
                        <button className="checked-btn" onClick={()=>{this.setState({selectedIndex:index})}}><img src={this.state.selectedIndex == index ? '/images/checked.png' : '/images/uncheck.png'}/></button>
                        <div>
                            <label className="container1">{Strings.CardNumber_web} : {card.cardNo} <br/> {Strings.card_type_web} : {card.cardType}</label>
                        </div>
                    </li>
                )
            })
            return (
                <div className="paymentCardList">
                    <ul>
                        {arrReturn}
                    </ul>
                </div>
            )
        } else {
            return (
                <div style={{marginTop:'20px', width:'450px',margin:'0 auto'}}>
                    <div class="form-group">
                        <label>{Strings.card_name_web}*</label>
                        <input type="text" class="form-control" maxLength={75} value={this.state.cardName} onChange={e => this.setState({ cardName: e.target.value })} tabIndex="1" />
                        <div className="errorDiv">
                            <span className='errorMsg'>{this.state.errors.cardNameError}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{Strings.CardNumber_web}*</label>
                        {/* <input type="text" class="form-control" maxLength={16} value={this.state.cardNumber} onChange={e => this.setState({ cardNumber: e.target.value })} tabIndex="2"/> */}
                        <input type="text" class="form-control" onKeyPress="return onlyNumberKey(event)" maxLength={16} tabIndex="2"/>
                        <div className="errorDiv">
                            <span className='errorMsg'>{this.state.errors.cardNumberError}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{Strings.ExpiryDate_web}*</label>
                        <div class="row">
                            <div class="col-5 col-md-6">
                                <select class="form-control" value={this.state.mm} onChange={e => this.setState({ mm: e.target.value })}>
                                    <option value="0">{Strings.str_mm}</option>
                                    <option value="1">01 - {Strings.str_january}</option>
                                    <option value="2">02 - {Strings.str_february}</option>
                                    <option value="3">03 - {Strings.str_march}</option>
                                    <option value="4">04 - {Strings.str_april}</option>
                                    <option value="5">05 - {Strings.str_may}</option>
                                    <option value="6">06 - {Strings.str_june}</option>
                                    <option value="7">07 - {Strings.str_july}</option>
                                    <option value="8">08 - {Strings.str_august}</option>
                                    <option value="9">09 - {Strings.str_september}</option>
                                    <option value="10">10 - {Strings.str_october}</option>
                                    <option value="11">11 - {Strings.str_november}</option>
                                    <option value="12">12 - {Strings.str_december}</option>
                                </select>
                            </div>
                            <div class="col-5 col-md-6">
                                <select class="form-control" value={this.state.yyyy} onChange={e => this.setState({ yyyy: e.target.value })}>
                                    <option value="0">{Strings.str_yyyy}</option>
                                    <option value="2018">2018</option>
                                    <option value="2019">2019</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    <option value="2029">2029</option>
                                    <option value="2030">2030</option>
                                    <option value="2031">2031</option>
                                    <option value="2032">2032</option>
                                </select>
                            </div>
                        </div>
                        <div className="errorDiv">
                            <span className='errorMsg'>{this.state.errors.cardMMError}</span>
                            <span className='errorMsg'>{this.state.errors.cardYYYYError}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{Strings.str_cvv}*</label>
                        <input type="text" class="form-control" maxLength={5} value={this.state.cvv} onChange={e => this.setState({ cvv: e.target.value })} tabIndex="3" />
                        <div className="errorDiv">
                            <span className='errorMsg'>{this.state.errors.cardCVVError}</span>
                        </div>
                    </div>
                </div>
            )
        }
    }



    render() {
        return (
            <div style={{overflow:'hidden',position:'relative',paddingTop:'46%'}}>
                {this.props.isLoading ? <div class="loadermain" style={{ display: 'block' }}>
                    <div class="loader" ></div>
                </div> : <div class="loadermain" style={{ display: 'none' }}>
                        <div class="loader" ></div>
                    </div>}
                {/* {this.renderCardView()}
                <div className="paymentButtonDiv">
                    <button className="paymentButton" onClick={()=>{this.onCheckoutClick()}}>{Strings.Checkout_web}</button>
                    {this.state.arrayCards.length > 0 ? <button className="paymentButton" onClick={()=>{}}>{Strings.str_new_card}</button> : null}
                    <button className="paymentButton" onClick={()=>{}}>{Strings.btn_cancel}</button>
                </div> */}

                <iframe src={getSecureData(Constants.PLACEDORDEROBJECT).paymenturl} width='100%' height='100%' style={{position:'absolute', top:0}}></iframe>
                {/* <iframe src='http://honestgreens.mgtdemo.co.uk/savecard.aspx?custid=9892&orderId=29035&lid=341&rid=147&languageId=1&ipadd=' width='100%' height='710px'></iframe> */}
                {/* <iframe src='http://honestgreens.smartrestaurants.com/savecard.aspx?custid=9892&orderId=29035&lid=341&rid=147&languageId=1&ipadd=' width='100%' height='710px'></iframe> */}

                
            </div>
        )
    }

}

function mapStateToProps(state) {
    console.log('isLoaging PaymentCardList ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    getSavedCards: (customerId) => dispatch(HGActions.initGetSavedCardsAPI(customerId)),
})


export default connect(mapStateToProps, mapDispatchToProps)(PaymentCardList);
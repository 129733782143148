import React, { Component } from 'react';
import { history, withRouter } from "react-router-dom";
import Constant from '../constants/baseConstants';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';

var ordType = ''

class HeaderHomeMenu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loggedInUserId: 0,
            isAlertVisible: false,
            alertMessage:'',
            alertType:1,
            selectedLocationObject: {},
            lType: 1,
        }
    }

    componentDidMount() {
        // localStorage.getItem(Constant.USEROBJECT)
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let value = localStorage.getItem(Constant.USEROBJECT);
            let value = getSecureData(Constant.USEROBJECT);
            // console.log('value='+value);
            if (value !== undefined) {
                if (value !== null )// || value !== undefined) 
                    this.setState({ loggedInUserId: value.customerId });
            }
            

        }
        else {
            // console.log('value=');
        }
        
        // if (localStorage.getItem(Constant.PRE_LANGUAGE_TYPE) == '1') {
        if (getSecureData(Constant.PRE_LANGUAGE_TYPE) == '1') {
            this.setState({lType: 1})
        // } else if (localStorage.getItem(Constant.PRE_LANGUAGE_TYPE) == '2') {
        } else if (getSecureData(Constant.PRE_LANGUAGE_TYPE) == '2') {
            this.setState({lType: 2})
        }
        
    }

    // UNSAFE_componentWillUpdate() {
    //     // if (getSecureData(Constant.PRE_LANGUAGE_TYPE) == '1') {
    //     //     this.state.lType = 1
    //     // } else if (getSecureData(Constant.PRE_LANGUAGE_TYPE) == '2') {
    //     //     this.state.lType = 2
    //     // }
    // }

    renderBadge() {

        if (localStorage.hasOwnProperty(Constant.USERCART)) {
            // let value = localStorage.getItem(Constant.USERCART);
            let value = getSecureData(Constant.USERCART);
            if(value !== undefined) {
                if (value !== null) {
                    if (value.length > 0) {
                        return (
                            <span>{value.length}</span>
                        )
                    }
                }
            }
        }

    }

    renderAccountMenu() {
        // let locationObject = JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT))
        let locationObject = getSecureData(Constant.LOCATIONOBJECT)
        // console.log('this.state.loggedInUser='+this.state.loggedInUserId);
        //User login while show my order,logout ect option
        if (this.state.loggedInUserId > 0) {
            return (
                <li>
                    <a href="#">{Strings.lbl_my_account_react}</a>
                    <ul>
                        <li><a href="" onClick={() => this.props.history.push('/MyAccount')}>{Strings.EditAccount_Web}</a></li>
                        <li><a href="" onClick={() => { this.props.history.push('/MyOrders') }}>{Strings.lbl_title_my_order_react}</a></li>
                        {/* <li><a href="" onClick={() => { this.props.history.push('/CardListing') }}>{Strings.btn_save_card}</a></li> */}
                        {(locationObject) ? <li><a href="" onClick={() => { this.props.history.push('/WaiterListing') }}>{Strings.rate_a_waiter}</a></li> : null }
                        <li><a href="/UserLogout">{Strings.lbl_title_logout_react}</a></li>
                    </ul>
                </li>
            )
        }
    }
    renderProductMenu=()=>{
        if (this.state.loggedInUserId > 0) {
            return (
                <li className="current_page_item"><a href="" onClick={() => this.props.history.push('/Categories')}>{Strings.lbl_title_product_react}</a></li>
            )
        }
    }
    renderCartBadge=()=>{
        if (this.state.loggedInUserId > 0) {
            return (
                <div className="hg_cart" onClick={() => this.props.history.push('/CheckoutDelivery')}><a href=""></a>{this.renderBadge()}</div>
            )
        }
    }
    renderCartMenu=()=>{
        if (this.state.loggedInUserId > 0) {
            return (
                <li className="mobileMenu"><a href="" onClick={() => this.props.history.push('/CheckoutDelivery')}>{Strings.lbl_title_cart_react}</a></li>
            )
        }
    }

    // English language type: 1
    // Spanish language type: 2
    //Handle select language click event
    changeLanguageClickEvent(type) {
        console.log("change language event header white menu : " + type);
        this.props.changeLanguageEvent(type)
    }
    renderLogo(logoType) {
        if(logoType===1)
            return (<div className="hg_logo"><a href="/"><img src='images/logo-white.png' alt="" /></a></div>);
        else
            return (<div className="hg_logo"><a href="/"><img src='images/logo.png' alt="" /></a></div>);
    }

    onChangeLocation(location) {
        // if (JSON.parse(localStorage.getItem(Constant.USERCART))) {
        if (getSecureData(Constant.USERCART)) {
            // if (JSON.parse(localStorage.getItem(Constant.USERCART)).length > 0) {
            if (getSecureData(Constant.USERCART).length > 0) {
                this.setState({isAlertVisible:true,alertType:1, alertMessage:Strings.empty_cart_message, selectedLocationObject:location})  
            } else {
                // localStorage.setItem(Constant.LOCATIONOBJECT,JSON.stringify(location))
                setSecureData(Constant.LOCATIONOBJECT,location)
                setTimeout(() => {
                    this.setState({},()=>{
                    //     this.props.onChangeLocation()
                    })
                }, 200);
            }
        } else {
            // localStorage.setItem(Constant.LOCATIONOBJECT,JSON.stringify(location))
            setSecureData(Constant.LOCATIONOBJECT,location)
            setTimeout(() => {
                this.setState({},()=>{
                    // this.props.onChangeLocation()
                })
            }, 200);
        }
    }

    onChangeOrderType(order) {
        if (!getSecureData(Constant.USERCART)) {
            ordType = (order.title == Strings.Delivery_web) ? 'Delivery' : 'TakeAway'
            setSecureData(Constant.ORDERTYPE,ordType)
            setTimeout(() => {
                this.setState({})
            }, 200); 
            return
        }

        if (getSecureData(Constant.USERCART).length > 0) {
            let ord = (order.title == Strings.Delivery_web) ? Strings.Delivery_web : Strings.lbl_takeaway_react
            ordType = (order.title == Strings.Delivery_web) ? 'Delivery' : 'TakeAway'
            this.setState({isAlertVisible:true,alertType:2, alertMessage:Strings.remove_cart_item_message.replace('#',ord)})  
        } else {
            ordType = (order.title == Strings.Delivery_web) ? 'Delivery' : 'TakeAway'
            setSecureData(Constant.ORDERTYPE,ordType)
            setTimeout(() => {
                this.setState({})
            }, 200);
        }
    }

    onConfirmClick() {
        if(this.state.alertType == 1) {
            // localStorage.setItem(Constant.LOCATIONOBJECT,JSON.stringify(this.state.selectedLocationObject))
            setSecureData(Constant.LOCATIONOBJECT,this.state.selectedLocationObject)
            localStorage.removeItem(Constant.USERCART)
            setTimeout(() => {
                this.setState({isAlertVisible:false})
            }, 200);

        } else if(this.state.alertType == 2) {
            setSecureData(Constant.ORDERTYPE,ordType)
            localStorage.removeItem(Constant.USERCART)
            setTimeout(() => {
                this.setState({isAlertVisible:false})
            }, 200);
        }
        
    }

    renderLocationList() {
        var arrReturn = []

        // let locationList = JSON.parse(localStorage.getItem(Constant.LOCATIONLISTOBJECT))
        let locationList = getSecureData(Constant.LOCATIONLISTOBJECT)
        // let locationObject = JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT))
        let locationObject = getSecureData(Constant.LOCATIONOBJECT)
        if (locationList) {
            if (locationList.length > 0) {
                locationList.map((location, index)=>{
                    arrReturn.push(
                        // <li><a  className="dropdown-item" href="javascript:void(0);" onClick={() => { this.onChangeLocation(location) }}>{location.locationName}</a></li>
                        <li key={index}><a href="javascript:void(0);" onClick={() => {this.onChangeLocation(location)}}>{location.locationName}</a></li>
                    )
                })
            } else {
                return
            }
        } else {
            return
        }
        
        return (
            // <div className="lang_drop">
            //     <div className="dropdown">
            //         <a href="#" className="" type="button" data-toggle="dropdown"><span className="caret">{(locationObject) ? locationObject.locationName : null }</span></a>
            //         <ul className="dropdown-menu">
            //             {arrReturn}
            //         </ul>
            //     </div>
            // </div>
            <li>
                <a href="javascript:void(0);" className="myHovery" id="myHovery">{(locationObject) ? locationObject.locationName : null }</a>
                <ul>
                    {arrReturn}
                </ul>
            </li>
        )
    }

    renderOrderTypes() {
        //Check user is logged in or not
        if (this.state.loggedInUserId > 0) {
            //check location object available or not in local storage if available then fetch and set data
            if (getSecureData(Constant.LOCATIONOBJECT)) {
                if (getSecureData(Constant.ORDERTYPE)) {
                    let locationObject = getSecureData(Constant.LOCATIONOBJECT)
                    var arry = []
                    if (locationObject.isDelivery) {
                        arry.push({ title: Strings.Delivery_web, isSelect: false })
                    }
                    if (locationObject.isTakeAway) {
                        arry.push({ title: Strings.lbl_takeaway_react, isSelect: false })
                    }
                    var arrReturn = []
                    arry.map((order, index)=>{
                        arrReturn.push(
                            <li key={index}><a href="#" onClick={() => {this.onChangeOrderType(order)}}>{order.title}</a></li>
                        )
                    })
                    return (
                        <li>
                            <a href="#">{getSecureData(Constant.ORDERTYPE) == 'Delivery' ? Strings.Delivery_web : Strings.lbl_takeaway_react}</a>
                            <ul>
                                {arrReturn}
                            </ul>
                        </li>
                    )
                }
            }
        }
    }

    render() {
        return (
            <header>
                <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={true} btnLeftClick={() => {this.setState({isAlertVisible:false})}} btnRightClick={() => {this.onConfirmClick()}} btnLeftTitle={Strings.btn_cancel}  btnRightTitle={Strings.btn_confirm} />
                <div className="container">
                    {this.renderLogo(this.props.logoType)}
                    <div className="navigation"> <a href="" className="lines-button x2"><span className="lines"></span></a>
                        <div className="main_menu">
                            <ul className="firstul">
                                {this.renderProductMenu()}                                
                                {this.renderAccountMenu()}
                                {this.renderCartMenu()} 
                                {this.renderCartBadge()}                               
                                {/* <li className="mobileMenu"><a href="#" onClick={() => this.props.history.push('/Categories')}>{Strings.lbl_title_cart_react}</a></li> */}
                                <li>
                                    <a href="#">{this.state.lType == 1 ? 'EN' : 'ES'}</a>
                                    <ul>
                                        <li><a href="#" onClick={() => {this.changeLanguageClickEvent(1)}}>EN</a></li>
                                        <li><a href="#" onClick={() => {this.changeLanguageClickEvent(2)}}>ES</a></li>
                                    </ul>
                                </li>
                                {this.props.isCategory ? this.renderLocationList() : null}
                                {!this.props.isHideOrderType ? this.renderOrderTypes() : null}
                            </ul>
                        </div>
                        {/* <div className="hg_cart" onClick={() => this.props.history.push('/CheckoutDelivery')}><a href=""></a>{this.renderBadge()}</div> */}
                        {/* <div className="lang_drop">
                            <div className="dropdown">
                                <a href="#" className="" type="button" data-toggle="dropdown"><span className="caret">{this.state.lType == 1 ? 'English' : 'Spanish'}</span></a>
                                <ul className="dropdown-menu">
                                    <li><a onClick={() => { this.changeLanguageClickEvent(1) }} className="dropdown-item" href="javascript:void(0);">English</a></li>
                                    <li><a onClick={() => { this.changeLanguageClickEvent(2) }} className="dropdown-item" href="javascript:void(0);">Spanish</a></li>
                                </ul>
                            </div>
                        </div> */}
                        {/* {this.props.isCategory ? this.renderLocationList() : null} */}
                    </div>
                </div>
            </header>
        )
    }
}

export default withRouter(HeaderHomeMenu);
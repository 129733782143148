import React, { Component } from "react";

import {withRouter} from 'react-router-dom';

import Constants from '../constants/baseConstants';
import Modal from 'react-bootstrap/Modal'
import { Strings } from "../constants/Localization";
import {getPriceFormat,bindhttps} from "../Utils/utils";
import {setSecureData, getSecureData} from '../Utils/utils';


class ItemPopup extends Component {
  
  constructor(props) {
    super(props);
    
    let objItem = this.props.itemData
    var itemQty = 1
    var allPrice = 0.0

    if (this.props.isEdit) {
      itemQty = objItem.totalQty
      allPrice = objItem.totalPrice
      console.log('edit at ---- ',this.props.editIndex,objItem)
    } else {
      for (let i=0; i <= objItem.PriceList.length-1; i++) {
        
        if (i == 0) {
          objItem.PriceList[i].isSelect = true
          allPrice = objItem.PriceList[i].price
        } else {
          objItem.PriceList[i].isSelect = false
        }
  
        var objOptions = []
        objItem.PriceList[i].OptionList.map((data,index) => {
          //Check is all select or not
          if (data.isSelectAll) {
            data.isSelect = true
          } else {
            if(data.isCompulsory) {
              if (index == 0) {
                data.isSelect = true
              } else {
                data.isSelect = false
              }
            } else {
            }
          }
          objOptions.push(data)
        })
        objItem.PriceList[i].OptionList = objOptions
      }
    }

    this.state = {
      itemObject: objItem,
      qty:itemQty,
      totalPrice: allPrice,

      isAlertVisible: false,
      alertMessage: '',
    }

    // const mLanguageCode="en-GB"; 
  }
  
  componentDidMount() {
    // mLanguageCode = localStorage.getItem(Constants.PRE_LANGUAGE_CODE); 
    console.log('componentDidMount called from item popup ----- ',this.state.itemObject)
  }

  getNumberOfOptionsSelections = (item) => {
    if (item.length == 0) {
        return
    }
    var counter = 0
    for (let k=0;k<=item.length-1;k++) {
        if (item[k].isSelect) {
            counter += 1
        }
    }
    return counter
}

  onOptClick(optData,i) {
    console.log("optData ========= ",i,optData)
    let objItem = this.state.itemObject

    for (let i=0; i<objItem.PriceList.length; i++) {

      if (objItem.PriceList[i].isSelect) {
        let options = objItem.PriceList[i].OptionList
        for (let k=0;k<options.length;k++) {
          if (optData.isSelectAll) {
            if (optData.isSelect) {
                if (options[k].typeid == optData.typeid) {
                  options[k].isSelect = false
                }
            } else {
              if (options[k].typeid == optData.typeid) {
                options[k].isSelect = true
                console.log('else optData.isSelect ----- ',k,options[k].typeid)
              }
            }
          } else {
            if (optData.isMultiselect) {
              console.log('is multiselect')
              if (optData.maxselection > 0) {
                let no = this.getNumberOfOptionsSelections(options) 
                console.log("max number of selection ---> "+ no)
                if (optData.maxselection > no) {
                  if (!optData.isSelect) {
                    alert('you can not select maximum number of options out of ',optData.maxselection)
                  } else {
                    if (options[k].typeid == optData.typeid) {
                      options[k].isSelect = true
                    }
                  }
                } else {
                  if (options[k].typeid == optData.typeid) {
                    if (optData.isSelect) {
                      options[k].isSelect = false
                    } else {
                        options[k].isSelect = true
                        
                    }
                  }
                }
              } else {
                console.log('optData.maxselection > 0 else')
                if (options[k].typeid == optData.typeid) {
                  if (optData.isSelect) {
                      options[k].isSelect = false
                  } else {
                      options[k].isSelect = true
                  }
                }
              }
            } else {
              console.log('is multiselect else')
              if (options[k].typeid == optData.typeid && options[k].labelText == optData.labelText) {
                console.log('options[k].typeid == optData.typeid && options[k].labelText == optData.labelText')
                if (optData.isSelect == true) {
                    options[k].isSelect = false
                } else {
                    options[k].isSelect = true                                
                } 
              } else if (options[k].labelText == optData.labelText) {
                console.log('options[k].labelText == optData.labelText')
                options[k].isSelect = false
              }
            }
          }
        }
        objItem.PriceList[i].OptionList = options
        console.log("optData updated ========= ",options)
        this.setState({itemObject: objItem, totalPrice: this.countPrice()*this.state.qty})
        break
      }
    }
  }

  renderOptions(title) {
    
    for (var i=0;i<this.state.itemObject.PriceList.length;i++) {
      if (this.state.itemObject.PriceList[i].isSelect) {
        let optList = this.state.itemObject.PriceList[i].OptionList

        let filrt = optList.filter(data => (data.labelText == title))
        
        var arrOpt = []
    
        filrt.map((options,index) => {
          arrOpt.push(
            <a onClick={()=>{this.onOptClick(options,index)}} key={index}>
              <li> 
                <button className="checked-btn"><img src={options.isSelect ? "/images/checked.png" : "/images/uncheck.png"}/></button><label className="container1">
                {options.type} {options.price > 0.0 ? getPriceFormat(options.price.toFixed(2)) : null}
                {/* {options.type} {options.price > 0.0 ? [options.price.toFixed(2)+Constants.currencyIcon] : null} */}
                      </label>
              </li>    
            </a>
          )
        })
    
        return arrOpt
        break
      }
    }
  }

  onPriceClick(index) {
    console.log('select price type --- ',index)
    let obj = this.state.itemObject
    if (obj.PriceList.length == 1) {
      return
    }
    for (let i=0; i < obj.PriceList.length; i++) {
        if (index != i) {
            obj.PriceList[i].isSelect = false
        } else {
            obj.PriceList[i].isSelect = true
            this.setState({totalPrice:obj.PriceList[i].price})
        }

        var lst = obj.PriceList[i].OptionList
        for (let k=0;k<=lst.length-1;k++) {
            if (lst[k].isSelectAll) {
                obj.PriceList[i].OptionList[k].isSelect = true
            } else {
                if (lst[k].isCompulsory) {
                    if (k>0) {
                        if (lst[k-1].isCompulsory == true && obj.PriceList[i].OptionList[k].labelText == lst[k-1].labelText) {
                            obj.PriceList[i].OptionList[k].isSelect = false
                        } else {
                            obj.PriceList[i].OptionList[k].isSelect = true
                        }
                    } else {
                        obj.PriceList[i].OptionList[k].isSelect = true
                    }
                } else {
                    obj.PriceList[i].OptionList[k].isSelect = false
                }
            }
        }
    }

    // let priceFilter = this.state.itemObject.PriceList.filter((price) => (price.isSelect == true))
    // var plPrice = priceFilter[0].price
    
    // let optionFilter = priceFilter[0].OptionList.filter((option) => (option.isSelect == true))
    // let arryoptions = optionFilter.map((options) => (Number(options.price)))
    // var sum = 0 
    // if (arryoptions.length > 0 ) {
    //     sum = arryoptions.reduce((result,number)=> result+number) + plPrice;
    // } else {
    //     sum += plPrice
    // }
    
    this.setState({itemObject:obj,totalPrice:this.countPrice()*this.state.qty})
  }

  renderPriceOption() {
    var arrReturn = []

    this.state.itemObject.PriceList.map(((priceOptions,index) => {
      arrReturn.push(
        <a onClick={()=>{this.onPriceClick(index)}} key={index}>
          <li> 
            <button className="checked-btn"><img src={priceOptions.isSelect ? "/images/checked.png" : "/images/uncheck.png"}/></button>
            <label className="container1">
              {priceOptions.type}
            </label>
          </li>
        </a>
      )
    }))
    return (
      <div className="plate-div">
          <ul>
            {arrReturn}
          </ul>
        </div>
    )
  }

  renderOptionMenu () {
    console.log('renderOptionMenu ----- ')

    for (var i=0;i<this.state.itemObject.PriceList.length;i++) {
      
      if (this.state.itemObject.PriceList[i].isSelect) {
        var arrayTitle = []
        let optList = this.state.itemObject.PriceList[i].OptionList
        
        optList.map((data) => {
  
          var temp = arrayTitle.filter(obj => (obj == data.labelText))
          if (temp.length == 0) {
            arrayTitle.push(data.labelText)
          }
        })
        var arrReturn = []
        for (var k=0;k<=arrayTitle.length-1;k++) {
          arrReturn.push(
            <div className="plate-div" key={k}>
              <h4>{arrayTitle[k]}</h4>
              <ul>
                {this.renderOptions(arrayTitle[k])}
              </ul>
            </div>
            )
          }
        return arrReturn
        break
      }
    }
  }

  onQtyChanged(type) {
    var q = this.state.qty
    if (type == 0) {
      if (q > 1) {
        q -= 1
      }
    } else {
      q += 1
    }
    this.setState({qty: q, totalPrice: q * this.countPrice()})
    
  }

  countPrice = () => {

        let priceFilter = this.state.itemObject.PriceList.filter((price) => (price.isSelect == true))
        var plPrice = priceFilter[0].price
        
        let optionFilter = priceFilter[0].OptionList.filter((option) => (option.isSelect == true))
        let arryoptions = optionFilter.map((options) => (Number(options.price)))
        var sum = 0 
        if (arryoptions.length > 0 ) {
            sum = arryoptions.reduce((result,number)=> result+number) + plPrice;
        } else {
            sum += plPrice
        }
        return sum
  }

  onAddToCart() {

    //Check for add or add to cart
    if (!this.props.isEdit) {
      
      //add to cart item
      this.state.itemObject.totalQty = this.state.qty
      this.state.itemObject.totalPrice = this.countPrice() * this.state.qty
  
      if (localStorage.hasOwnProperty(Constants.USERCART)) {
        // let userObject = localStorage.getItem(Constants.USERCART);
        let userObject = getSecureData(Constants.USERCART);
        
        if (userObject !== null) {
            // userObject = JSON.parse(userObject);
            // let cartData = JSON.parse(localStorage.getItem(Constants.USERCART))
            let cartData = getSecureData(Constants.USERCART)
  
            console.log('Cart Data --- ',JSON.stringify(cartData))
            console.log('itemObject --- ',JSON.stringify(this.state.itemObject))
  
            var shouldUpdate = false
            var updateAt = 0
            if (cartData.length > 0) {
  
              //check menu item id available and matched 
              cartData.map((cartObject,index) => {
                if (cartObject.menuitemId == this.state.itemObject.menuitemId) {
                  let cartPriceType = cartObject.PriceList.filter(data => (data.isSelect == true))
                  let itemPriceType = this.state.itemObject.PriceList.filter(data => (data.isSelect == true))
                  //compare selected price type
                  if (cartPriceType[0].typeid == itemPriceType[0].typeid) {
                    //get selected options list type 
                    let cartOptions = cartPriceType[0].OptionList.filter(opt => (opt.isSelect == true))
                    let itemOptions = itemPriceType[0].OptionList.filter(opt => (opt.isSelect == true))
  
                    var arrayCartOpt = []
                    cartOptions.map(cartopt=>(
                      arrayCartOpt.push(cartopt.typeid)
                    ))
                    var arrayItemOpt = []
                    itemOptions.map(itemopt=>(
                      arrayItemOpt.push(itemopt.typeid)
                    ))
                    console.log('arrayCartOpt ----- ',arrayCartOpt)
                    console.log('arrayItemOpt ----- ',arrayItemOpt)
                    //compare selected options list
                    if (JSON.stringify(arrayCartOpt) == JSON.stringify(arrayItemOpt)) {
                      shouldUpdate = true
                      updateAt = index
                      console.log('Both object are same......')
                    } else {
                      console.log('Both object are not same......')
                    }
                  }
                }
              })
            }
  
            if (shouldUpdate) {
              
              let oldQty = cartData[updateAt].totalQty
              let oldPrice = cartData[updateAt].totalPrice
  
              console.log('item data should update at --- ',updateAt,)
              //add new qty and price in cart item
              cartData[updateAt].totalQty += this.state.itemObject.totalQty
              cartData[updateAt].totalPrice += this.state.itemObject.totalPrice
  
              // localStorage.setItem(Constants.USERCART,JSON.stringify(cartData))
              setSecureData(Constants.USERCART,cartData)
            } else {
              
              console.log('add item in cart')
              cartData.push(this.state.itemObject)
              // localStorage.setItem(Constants.USERCART,JSON.stringify(cartData))
              setSecureData(Constants.USERCART,cartData)
            }
            //close item popup
            this.props.onClose({isHide:true,isNewItem:!shouldUpdate})
          } else {
            // localStorage.setItem(Constants.USERCART,JSON.stringify([this.state.itemObject]))
            setSecureData(Constants.USERCART,[this.state.itemObject])
            //close item popup
            this.props.onClose({isHide:true,isNewItem:true})
        }
      } else {
        // localStorage.setItem(Constants.USERCART,JSON.stringify([this.state.itemObject]))
        setSecureData(Constants.USERCART,[this.state.itemObject])
        //close item popup
        this.props.onClose({isHide:true,isNewItem:true})
      }
      
    } else {
      //edit item
      console.log('edit existing item')
      // let cartData = JSON.parse(localStorage.getItem(Constants.USERCART))
      let cartData = getSecureData(Constants.USERCART)

      cartData[this.props.editIndex].PriceList = this.state.itemObject.PriceList
      cartData[this.props.editIndex].isReOrderedItem = false
      
      console.log('item data should edit at --- ',this.props.editIndex, this.state.qty, this.state.totalPrice)
      //add new qty and price in cart item
      cartData[this.props.editIndex].totalQty = this.state.qty
      cartData[this.props.editIndex].totalPrice = this.state.totalPrice
      console.log('updated data ----- ',cartData)
      // localStorage.setItem(Constants.USERCART,JSON.stringify(cartData))
      setSecureData(Constants.USERCART,cartData)
      setTimeout(() => {
        //close item popup
        this.props.onClose({isHide:true})
      }, 200);
      
    }


    

  }

  render() {

    console.log('Popup Item Object ----- ',this.state.itemObject)

    return (

      <Modal
        show={this.props.isVisible}
        onHide={()=>this.props.onClose({isHide: false})}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

      <Modal.Header closeButton>
      {/* <div className="pop-header">
          
          <button type="button" className="close" data-dismiss="pop" onClick={()=>this.props.onClose({isHide: false})}>&times;</button>
        </div> */}
      </Modal.Header>
      <Modal.Body>
        {/* <div>
          <h3>{this.props.itemData.menuItemName}</h3>
        </div>
        <button onClick={()=>this.props.onClose()}>X</button> */}

        {/* <!-- The pop --> */}
        
  <div className="pop" id="mypop">
    <div className="pop-dialog">
      <div className="pop-content">
      
        {/* <!-- pop Header --> */}
        {/* <div className="pop-header">
          
          <button type="button" className="close" data-dismiss="pop" onClick={()=>this.props.onClose({isHide: false})}>&times;</button>
        </div> */}

        
        {/* <!-- pop body --> */}
        <div className="pop-body">
          <div className="prd-s">                  
                  <div className="prd-cont">                   
                    <h3>{this.state.itemObject.menuItemName}</h3>
                    <p>{this.state.itemObject.description}</p>
                    <div className="prd-img"><a href="#"><img src={bindhttps(this.state.itemObject.imgurl)}/></a></div>
                    {this.renderPriceOption()}
                    {this.renderOptionMenu()}
                    <div className="add-qntity">
                      <p>{Strings.lbl_quantity_react}</p>
                      <div className="number">
                          <span className="minus" onClick={()=>{this.onQtyChanged(0)}}>-</span>
                          <label>{this.state.qty}</label>
                          <span className="plus" onClick={()=>{this.onQtyChanged(1)}}>+</span>
                      </div>
                    </div>

                    {/* {!this.props.isEdit ? (
                      <div className="price-btns">
                        <a href="#" className="btn btn-primary btn-medium">More products</a>
                        <a href="#" className="btn btn-primary btn-medium">add a drink?</a>
                      </div>
                    ) : null} */}
                    
                    
                  </div>
                </div>
        </div>
        
        {/* <!-- pop footer --> */}
        <div className="pop-footer">
          <p>{Strings.Total_web}:  <span>
            {getPriceFormat(this.state.totalPrice.toFixed(2))}
            {/* {this.state.totalPrice.toFixed(2)+Constants.currencyIcon} */}
            </span></p>
          <button type="button" className="btn btn-danger" data-dismiss="pop" onClick={()=>{this.onAddToCart()}}>{this.props.isEdit ? Strings.str_btn_update_react : Strings.btn_add_to_cart_react}</button>
        </div>
        
      </div>
    </div>
  </div>
        
      </Modal.Body>
    </Modal>

      
    )
  }

}

export default withRouter(ItemPopup);
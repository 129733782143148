import React, { Component } from 'react';

import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import { Strings } from '../constants/Localization';
import FooterMenu from '../Components/FooterMenu';
import Constant from '../constants/baseConstants';
import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { Dropdown } from 'react-bootstrap';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
    href=""
    ref={ref}
    onClick={(e) => {
        e.preventDefault();
        onClick(e);
    }}
    className="form-control"
    >
    {children}
    &#x25bc;
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    // const [value, setValue] = useState('');
    const value="";
    return (
        <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
        >
        {/* <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => alert(e.target.value)}
            value={value}
        /> */}
        <ul>
            {React.Children.toArray(children).filter(
            (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
        </ul>
        </div>
    );
    },
);

class SignUp extends Component {

    constructor(props) {
        super(props)

        var userName = this.props.location.name
        var emailId = this.props.location.emailId
        var isFromSocialMedia = this.props.location.isFromSocialMedia
        console.log("userName :" + userName + "  " + emailId)
        this.state = {
            name: '',
            countryCode: '0',
            phoneNumber: '',
            email: '',
            password: '',
            languageType: 1, //default language type

            errors: {
                nameError: '',
                countryCodeError: '',
                phoneNumberError: '',
                emailError: '',
                passwordError: '',
                locationError: '',
            },

            isSocialSignUp: false,
            locationArray: [],
            locationIndex: -1,
        }
        setTimeout(() => {
            if (isFromSocialMedia) {
                this.setState({ name: userName, email: emailId })
            }
        }, 200);
    }

    componentDidMount() {
        AOS.init({
            duration: 1000
        })
        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        setTimeout(() => {
            this.props.locationListApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    if (this.props.data.result != null) {
                        var locationList = this.props.data.result;
                        if (locationList != null && locationList.length > 0) {
                            // localStorage.setItem(Constant.LOCATIONLISTOBJECT, JSON.stringify(locationList))
                            setSecureData(Constant.LOCATIONLISTOBJECT, locationList)
                            this.setState({ locationArray: locationList})
                        }
                    }
                } else {
                    alert(this.props.data.message)
                }
                //CALL CHECK VERSION API 
                if (!localStorage.hasOwnProperty(Constant.CHECKINFO)) {
                    this.props.callCheckVersion(0,this.state.languageType).then(() => {
                        if (this.props.data.status == 1) {
                            setTimeout(() => {
                                // localStorage.setItem(Constant.CHECKINFO, JSON.stringify(this.props.data.result))
                                setSecureData(Constant.CHECKINFO, this.props.data.result)
                            }, 500)
                        } else {
                        }
                    })
                }
            })
        }, 200);
    }


    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("sign up page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }


    onSignUpClick = (event) => {
        event.preventDefault();

        // this.props.history.push({pathname: '/MobileVerification'})
        // return
        var nmError = this.state.name.length == 0 ? Strings.error_msg_name_react : '';
        var ccodeerror = this.state.countryCode == '0' ? Strings.error_msg_country_code_react : '';
        var phError = this.state.phoneNumber.length < 9 ? Strings.error_msg_phone_no_react : '';
        var emlError = this.state.email.length == 0 ? Strings.error_msg_email_react : '';
        var passError = this.state.password.length == 0 ? Strings.error_msg_password_react : '';
        var locError = this.state.locationIndex == -1 ? Strings.please_select_location : '';

        if (emlError.length == 0) {
            validEmailRegex.test(this.state.email) == false ? emlError = Strings.error_msg_valid_email_react : emlError = '';
        }

        this.setState({ errors: { locationError:locError, nameError: nmError, countryCodeError: ccodeerror, phoneNumberError: phError, emailError: emlError, passwordError: passError } })

        setTimeout(() => {
            if (this.state.errors.nameError == '' && this.state.errors.countryCodeError == '' && this.state.errors.phoneNumberError == '' && this.state.errors.emailError == '' && this.state.errors.passwordError == '' && this.state.errors.locationError == '') {
                console.info('Valid form')
                // this.props.SignUpAPICall(JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT)).locationId,0, this.state.name, this.state.countryCode, this.state.phoneNumber, this.state.email, this.state.password, this.state.languageType).then(() => {
                this.props.SignUpAPICall(getSecureData(Constant.LOCATIONOBJECT).locationId,0, this.state.name, this.state.countryCode, this.state.phoneNumber, this.state.email, this.state.password, this.state.languageType).then(() => {
                    console.log("SIGNUP RESPONSE --------- ", this.props.data)
                    if (this.props.data.status == 1) {
                        if (this.props.data.result != null) {
                            setTimeout(() => {
                                let result = this.props.data.result;
                                console.log("SIGNUP RESPONSE --------- ", JSON.stringify(result))
                                if (result.isMobileVerify == true) {

                                } else {
                                    //Move on Mobile verification screen
                                    this.props.history.push({ pathname: '/MobileVerification', customerId: result.customerId, phone: this.state.countryCode + this.state.phoneNumber, message: this.props.data.message })
                                }

                            }, 100)
                        }
                    } else {
                        alert(this.props.data.message)
                        console.log("sign up status else...")
                    }
                })

            } else {
                console.error('Not valid form')
            }
        }, 100)
    }

    onSelectLocation(e) {
        if(e != '') {
            this.setState({ locationIndex: e })
            // localStorage.setItem(Constant.LOCATIONOBJECT, JSON.stringify(this.state.locationArray[e.target.value]))
            setSecureData(Constant.LOCATIONOBJECT, this.state.locationArray[e])
        } else {
            this.setState({ locationIndex: -1})
        }
    }

    renderLocationList() {

        var arrReturn = []
        this.state.locationArray.map((location,index) => {
            arrReturn.push(
                // <option value={index}>{location.locationName}</option>
                <Dropdown.Item eventKey={index}>{location.locationName}</Dropdown.Item>
            )
        })

        return (    
            // <select class="form-control" onChange={e => this.onSelectLocation(e)}>
            //     <option value="">{Strings.lbl_select_location_react}</option>
            //     {arrReturn}
            // </select>


            <Dropdown onSelect={(e) => this.onSelectLocation(e)}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                {this.state.locationIndex==-1 ? Strings.lbl_select_location_react : this.state.locationArray[this.state.locationIndex].locationName} 
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu} style={{height:"150px",overflow:"scroll"}}>
                    {arrReturn}
                </Dropdown.Menu>
            </Dropdown>
        )
        
    }


    render() {


        const responseGoogle = (response) => {
            if (response.error == null) {
                var res = response.profileObj;
                console.log("google response success----- ", res);
                if (res == null) {
                    return
                }
                this.setState({ name: res.name, email: res.email })
                return
                // this.props.googleSignUpCall(res.email,3,res.googleId,this.state.languageType).then(() => {
                //     console.log("GOOGLE SIGNUP RESPONSE --------- ",this.props.data)
                //     if (this.props.data.status == 1) {
                //         if (this.props.data.result != null) {
                //             setTimeout(() => {
                //                 console.log("GOOGLE - SIGNUP RESPONSE --------- ",JSON.stringify(this.props.data.result))
                //             }, 100)
                //         }
                //     } else {
                //         alert(this.props.data.message)
                //         console.log("sign up status else...")
                //     }
                // })

            } else {
                console.log("google response failed----- ", response.error);
            }
        }

        const loginWithFacebook = (response) => {
            console.log("login with facebook  :" + JSON.stringify(response));
        }

        return (
            <div>


                {this.props.isLoading ? <div class="loadermain" style={{ display: 'block' }}>
                    <div class="loader" ></div>
                </div> : <div class="loadermain" style={{ display: 'none' }}>
                        <div class="loader" ></div>
                    </div>}
                {/* Old header */}
                {/* <HeaderMenu /> */}

                <HeaderWhiteMenu
                    changeLanguageEvent={this.languageChangeClickEvent} />

                {/* <h2>{this.props.match.params.id}</h2> */}
                <div class="content_wrap">
                    <div class="container">
                        <div class="signupwrap" data-aos='fade-up'>
                            <h1>{Strings.btn_signup_react}</h1>
                            <div class="row">
                                <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                    <div class="form-group">
                                        <label>{Strings.lbl_name_react}*</label>
                                        <input type="text" class="form-control" maxLength={75} value={this.state.name} onChange={e => this.setState({ name: e.target.value })} tabIndex="1" />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.nameError}</span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{Strings.lbl_phone_number_react}*</label>
                                        <div class="row">
                                            <div class="col-5 col-md-3 pr-0">
                                                {/* <select class="form-control" onChange={e => this.setState({ countryCode: e.target.value })}>
                                                    
                                                    <option value="+44">+44</option>
                                                </select> */}
                                                <Dropdown onSelect={(e) => this.setState({ countryCode: e })}>
                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                    {this.state.countryCode==0?"Code ":this.state.countryCode} 
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={CustomMenu} style={{height:"200px",overflow:"scroll"}}>
                                                        <Dropdown.Item eventKey="+7">+7</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+30">+30</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+31">+31</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+32">+32</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+33">+33</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+34">+34</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+36">+36</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+39">+39</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+40">+40</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+41">+41</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+43">+43</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+44">+44</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+45">+45</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+46">+46</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+47">+47</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+48">+48</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+49">+49</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+90">+90</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+298">+298</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+350">+350</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+351">+351</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+352">+352</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+353">+353</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+354">+354</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+355">+355</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+356">+356</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+357">+357</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+358">+358</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+359">+359</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+370">+370</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+371">+371</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+372">+372</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+373">+373</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+374">+374</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+375">+375</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+376">+376</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+377">+377</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+378">+378</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+379">+379</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+380">+380</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+381">+381</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+382">+382</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+383">+383</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+385">+385</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+386">+386</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+387">+387</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+389">+389</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+420">+420</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+421">+421</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+423">+423</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+994">+994</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+995">+995</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+3491">+3491</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+91">+91</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {/* <select class="form-control" onChange={e => this.setState({ countryCode: e.target.value })}>
                                                    <option value="0">Code</option>
                                                    <option value="+7">+7</option>
                                                    <option value="+30">+30</option>
                                                    <option value="+31">+31</option>
                                                    <option value="+32">+32</option>
                                                    <option value="+33">+33</option>
                                                    <option value="+34">+34</option>
                                                    <option value="+36">+36</option>
                                                    <option value="+39">+39</option>
                                                    <option value="+40">+40</option>
                                                    <option value="+41">+41</option>
                                                    <option value="+43">+43</option>
                                                    <option value="+44">+44</option>
                                                    <option value="+45">+45</option>
                                                    <option value="+46">+46</option>
                                                    <option value="+47">+47</option>
                                                    <option value="+48">+48</option>
                                                    <option value="+49">+49</option>
                                                    <option value="+90">+90</option>
                                                    <option value="+298">+298</option>
                                                    <option value="+350">+350</option>
                                                    <option value="+351">+351</option>
                                                    <option value="+352">+352</option>
                                                    <option value="+353">+353</option>
                                                    <option value="+354">+354</option>
                                                    <option value="+355">+355</option>
                                                    <option value="+356">+356</option>
                                                    <option value="+357">+357</option>
                                                    <option value="+358">+358</option>
                                                    <option value="+359">+359</option>
                                                    <option value="+370">+370</option>
                                                    <option value="+371">+371</option>
                                                    <option value="+372">+372</option>
                                                    <option value="+373">+373</option>
                                                    <option value="+374">+374</option>
                                                    <option value="+375">+375</option>
                                                    <option value="+376">+376</option>
                                                    <option value="+377">+377</option>
                                                    <option value="+378">+378</option>
                                                    <option value="+379">+379</option>
                                                    <option value="+380">+380</option>
                                                    <option value="+381">+381</option>
                                                    <option value="+382">+382</option>
                                                    <option value="+383">+383</option>
                                                    <option value="+385">+385</option>
                                                    <option value="+386">+386</option>
                                                    <option value="+387">+387</option>
                                                    <option value="+389">+389</option>
                                                    <option value="+420">+420</option>
                                                    <option value="+421">+421</option>
                                                    <option value="+423">+423</option>
                                                    <option value="+994">+994</option>
                                                    <option value="+995">+995</option>
                                                    <option value="+3491">+3491</option>
                                                    <option value="+91">+91</option>
                                                </select> */}
                                            </div>
                                            <div class="col-7 col-md-9">
                                                <input type="text" class="form-control" maxLength={11} value={this.state.phoneNumber} onChange={e => this.setState({ phoneNumber: e.target.value })} tabIndex="2" />
                                            </div>
                                        </div>
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.countryCodeError}</span>
                                            <span className='errorMsg'>{this.state.errors.phoneNumberError}</span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{Strings.lbl_email_react}*</label>
                                        <input type="text" class="form-control" maxLength={75} value={this.state.email} onChange={e => this.setState({ email: e.target.value })} tabIndex="3" />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.emailError}</span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{Strings.lbl_password_react_sign_in}*</label>
                                        <input type="password" class="form-control" maxLength={75} value={this.state.password} onChange={e => this.setState({ password: e.target.value })} tabIndex="4" />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.passwordError}</span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{Strings.lbl_select_location_react}*</label>
                                        {this.renderLocationList()}
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.locationError}</span>
                                        </div>
                                    </div>
                                    <div class="signup">
                                        <a href="#" class="btn btn-primary btn-big" onClick={this.onSignUpClick} tabIndex="5">{Strings.btn_signup_react}</a>
                                    </div>
                                    <div class="loginimg">
                                        <h6>- {Strings.lbl_or_sign_up_react} -</h6>

                                        <GoogleLogin
                                            clientId="978221704755-e4a2atvcsfol27ei8n6437pq7c1e514a.apps.googleusercontent.com"
                                            cookiePolicy={"single_host_origin"}
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            render={({ onClick, disabled }) => (
                                                //Old view google plus :18-05-2-020
                                                // <a href="" onClick={onClick} disabled={disabled}><img src="/images/gp1.png" alt=""/></a>
                                                <label class="google"> <a href="" onClick={onClick} disabled={disabled}><img src="images/new_gp.png" alt="" />{Strings.btn_google_plus_react}</a></label>
                                            )} />

                                        <FacebookLogin
                                            appId="240943480330909" //App id from facebook developer 
                                            fields="name,email,picture"
                                            callback={loginWithFacebook}
                                            icon={false}
                                            render={renderProps => (
                                                //old view facebook :18-05-2-020
                                                // <a onClick={renderProps.onClick} href="#"><img src="images/fb1.png" alt="" /></a>
                                                <label class="facebook"> <a onClick={renderProps.onClick} href="javascript:void(0);"><img src="images/new_fb.png" alt="" />{Strings.btn_facebook_react}</a></label>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }
}

function mapStateToProps(state) {
    console.log('isLoaging SignUp ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    SignUpAPICall: (locationId,customerId, name, countrycode, phone, email, password, languageType) => dispatch(HGActions.initRegistrationCall(locationId,customerId, name, countrycode, phone, email, password, languageType)),
    googleSignUpCall: (email, sMediaFlag, smediaId, languageType) => dispatch(HGActions.initSocialSignUpCall(email, sMediaFlag, smediaId, languageType)),
    socialCustomerAddUpdate: (name, countrycode, phone, email, password, sMediaFlag, smediaId) => dispatch(HGActions.initAddUpdateSocialCustomerCall(name, countrycode, phone, email, password, sMediaFlag, smediaId)),
    locationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
import actionType from '../actions/ActionTypes';

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: undefined
}

export default function HGReducers(state = INITIAL_STATE, action) {
  console.log(' HGReducers - ' + action.type);
  
  switch (action.type) {

    case actionType.CHECKAPPVERSION_L:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.CHECKAPPVERSION_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.CHECKAPPVERSION_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      case actionType.LOGIN_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.LOGIN_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.LOGIN_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //FORGET PASSWORD
    case actionType.FORGET_PASSWORD_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.FORGET_PASSWORD_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.FORGET_PASSWORD_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //COMPANY ACCOUNT LINK
    case actionType.LINK_ACCOUNT_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.LINK_ACCOUNT_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.LINK_ACCOUNT_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //SIGNUP
    case actionType.REGISTRATION_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.REGISTRATION_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.REGISTRATION_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //VERIFY CUSTOMER
    case actionType.VERIFY_CUSTOMER_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.VERIFY_CUSTOMER_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.VERIFY_CUSTOMER_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //RESEND VERIFICATION CODE
    case actionType.RESEND_VERIFICATION_CODE_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.RESEND_VERIFICATION_CODE_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.RESEND_VERIFICATION_CODE_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //GET ALL CATEGORIES
    case actionType.GET_CATEGORIES_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_CATEGORIES_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_CATEGORIES_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //GET CATEGORIES MENU ITEMS
    case actionType.GET_CATEGORIES_MENUITEMS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_CATEGORIES_MENUITEMS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_CATEGORIES_MENUITEMS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //DELETE ACCOUNT 
      case actionType.DELETE_ACCOUNT_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.DELETE_ACCOUNT_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.DELETE_ACCOUNT_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //GET ORDER LIST
    case actionType.ORDERS_LIST_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.ORDERS_LIST_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.ORDERS_LIST_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //ORDER DETAILS LIST
    case actionType.ORDER_DETAILS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.ORDER_DETAILS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.ORDER_DETAILS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
      
  //Check Social media exist
      case actionType.SOCIAL_SIGNUP_A:
        return {
          ...state,
          isLoading: true,
        }
      case actionType.SOCIAL_SIGNUP_S:
        return {
          ...state,
          isLoading: false,
          data: action.data,
          error: undefined
        }
      case actionType.SOCIAL_SIGNUP_F:
        return {
          ...state,
          isLoading: false,
          error: action.error
        }
        
        //Get Location
    case actionType.LOCATION_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.LOCATION_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.LOCATION_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //CHECK SPECIAL OFFERS
    case actionType.CHECK_SPECIAL_OFFERS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.CHECK_SPECIAL_OFFERS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.CHECK_SPECIAL_OFFERS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //GET COMPANY CREDIT 
    case actionType.CHECK_COMPANY_CREDIT_SCORE_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.CHECK_COMPANY_CREDIT_SCORE_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.CHECK_COMPANY_CREDIT_SCORE_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //ADD COMPANY ADDRESS
    case actionType.ADD_COMPNAY_ADDRESS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.ADD_COMPNAY_ADDRESS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.ADD_COMPNAY_ADDRESS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //GET DELIVERY ADDRESS
    case actionType.GET_DELIVERY_ADDRESS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_DELIVERY_ADDRESS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_DELIVERY_ADDRESS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
        
  //DELETE DELIVERY ADDRESS
  case actionType.DELETE_DELIVERY_ADDRESS_A:
    return {
      ...state,
      isLoading: true,
    }
  case actionType.DELETE_DELIVERY_ADDRESS_S:
    return {
      ...state,
      isLoading: false,
      data: action.data,
      error: undefined
    }
  case actionType.DELETE_DELIVERY_ADDRESS_F:
    return {
      ...state,
      isLoading: false,
      error: action.error
    }

    //ADD DELIVERY ADDRESS
  case actionType.ADD_DELIVERY_ADDRESS_A:
    return {
      ...state,
      isLoading: true,
    }
  case actionType.ADD_DELIVERY_ADDRESS_S:
    return {
      ...state,
      isLoading: false,
      data: action.data,
      error: undefined
    }
  case actionType.ADD_DELIVERY_ADDRESS_F:
    return {
      ...state,
      isLoading: false,
      error: action.error
    }

    //Get App Message
    case actionType.APP_MESSAGE_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.APP_MESSAGE_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.APP_MESSAGE_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
      
    //Add Orders
    case actionType.ADD_ORDERS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.ADD_ORDERS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.ADD_ORDERS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //CHECK MENU ITEMS TIMES
    case actionType.CHECK_ITEM_TIME_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.CHECK_ITEM_TIME_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.CHECK_ITEM_TIME_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //GET SAVED CARDS
    case actionType.GET_SAVED_CARD_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_SAVED_CARD_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_SAVED_CARD_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //GET RE ORDER INFO
    case actionType.GET_REORDER_INFO_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_REORDER_INFO_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_REORDER_INFO_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //GET ITEM DETAILS 
    case actionType.GET_ITEM_DETAILS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_ITEM_DETAILS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_ITEM_DETAILS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //Add User Feedback
    case actionType.ADD_FEEDBACK_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.ADD_FEEDBACK_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.ADD_FEEDBACK_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //Get Card List
    case actionType.GET_CARD_LIST_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_CARD_LIST_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_CARD_LIST_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //Add Card Details 
    case actionType.ADD_CARD_DETAILS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.ADD_CARD_DETAILS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.ADD_CARD_DETAILS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //Delete Card Details 
    case actionType.DELETE_CARD_DETAILS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.DELETE_CARD_DETAILS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.DELETE_CARD_DETAILS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //Waiter List 
    case actionType.WAITER_LIST_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.WAITER_LIST_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.WAITER_LIST_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    //Waiter List 
    case actionType.WAITER_DETAIL_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.WAITER_DETAIL_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.WAITER_DETAIL_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      //Get avocado details 
    case actionType.GET_AVOCADO_DETAILS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_AVOCADO_DETAILS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_AVOCADO_DETAILS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

       // CHECK DELIVERY ADDRESS
    case actionType.CHECK_DELIVERY_ADDRESS_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.CHECK_DELIVERY_ADDRESS_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.CHECK_DELIVERY_ADDRESS_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }


      // CANCEL ORDER 
    case actionType.CANCEL_ORDER_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.CANCEL_ORDER_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.CANCEL_ORDER_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      // RESTO INFO 
    case actionType.GET_RESTO_INFO_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_RESTO_INFO_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_RESTO_INFO_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
      
      
      // VOUCHER CODE 
    case actionType.APPLY_VOUCHER_CODE_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.APPLY_VOUCHER_CODE_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.APPLY_VOUCHER_CODE_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

      // RESET PASSWORD 
    case actionType.RESET_PASSWORD_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.RESET_PASSWORD_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.RESET_PASSWORD_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    // Office Register
    case actionType.OFFICE_REGISTER_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.OFFICE_REGISTER_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.OFFICE_REGISTER_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    // DELIVERY CHARGE
    case actionType.DELIVERY_CHARGE_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.DELIVERY_CHARGE_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.DELIVERY_CHARGE_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    // NEXT DAY ORDER 
    case actionType.CHECK_NEXTDAY_ORDER_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.CHECK_NEXTDAY_ORDER_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.CHECK_NEXTDAY_ORDER_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    // GET COMPANY LIST
    case actionType.GET_COMPANY_LIST_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.GET_COMPANY_LIST_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.GET_COMPANY_LIST_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    // REMOVE LINKED COMPANY
    case actionType.REMOVE_LINKED_COMPANY_A:
      return {
        ...state,
        isLoading: true,
      }
    case actionType.REMOVE_LINKED_COMPANY_S:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: undefined
      }
    case actionType.REMOVE_LINKED_COMPANY_F:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    default:
      return state
  }

}



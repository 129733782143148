import React, { Component } from "react";
import {
  history,
} from "react-router-dom";
// import { createBrowserHistory } from "history";

import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constants from '../constants/baseConstants';
import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import FooterMenu from "../Components/FooterMenu";
import ItemPopup from '../Components/ItemPopup';
import AlertDialog from '../Components/AlertDialog';
import { Strings } from '../constants/Localization';
import AOS from 'aos';
import 'aos/dist/aos.css';
import moment from 'moment';
import {getPriceFormat,bindhttps} from "../Utils/utils";
import {setSecureData, getSecureData} from '../Utils/utils';
import baseConstants from "../constants/baseConstants";


var categoryName = ''

class MenuItems extends Component {

  constructor(props) {
    super(props);
    this.state = {
      arrayWholeMenuItems: [],
      arrayMenuItems: [],
      arraySubCategory: [],
      arrayFilterTypes: [],
      isFilter: false,
      isItemPopupVisible: false,
      objectAt: 0,
      // catId: JSON.parse(localStorage.getItem(Constants.PRE_CAT_ID)),//this.props.location.catId,
      // arrayMainCategories: JSON.parse(localStorage.getItem(Constants.CATEGORIESDATA)),//this.props.location.Categorieslist,
      catId: getSecureData(Constants.PRE_CAT_ID),//this.props.location.catId,
      arrayMainCategories: getSecureData(Constants.CATEGORIESDATA),//this.props.location.Categorieslist,

      isAlertVisible: false,
      alertMessage: '',
      btnLeft: '',
      btnRight: '',
      isMultiButton: false,
      languageType: 1,
    }
  }

  componentDidMount() {
    AOS.init({
      duration : 1000
    })
    if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
      // let userObject = localStorage.getItem(Constants.USEROBJECT);
      let userObject = getSecureData(Constants.USEROBJECT);
      if (userObject == null) {
        this.props.history.push({ pathname: '/' })
        return
      }
    }
    else {
      this.props.history.push({ pathname: '/' })
      return
    }

    
    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
    let session = getSecureData(Constants.PRE_MESSAGE_LIST);
    let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
    // let mLanguageCode = localStorage.getItem(Constants.PRE_LANGUAGE_CODE);    
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage('en'); //Setting selected language code here
        this.setState({ languageType: mLanguageType,})
      }
      else {
        if (mLanguageType != null) {
          Strings.setLanguage('es'); //Setting selected language code here
          this.setState({ languageType: mLanguageType,})
        }
      }
    }
    else {
      Strings.setLanguage('en');//Setting default language code here
    }

    setTimeout(() => {
      this.getCategoryItem()  
    }, 200);

  }


  //Handle change language click event
  //English language type: 1
  //Spanish language type: 2
  languageChangeClickEvent = (type) => {
    console.log("menuitem page language type :" + type);
    // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, type)
    setSecureData(Constants.PRE_LANGUAGE_TYPE, type)
    this.setState({ languageType: type })
    setTimeout(() => {
      this.props.AppMessageApi(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          var messageListArray = this.props.data.result;
          if (messageListArray != null && messageListArray.length > 0) {
            console.log("message list  :" + JSON.stringify(messageListArray))
            // localStorage.setItem(Constants.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
            setSecureData(Constants.PRE_MESSAGE_LIST, messageListArray);
            // let localMessage = JSON.parse(localStorage.getItem(Constants.PRE_MESSAGE_LIST));
            let localMessage = getSecureData(Constants.PRE_MESSAGE_LIST);
            // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, this.state.languageType)
            setSecureData(Constants.PRE_LANGUAGE_TYPE, this.state.languageType)
            // let languageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
            let languageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
            console.log('language type  :' + languageType);
            if (languageType == 1) { //English language type: 1
              Strings.setLanguage('en');//Setting selected language code here
              this.setState({ languageType: languageType,})
              // localStorage.setItem(Constants.PRE_LANGUAGE_CODE, "en-GB");
            }
            else {
              Strings.setLanguage('es'); //Setting selected language code here
              this.setState({ languageType: languageType,})
              // localStorage.setItem(Constants.PRE_LANGUAGE_CODE, "es");
            }
            Strings.setContent(localMessage)
            // this.setState({ languageType: languageType })
            //Call Menu items API while change language
            setTimeout(() => {
              this.callGetCategoryListApi()
            }, 200);
          }
        } else {
          alert(this.props.error)
        }
      })
    }, 700)
  }


  callGetCategoryListApi() {
    this.props.getCategoriesAPICall(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
            if (this.props.data.result != null) {
                setTimeout(() => {
                    if (this.props.data.result.length > 0) {
                        this.setState({ arrayMainCategories: this.props.data.result })
                        // localStorage.setItem(Constants.CATEGORIESDATA,JSON.stringify(this.props.data.result))
                        setSecureData(Constants.CATEGORIESDATA,this.props.data.result)
                        window.location.reload(false)
                        this.getCategoryItem()  
                    }
                }, 100)
            }
        } else {
            alert(this.props.data.message)
            console.log('Category status 2 ---- ',this.props.data)
        }
    })
}

  getCategoryItem() {

    this.state.arraySubCategory = []
    this.state.arrayFilterTypes = []

    let myCatData = this.state.arrayMainCategories.filter(data => (data.catId == this.state.catId))
    if(myCatData.length == 0)  {
      return
    }
    categoryName = myCatData[0].catName

    this.props.getCategoriesMenuItemsAPICall(this.state.catId,this.state.languageType).then(() => {
      // console.log("CATEGORIES MENU ITEMS RESPONSE --------- ",this.props.data)
      if (this.props.data.status == 1) {
        if (this.props.data.result != null) {
          setTimeout(() => {
            if (this.props.data.result.MenuCategoryList.length == 0) {

              this.setState({ arrayMenuItems: this.props.data.result.MenuItemList, arrayWholeMenuItems: this.props.data.result.MenuItemList })

              var filterTitle = []
              this.state.arrayMenuItems.map(menuData => (
                menuData.MenuStausList.map(status => {
                  var obj = filterTitle.filter(flt => (flt.title == status.title))
                  if (obj.length == 0) {
                    var imgURL = ''
                    if (status.iconurl == baseConstants.iconURL+'Resources/MenuStatus/PB.png')
                      // imgURL = "/images/PB.png" 
                      imgURL = '/images/status/PB.png'
                      // imgURL = baseConstants.mainURL+'PB.png'
                    else if (status.iconurl == baseConstants.iconURL+'Resources/MenuStatus/GF.png')
                      // imgURL = "/images/GF.png" 
                      imgURL = '/images/status/GF.png'
                      // imgURL = baseConstants.mainURL+'GF.png'
                    else if (status.iconurl == baseConstants.iconURL+'Resources/MenuStatus/PA.png')
                      // imgURL = "/images/PA.png" 
                      imgURL = '/images/status/PA.png'
                      // imgURL = baseConstants.mainURL+'PA.png'
                    else if (status.iconurl == baseConstants.iconURL+'Resources/MenuStatus/SG.PNG')
                      // imgURL = "/images/SG.png" 
                      imgURL = '/images/status/SG.png'
                      // imgURL = baseConstants.mainURL+'SG.png'
                    else if (status.iconurl == baseConstants.iconURL+'Resources/MenuStatus/VE.png')
                      // imgURL = "/images/VE.png" 
                      imgURL = '/images/status/VE.png'
                      // imgURL = baseConstants.mainURL+'Resources/MenuStatus/VE.png'
                      // imgURL = baseConstants.mainURL+'VE.png'
                    
                    // let fObj = { title: status.title, iconurl: status.iconurl, iconurlDark: status.iconurlDark, isSelected: false }
                    let fObj = { title: status.title, iconurl: imgURL, iconurlDark: status.iconurlDark, isSelected: false }
                    filterTitle.push(fObj)
                  }
                })
              ))
              console.log('resource ------------------ ',filterTitle)
              this.setState({ arrayFilterTypes: filterTitle })
            } else if (this.props.data.result.MenuCategoryList.length > 0) {
              this.setState({ arraySubCategory: this.props.data.result.MenuCategoryList, arrayMenuItems: this.props.data.result.MenuItemList, arrayWholeMenuItems: this.props.data.result.MenuItemList })
            }
            if (window.screen.width < 768) {
              window.scrollTo(0, 880)
            } else {
              window.scrollTo(0, 0)
            }
              
          }, 100)
        }
      } else {
        console.log('api errrrror')
      }
    })
  }

  onAddClick(i, menuitemId) {
    
    let myCatData = this.state.arrayMainCategories.filter(data => (data.catId == this.state.catId))
    let uktime = moment(myCatData[0].ukdatetime, "YYYY-MM-dd HH:mm:ss").format("HH:mm:ss")
    let ukdate = moment(myCatData[0].ukdatetime, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")

    this.props.checkItemTimeAPI(uktime,ukdate,this.state.catId,this.state.languageType).then(() => {
      
      if (this.props.data.status == 1) {
        if (this.props.data.result != null) {
          setTimeout(() => {

            console.log('Clicked at menuitemId ', menuitemId)
            if (this.state.arraySubCategory.length > 0) {
              for (var k = 0; k < this.state.arrayMenuItems.length; k++) {
                if (this.state.arrayMenuItems[k].menuitemId == menuitemId) {
                  i = k
                  break
                }
              }
            }
            console.log('Clicked at ', i)
            this.setState({ isItemPopupVisible: true, objectAt: i })

          }, 100)
        }
      } else {
        this.setState({isAlertVisible: true, alertMessage:this.props.data.message, isMultiButton: false, btnLeft:Strings.Ok_web})
      }
    })
  }

  renderOptions(optlist, title) {

    let filrt = optlist.filter(data => (data.labelText == title))

    var arrOpt = []
    for (var i = 0; i < filrt.length; i++) {
      arrOpt.push(
        <li key={i}>
          <label className="container1">
            <input
              type="checkbox"
              checked="checked"
            />
            <span className="checkmark"></span>
            {filrt[i].type}
          </label>
        </li>
      )
    }
    return arrOpt
  }

  renderOptionMenu(item, index) {
    console.log('index ------ ', item.menuItemName, index)

    var arrayTitle = []
    let optList = this.state.arrayMenuItems[index].PriceList[0].OptionList

    optList.map((data) => {
      var temp = arrayTitle.filter(obj => (obj == data.labelText))
      if (temp.length == 0) {
        arrayTitle.push(data.labelText)
      }
    })
    console.log('arrayTitle ------ ', arrayTitle.length, arrayTitle)

    var arrReturn = []
    for (var k = 0; k <= arrayTitle.length - 1; k++) {
      console.log('plate-div ----- ', k, optList)
      arrReturn.push(
        <div className="plate-div" key={k}>
          <h4>{arrayTitle[k]}</h4>
          <ul>
            {/* {this.renderOptions(optList,arrayTitle[k])} */}
          </ul>
        </div>
      )
    }
    return arrReturn
  }

  renderMenuStatus(status) {
    let obj = []
    status.map((data,index) => (
      obj.push(
        <li key={index}>
          <img src={bindhttps(data.iconurl)} />
          {/* <a href="#">
           
          </a> */}
        </li>
      )
    ))
    return obj
  }

  renderMenuItems(menuItem) {
    const itemDiv = menuItem.map((item, index) => (
      <div className="col-lg-4 col-md-12" key={index}>
        <div className="prd-s">
          <div className="prd-img">
            <img src={bindhttps(item.imgurl)}  onClick={() => { this.onAddClick(index, item.menuitemId) }}/>
            {/* <a href="">
              
            </a> */}
          </div>
          <div className="prd-cont">
            <ul className="vegi">
              {this.renderMenuStatus(item.MenuStausList)}
            </ul>

            <h3  onClick={() => { this.onAddClick(index, item.menuitemId) }}>{item.menuItemName}</h3>
            <p>{item.description}</p>
            <div className="price-btns">
              <p>{getPriceFormat(item.PriceList[0].price.toFixed(2))}</p>
              <button
                href=""
                className="btn btn-primary btn-medium"
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#myModal"
                key={index}
                onClick={() => { this.onAddClick(index, item.menuitemId) }}
              >
                {Strings.lbl_edit_add_react}
              </button>
            </div>
          </div>
        </div>
      </div>
    ));
    return itemDiv
  }

  onFilterClick(filterData) {

    filterData.isSelected = !filterData.isSelected
    this.setState({ arrayFilterTypes: this.state.arrayFilterTypes })

    let flt = this.state.arrayFilterTypes.filter(data => (
      data.isSelected == true
    ))
    if (flt.length > 0) {

      let menuItem = this.state.arrayWholeMenuItems
      var filteredItem = []
      for (var a = 0; a < flt.length; a++) {
        for (var i = 0; i < menuItem.length; i++) {
          for (var k = 0; k < menuItem[i].MenuStausList.length; k++) {
            if (flt[a].title == menuItem[i].MenuStausList[k].title) {
              let aObj = filteredItem.filter(aData => (aData.menuitemId == menuItem[i].menuitemId))
              if (aObj.length == 0) {
                filteredItem.push(menuItem[i])
              }
            }
          }
        }
      }

      this.setState({ arrayMenuItems: filteredItem })

    } else {

      this.setState({ arrayMenuItems: this.state.arrayWholeMenuItems })
    }
  }

  renderFilter() {
    let obj = this.state.arrayFilterTypes.map((data, index) => (
      <a onClick={() => { this.onFilterClick(data) }} key={index}>
        <li>
          {data.title} {(data.isSelected == true) ? <img src={data.iconurlDark} /> : <img src={data.iconurl} />}
        </li>
      </a>
    ))
    return (
      <ul className="list-btn">{obj}</ul>
    )
  }

  onSelectCategory(id) {
    this.setState({ catId: id })
    // localStorage.setItem(Constants.PRE_CAT_ID,JSON.stringify(id))
    setSecureData(Constants.PRE_CAT_ID,id)
    setTimeout(() => {
      this.getCategoryItem()
    }, 200)

  }

  renderCategoryView() {

    let catArray = []
    this.state.arrayMainCategories.map(category => (
      catArray.push(
        <li key={category.catId}>
          {this.state.catId == category.catId ? (
            <a herf="" data-toggle="tab" className="active" onClick={() => { this.onSelectCategory(category.catId) }}>
              <p>{category.catName}</p>
              <img src={bindhttps(category.thumbimgurl)} />
            </a>
          ) : (
              <a herf="" data-toggle="tab" onClick={() => { this.onSelectCategory(category.catId) }}>
                <p>{category.catName}</p>
                <img src={bindhttps(category.thumbimgurl)} />
              </a>
            )}

        </li>
      )
    ))
    return (
      <ul className="nav nav-tabs tabs-left sideways">
        {catArray}
      </ul>
    )
  }

  renderSubCategoryView() {

    let catObjects = []
    this.state.arraySubCategory.map(catData => {
      var filt = this.state.arrayMenuItems.filter(menuItem => (menuItem.catId == catData.catId))
      catObjects.push({ catTitle: catData.catName, menuItems: filt })
    })
    console.log('cat object ----- ', catObjects)

    var objFinal = []
    catObjects.map(catData => (
      objFinal.push(
        <div className="dr1">
          <h2>{catData.catTitle}</h2>
          <div className="row">
            {this.renderMenuItems(catData.menuItems)}
          </div>
        </div>
      )
    ))
    return objFinal
  }

  onViewOrderClick() {
    this.setState({ isAlertVisible: false })
    this.props.history.push({pathname:'/CheckoutDelivery'})
  }

  renderBanner() {
    
    let myCatData = this.state.arrayMainCategories.filter(data => (data.catId == this.state.catId))
    console.log('myCatData ------------------------ ',myCatData)
    if(myCatData.length > 0)  {
      return ((myCatData[0].bannerfullimgurl) ? <img src={bindhttps(myCatData[0].bannerfullimgurl)} className="w-100" /> : <img src="/images/dishes-banner.jpg" className="w-100" />)
    }

    console.log('========================',this.state.catId,"========================",myCatData)
    // if (this.state.catId == 2984) { // Breakfast
    //   return (<img src="/images/dishes-banner.jpg" className="w-100" />)
    // } else if (this.state.catId == 2922) { //Garden Bowl
    //   return (<img src="/images/banner_gardenbowl.png" className="w-100" />)
    // } else if (this.state.catId == 2921) { //Market Plate
    //   return (<img src="/images/banner_marketplate.png" className="w-100" />)
    // } else if (this.state.catId == 2949) { // Drinks
    //   return (<img src="/images/banner_drinks.png" className="w-100" />)
    // } else if (this.state.catId == 2980) { // Dessert
    //   return (<img src="/images/banner_dessert.png" className="w-100" />)
    // } else {
    //   return (<img src="/images/dishes-banner.jpg" className="w-100" />)
    // }
  }

  render() {
    return (
      <div className="white dishes">
        {this.props.isLoading ? (
          <div className="loadermain" style={{ display: "block" }}>
            <div className="loader"></div>
          </div>
        ) : (
            <div className="loadermain" style={{ display: "none" }}>
              <div className="loader"></div>
            </div>
          )}
          {/* {this.props.isLoading ? (
            <div className="lds-dual-ring" style={{ display: "block" }}></div>
          ) : (
            <div className="lds-dual-ring" style={{ display: "none" }}></div>
          )} */}
          <HeaderWhiteMenu logoType={1}
          changeLanguageEvent={this.languageChangeClickEvent} />
        <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={this.state.isMultiButton} btnLeftClick={() => { this.setState({ isAlertVisible: false }) }} btnRightClick={() => { this.onViewOrderClick() }} btnLeftTitle={this.state.btnLeft} btnRightTitle={this.state.btnRight} />
        {/* {this.state.arrayMenuItems[this.state.objectAt] ? <ItemPopup itemData={this.state.arrayMenuItems[this.state.objectAt]} onClose={() => { this.setState({ isItemPopupVisible: false }) }} isVisible={this.state.isItemPopupVisible}/> : console.log("should not render menu popup")} */}
        {this.state.isItemPopupVisible ? <ItemPopup itemData={this.state.arrayMenuItems[this.state.objectAt]} onClose={(popup) => { console.log(popup); this.setState({ isItemPopupVisible: false, isAlertVisible: popup.isHide, alertMessage: popup.isNewItem ? Strings.error_msg_item_added_react : Strings.error_msg_item_update_react, btnLeft: Strings.Ok_web, isMultiButton: true, btnRight: Strings.btn_view_order_react }) }} isVisible={this.state.isItemPopupVisible} /> : null}
        {/* <ItemPopup itemData={this.state.arrayMenuItems[this.state.objectAt]} onClose={() => { this.setState({ isItemPopupVisible: false }) }} isVisible={this.state.isItemPopupVisible}/> */}

        <div className="inner-banner">
          {this.renderBanner()}
          
          <div className="inner-banner-cont">
            <div className="container">

              <h1>{categoryName}</h1>
              {this.renderFilter()}
            </div>
          </div>
        </div>
        <div className="content_wrap">
          <div className="dishes-page">
            <div className="row">
              {/* <!-- required for floating --> */}
              <div className="col-lg-4 col-md-4 left">
                {/* <!-- Nav tabs --> */}
                <h2>
                  {Strings.lbl_choose_react} <br /> {Strings.lbl_a_product_react}
                </h2>
                {(this.state.arrayMainCategories) ? this.renderCategoryView() : console.log('error in categories render')}

              </div>

              <div className="col-md-8 col-md-8 right">
                {/* <!-- Tab panes --> */}
                <div className="tab-content">
                  {this.state.arraySubCategory.length == 0 ? (
                    <div className="tab-pane active" id="home-v">
                      <div className="row">
                        {this.renderMenuItems(this.state.arrayMenuItems)}
                      </div>
                    </div>
                  ) : (
                      <div className="tab-pane drinks active" id="settings-2">
                        {this.renderSubCategoryView()}
                      </div>
                    )}
                  {/* <!--tab seting end--> */}
                </div>
              </div>

              <div className="clearfix"></div>
            </div>
          </div>
        </div>

        <FooterMenu />

      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log('isLoaging Categories MENU Items------- ' + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error
  }
}

const mapDispatchToProps = dispatch => ({
  getCategoriesAPICall: (languageType) => dispatch(HGActions.initGetAllCategoriesCall(languageType)),
  checkItemTimeAPI: (contactTime,orderDate,catId,languageType) => dispatch(HGActions.initCheckMenuItemTimeAPI(contactTime,orderDate,catId,languageType)),
  getCategoriesMenuItemsAPICall: (categories, languageType) => dispatch(HGActions.initGetCategoriesMenuItemsCall(categories, languageType)),
  AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuItems);

import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import FooterMenu from '../Components/FooterMenu';
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constants from '../constants/baseConstants';
import { Strings } from '../constants/Localization';
import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import baseConstants from '../constants/baseConstants';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {getPriceFormat} from "../Utils/utils";
import {setSecureData, getSecureData} from '../Utils/utils';
import AlertDialog from '../Components/AlertDialog';


let customerId = 0;

class cancelOrder extends Component {

    constructor(props) {
        super(props)
        this.state = {

            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            alertType: 0,
        }

    }

    componentDidMount() {

        if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
            // let userObject = localStorage.getItem(Constants.USEROBJECT);
            let userObject = getSecureData(Constants.USEROBJECT);
            if (userObject == null) {
              this.props.history.push({ pathname: '/' })
              return
            }
          }
          else {
            this.props.history.push({ pathname: '/' })
            return
          }

          if (localStorage.hasOwnProperty(Constants.PLACEDORDEROBJECT)) {
            let orderObj = getSecureData(Constants.PLACEDORDEROBJECT);
            if (orderObj == null) {
              this.props.history.push({ pathname: '/' })
              return
            }
          }
          else {
            this.props.history.push({ pathname: '/' })
            return
          }

        this.setState({alertType:0, isAlertVisible: true, alertMessage:Strings.msg_cancel_order, isMultiButton: true, btnLeft:Strings.str_no, btnRight: Strings.yes})

    }


    onLeftClick() {
        this.setState({ isAlertVisible: false },()=>{
            if(this.state.alertType == 0) {
                this.props.history.push({pathname:'/PaymentCardList'})
            } else if (this.state.alertType == 1) {
                this.props.history.push({pathname:'/CheckoutDelivery', isCancelOrder: true})
                // setTimeout(() => {
                //     localStorage.removeItem(Constants.PLACEDORDEROBJECT)
                // }, 200);
            }
        })
    }

    onRightClick() {
        this.setState({ isAlertVisible: false },() => {
            this.props.history.push({pathname:'/CheckoutDelivery', isCancelOrder: true})
            
            // let oId = getSecureData(Constants.PLACEDORDEROBJECT).orderId

            // console.log('getSecureData order object ------------------- ',getSecureData(Constants.PLACEDORDEROBJECT))
            
            // var str = {
            //     "orderId": oId,
            //     "paymentstatus": "fail",
            //     "transactionID": "",
            //     "transactionfrom": 1,
            //     "response": "Manually Cancelled",
            // }
            // this.props.callCancelAPI(str,1).then(() => {
            //     if (this.props.data.status == 1) {
            //         if (this.props.data.result != null) {
            //             this.setState({alertType:1, isAlertVisible: true, alertMessage:this.props.data.message, isMultiButton: false, btnLeft:Strings.Ok_web})
            //             console.log('callCancelAPI status if---- ---- ',this.props.data.message)
            //         }
            //     } else {
            //         alert(this.props.data.message)
            //         this.setState({alertType:1, isAlertVisible: true, alertMessage:this.props.data.message, isMultiButton: false, btnLeft:Strings.Ok_web})
            //         console.log('callCancelAPI status 2 ---- ',this.props.data)
            //     }
            // })
            // .catch((error) => {

            // })
        })
    }


    render() {
        return (
            <div>
                {this.props.isLoading ? <div class="loadermain" style={{ display: 'block' }}>
                    <div class="loader" ></div>
                </div> : <div class="loadermain" style={{ display: 'none' }}>
                        <div class="loader" ></div>
                    </div>}
                {/* <HeaderWhiteMenu changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/> */}

                <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={this.state.isMultiButton} btnLeftClick={() => { this.onLeftClick() }} btnRightClick={() => { this.onRightClick() }} btnLeftTitle={this.state.btnLeft} btnRightTitle={this.state.btnRight} />
                
                {/* <FooterMenu/> */}
            </div>
        )
    }

    
}

function mapStateToProps(state) {
    console.log('isLoaging Cancel Order ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}
const mapDispatchToProps = dispatch => ({
    // orderListApi: (customerId, languageType) => dispatch(HGActions.GetOrderListApi(customerId, languageType)),
    callCancelAPI: (TransactionInfo,languageType) => dispatch(HGActions.initCancelOrderAPI(TransactionInfo,languageType)),

})
export default connect(mapStateToProps, mapDispatchToProps)(cancelOrder);



import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import queryString from 'query-string';
import { ThemeProvider } from 'react-bootstrap';


class ResetPassword extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading:false,
            strNewPassword: '',
            strConfirmPassword: '',
            errors: {
                errStrNewPassword: '',
                errStrConfirmPassword: '',                
            },
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1,   

        }
    }
    componentDidMount(){
        
        let params = queryString.parse(this.props.location.search)
        if (params.customerId) {
            console.log('query string -------------> ', params)
        }
        

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }


    }

    

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my account page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    onResetPassword = (event) => {
        event.preventDefault();

        var newError = this.state.strNewPassword.length == 0 ? Strings.enter_new_pass_web : '';
        var confirmErr = this.state.strConfirmPassword.length == 0 ? Strings.enter_confirm_pass_web : this.state.strNewPassword != this.state.strConfirmPassword ? Strings.confPassNotMatch_web : '';
        
        this.setState({ errors: { errStrNewPassword: newError, errStrConfirmPassword: confirmErr} }, () => {
            let params = queryString.parse(this.props.location.search)
            if (params.customerId) {
                console.log('query string -------------> ', params)
                if (this.state.errors.errStrNewPassword == '' && this.state.errors.errStrConfirmPassword == '') {
                    console.info('Valid form')
                    // let location = JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT))
                    this.props.forgotPassword(params.customerId, this.state.strNewPassword, this.state.strConfirmPassword).then(() => {
                        console.log("reset password response --------- ", this.props.data)
                        if (this.props.data.status == 1) {
                            this.setState({isAlertVisible: true, alertMessage: this.props.data.message, isMultiButton: false, btnLeft: Strings.Ok_web})
                        } else {
                            this.setState({isAlertVisible: true, alertMessage: this.props.data.message, isMultiButton: false, btnLeft: Strings.Ok_web})
                        }
                    })
                    .catch((err)=>{
                        console.log('error ',err)
                    })
    
                } else {
                    console.error('Not valid form')
                }
            }
        })
    }

    render() {
        return (
            <div>
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
                {/* <HeaderWhiteMenu
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/> */}
                <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={this.state.isMultiButton} btnLeftClick={() => { this.setState({ isAlertVisible: false }) }} btnRightClick={() => { this.onRightClick() }} btnLeftTitle={this.state.btnLeft} btnRightTitle={this.state.btnRight} />
                <div className="content_wrap">
                    <div className="container">
                        <div className="user">

                            <div className="row">
                                <div className="col-md-12  col-lg-6 center" style={{margin:'0 auto'}}>
                                    <h1 className="text-center">{Strings.reset_pass_web}</h1>
                                    <div className="form-group">
                                        <label>{Strings.new_password_web}*</label>
                                        <input className="form-control" type="password" value={this.state.strNewPassword} onChange={e => this.setState({ strNewPassword: e.target.value })} />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.errStrNewPassword}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{Strings.confPassword_web}*</label>
                                        <input className="form-control" type="password" value={this.state.strConfirmPassword} onChange={e => this.setState({ strConfirmPassword: e.target.value })} />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.errStrConfirmPassword}</span>
                                        </div>
                                    </div>
                                    <div className="buttons-checkout">
                                        <button className="btn btn-primary btn-medium" onClick={this.onResetPassword}>{Strings.reset_pass_web}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <FooterMenu /> */}
            </div>
        )
    }

}

function mapStateToProps(state) {
    console.log('isLoaging ResetPassword ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    forgotPassword: (customerId, password, confirmPassword) => dispatch(HGActions.initResetPasswordAPI(customerId, password, confirmPassword)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import FooterMenu from '../Components/FooterMenu';
import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import Constant from '../constants/baseConstants';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import LinkCompany from '../Components/LinkCompany';
import {setSecureData, getSecureData} from '../Utils/utils';
import { Dropdown } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class MyAccount extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // isContentLoading:false,
            name: '',
            countryCode: 0,
            phoneNumber: '',
            email: '',
            password: '',
            linkedCompanyName: '',
            customerId: 0,
            languageType: 1, //default language type
            errors: {
                nameError: '',
                countryCodeError: '',
                phoneNumberError: '',
                emailError: '',
                passwordError: '',
                errorLinkedCompanyName: '',
            },

            arrayCards: [],
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1,
            objCard: {},
            arrayAddressList: [],
            objAvocadoDetails: {},
            selectedAddressIndex:0,

            isLinkVisible: false,
            arrCompanyList: [],
            arrSearchedCompany: [],
            objSelectedCompany: {},

        }
    }
    componentDidMount(){
        
        // this.setState({isContentLoading:true})
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                this.setState({
                    customerId: userObject.customerId, name: userObject.firstname, countryCode: userObject.countryCode,
                    phoneNumber: userObject.phone, email: userObject.emailId, password: userObject.password, linkedCompanyName: userObject.linkedCompanyName
                })
            }
            else {
                this.props.history.push({ pathname: '/' })
                return
            }
                
        }
        else {
            this.props.history.push({ pathname: '/' })
            return
        }
            

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        // var cId = JSON.parse(localStorage.getItem(Constant.USEROBJECT)).customerId
        var cId = getSecureData(Constant.USEROBJECT).customerId

        this.props.callCheckVersion(cId,this.state.languageType).then(() => {
            if (this.props.data.status == 1) {
                this.setState({linkedCompanyName: this.props.data.result.linkedCompanyName})

                // var userObject = JSON.parse(localStorage.getItem(Constant.USEROBJECT))
                var userObject = getSecureData(Constant.USEROBJECT)
                userObject.companyId = this.props.data.result.companyId
                userObject.isLinkToCompany = this.props.data.result.isLinkToCompany
                userObject.isRequestedToLink = this.props.data.result.isRequestedToLink
                userObject.linkedCompanyName = this.props.data.result.linkedCompanyName
                // localStorage.setItem(Constant.USEROBJECT, JSON.stringify(userObject))
                setSecureData(Constant.USEROBJECT, userObject)

                // localStorage.setItem(Constant.CHECKINFO, JSON.stringify(this.props.data.result))
                setSecureData(Constant.CHECKINFO, this.props.data.result)
            }
            
            //CALL SAVED CARDS API
            // this.props.getSavedCards(JSON.parse(localStorage.getItem(Constant.USEROBJECT)).customerId).then(() => {
            this.props.getSavedCards(getSecureData(Constant.USEROBJECT).customerId).then(() => {
                console.log("getSavedCards RESPONSE --------- ",this.props.data)
                if (this.props.data.status == 1) {
                    if (this.props.data.result != null) {
                        if (this.props.data.result.length > 0) {
                            this.setState({arrayCards:this.props.data.result})
                        }
                    }
                } else {
                    
                }
                //CALL DELIVERY ADDRESS API
                // let customer = JSON.parse(localStorage.getItem(Constant.USEROBJECT)).customerId
                // let id = JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT)).locationId
                let customer = getSecureData(Constant.USEROBJECT).customerId
                let id = getSecureData(Constant.LOCATIONOBJECT).locationId
                this.props.getDeliveryAddressAPI(id,customer,this.state.languageType).then(() => {
                    if (this.props.data.status == 1) {
                        
                        if(this.props.data.result.AddressLists) {
                            if (this.props.data.result.AddressLists.length > 0) {
                                this.setState({arrayAddressList: this.props.data.result.AddressLists})
                            }
                        }
                    }


                    //Call get avocado details API 
                    this.props.getAvocadoDetailsAPI(this.state.languageType).then(() => {
                        if (this.props.data.status == 1) {
                            this.setState({objAvocadoDetails: this.props.data.result})
                        }

                        setTimeout(() => {
                            this.props.getCompanyAPI(this.state.languageType)
                            .then(() => {
                                console.log('get company list------- > ',this.props.data)
                                if (this.props.data.status == 1) {
                                    this.setState({arrCompanyList: this.props.data.result.companyList})
                                }
                            })
                            .catch((error) => {

                            })
                        }, 200);
                    })
                    .catch((error) => {
                        this.props.getCompanyAPI(this.state.languageType)
                        .then(() => {
                            console.log('get company list------- > ',this.props.data)
                            if (this.props.data.status == 1) {
                                this.setState({arrCompanyList: this.props.data.result.companyList})
                            }
                        })
                        .catch((error) => {

                        })
                    })
                })
            })

        })
        

    }

    

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my account page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    onSaveClick = (event) => {
        event.preventDefault();

        var nmError = this.state.name.length == 0 ? Strings.error_msg_name_react : '';
        var ccodeerror = this.state.countryCode == 0 ? Strings.error_msg_country_code_react : '';
        var phError = this.state.phoneNumber.length < 9 ? Strings.error_msg_phone_no_react : '';
        var emlError = this.state.email.length == 0 ? Strings.error_msg_email_react : '';
        var passError = this.state.password.length == 0 ? Strings.error_msg_password_react : '';
        // var companyError = this.state.linkedCompanyName.length == 0 ? Strings.error_msg_company_account_react : '';

        if (emlError.length == 0) {
            validEmailRegex.test(this.state.email) == false ? emlError = Strings.error_msg_valid_email_react : emlError = '';
        }

        // this.setState({ errors: { errorLinkedCompanyName: companyError, nameError: nmError, countryCodeError: ccodeerror, phoneNumberError: phError, emailError: emlError, passwordError: passError } })
        this.setState({ errors: { nameError: nmError, countryCodeError: ccodeerror, phoneNumberError: phError, emailError: emlError, passwordError: passError } })

        // let userObject = JSON.parse(localStorage.getItem(Constant.USEROBJECT))
        let userObject = getSecureData(Constant.USEROBJECT)
        console.log(userObject)

        setTimeout(() => {
            // if (this.state.errors.nameError == '' && this.state.errors.errorLinkedCompanyName == '' && this.state.errors.countryCodeError == '' && this.state.errors.phoneNumberError == '' && this.state.errors.emailError == '' && this.state.errors.passwordError == '') {
            if (this.state.errors.nameError == '' && this.state.errors.countryCodeError == '' && this.state.errors.phoneNumberError == '' && this.state.errors.emailError == '' && this.state.errors.passwordError == '') {
                console.info('Valid form')
                // let location = JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT))
                let location = getSecureData(Constant.LOCATIONOBJECT)
                this.props.UpdateCustomerAPI(location.locationId,this.state.customerId, this.state.name, this.state.countryCode, this.state.phoneNumber, this.state.email, this.state.password, this.state.languageType).then(() => {
                    console.log("Update profile response --------- ", this.props.data)
                    if (this.props.data.status == 1) {
                        if (this.props.data.result != null) {
                            setTimeout(() => {
                                let result = this.props.data.result;
                                result.companyId = userObject.companyId
                                result.isLinkToCompany = userObject.isLinkToCompany
                                result.isRequestedToLink = userObject.isRequestedToLink
                                result.linkedCompanyName = userObject.linkedCompanyName
                                // localStorage.setItem(Constant.USEROBJECT, JSON.stringify(result))
                                setSecureData(Constant.USEROBJECT, result)
                                console.log(result)
                                if (result.isMobileVerify == true) {
                                    alert(this.props.data.message)
                                    //CALL Company link API
                                    
                                } else {
                                    //Move on Mobile verification screen
                                    this.props.history.push({ pathname: '/MobileVerification', customerId: result.customerId, phone: this.state.countryCode + this.state.phoneNumber, message: this.props.data.message, isProfileUpdate: true })
                                }
                            }, 100)
                        }
                    } else {
                        alert(this.props.data.message)
                        console.log("sign up status else...")
                    }
                })


                // setTimeout(() => {
                //     this.props.CompanyAccountLinkApi(this.state.customerId, this.state.linkedCompanyName, this.state.languageType).then(() => {
                //         if (this.props.data.status == 1) {
                //             if (this.props.data.result != null) {
                //                 if (this.props.data.result.companyName != null && this.props.data.result.companyName.length > 0) {
                //                     console.log("company account success : " + this.props.data.result.companyName)
                //                 }
                //             }
                //         } else {
                //             console.log("company account fail :" + this.props.error)
                //         }
                //     })
                // }, 200)


            } else {
                console.error('Not valid form')
            }
        }, 100)
    }

    callCompanyLink() {
        let userObject = getSecureData(Constant.USEROBJECT)
        
        if (userObject.isLinkToCompany) {
            this.setState({dialogType:4, isAlertVisible: true, alertMessage:Strings.company_link_delete_web, isMultiButton: true, btnLeft:Strings.str_no, btnRight: Strings.yes })            
            return
        }
        if (userObject.isRequestedToLink) {
            return
        }
        if (this.state.objSelectedCompany.CRC) {
            this.props.CompanyAccountLinkApi(this.state.customerId, this.state.objSelectedCompany.addressId, this.state.objSelectedCompany.companyId, this.state.languageType)
            .then(() => {
                console.log("company link -------- : ", this.props.data)
                if (this.props.data.status == 1) {
                    if (this.props.data.result != null) {
                        if (this.props.data.result.companyName != null && this.props.data.result.companyName.length > 0) {
                            console.log("company account success : ", this.props.data.result)
                            userObject.companyId = this.props.data.result.companyId
                            userObject.isLinkToCompany = this.props.data.result.isLinkToCompany
                            userObject.isRequestedToLink = this.props.data.result.isRequestedToLink
                            userObject.linkedCompanyName = this.props.data.result.linkedCompanyName
                            setSecureData(Constant.USEROBJECT,userObject)
                            setTimeout(() => {
                                this.setState({})
                            }, 100);
                        }
                    }
                } else {
                    alert(this.props.data.message)
                }
            })
            .catch((error) => {
                console.log("company account fail catch :" + this.props.error)
            })
        } else {
            console.log('link api call else')
        }
        // let userObject = getSecureData(Constant.USEROBJECT)
        // if (userObject.linkedCompanyName != this.state.linkedCompanyName && this.state.linkedCompanyName.length > 0) {
        //     this.props.CompanyAccountLinkApi(this.state.customerId, this.state.linkedCompanyName, this.state.languageType)
        //     .then(() => {
        //         if (this.props.data.status == 1) {
        //             if (this.props.data.result != null) {
        //                 if (this.props.data.result.companyName != null && this.props.data.result.companyName.length > 0) {
        //                     console.log("company account success : ", this.props.data.result)
        //                     userObject.companyId = this.props.data.result.companyId
        //                     userObject.isLinkToCompany = this.props.data.result.isLinkToCompany
        //                     userObject.isRequestedToLink = this.props.data.result.isRequestedToLink
        //                     userObject.linkedCompanyName = this.props.data.result.linkedCompanyName
        //                     setSecureData(Constant.USEROBJECT,userObject)
        //                 }
        //             }
        //         } else {
        //             alert(this.props.data.message)
        //         }
        //     })
        //     .catch((error) => {
        //         console.log("company account fail catch :" + this.props.error)
        //     })
        // } else {
        //     console.log('link api call else')
        // }
    }

    onDeleteClick = (event) => {
        event.preventDefault();
        this.setState({dialogType:1, isAlertVisible: true, alertMessage:Strings.msg_delete_account, isMultiButton: true, btnLeft:Strings.str_no, btnRight: Strings.yes })
    }

    onEditCard(cards) {
        // localStorage.setItem(Constant.PRE_CARD_LIST, JSON.stringify(cards))
        setSecureData(Constant.PRE_CARD_LIST, cards)
        this.props.history.push({ pathname: '/CardDetails' })
    }
    
    onDeleteCard(cards) {
        this.setState({objCard: cards, dialogType:2, isAlertVisible: true, alertMessage:Strings.msg_delete_card, isMultiButton: true, btnLeft:Strings.str_no, btnRight: Strings.yes })
    }

    onDeleteDeliveryAddress(index) {
        this.setState({selectedAddressIndex:index, dialogType:3, isAlertVisible: true, alertMessage:Strings.delete_delivery_address, isMultiButton: true, btnLeft:Strings.str_no, btnRight: Strings.yes })
    }

    renderDeliveryAddress() {

        var arrReturn = []
        this.state.arrayAddressList.map((address,index) => {
            arrReturn.push(
                <div className="daddress" key={index}>
                    <div className="addressList">
                        {address.address1}<br />
                        {address.address2 != "" ? Strings.lbl_comment_react+' : '+address.address2 : ""}
                    </div>
                    <button onClick={()=>this.onDeleteDeliveryAddress(index)}><img src="/images/delete.png"/></button>
                </div>
            )
        })  

        return (
            (arrReturn.length > 0) ? 
            <div className="form-group">
                <label>{Strings.DeliveryAddress_web}</label>
                {arrReturn}
            </div> : null
        )
    }
    

    renderCardView() {

        var arrReturn = []
        this.state.arrayCards.map((cards,index) => {
            arrReturn.push(
                <div>
                    {/* <div className="form-group">
                        <label>{Strings.lbl_credit_card_name_react}</label>
                        <div className="card-name">Jhonn Smith</div>
                    </div> */}
                    <div className="form-group">
                        {/* <label>{Strings.lbl_details_react}</label> */}
                        <div className="card-detail">{cards.cardNo}<span> - {cards.cardType}</span>
                        </div>
                    </div>
                    <div className="signup">
                        <a href="javascript:void(0);" className="btn btn-primary btn-medium" onClick={()=>{this.onEditCard(cards)}}>{Strings.btn_edit_details_react}</a>
                        <a href="javascript:void(0);" className="btn trans-btn btn-medium" onClick={()=>{this.onDeleteCard(cards)}}>{Strings.btn_delete_card_react}</a>
                    </div>
                </div>
            )
        })
        return arrReturn
    }

    onRightClick() {
        this.setState({ isAlertVisible: false })
        if (this.state.dialogType == 1) {
            setTimeout(() => {
                this.props.DeleteAccountAPI(this.state.customerId, this.state.languageType).then(() => {
                    console.log("Delete Account response --------- ", this.props.data)
                    if (this.props.data.status == 1) {
                        if (this.props.data.result != null) {
                            setTimeout(() => {
                                //clear local data
                                localStorage.removeItem(Constant.USEROBJECT)
                                alert(this.props.data.message)
                                this.props.history.push('/')
                            }, 100)
                        }
                    } else {
                        alert(this.props.data.message)
                    }
                })
            }, 200);
        } else if (this.state.dialogType == 2) {
            let mainObj = {
                restaurantId: 147,
                // customerId: JSON.parse(localStorage.getItem(Constant.USEROBJECT)).customerId,
                customerId: getSecureData(Constant.USEROBJECT).customerId,
                languageId: this.state.languageType,
                // locationId: JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT)).locationId,
                locationId: getSecureData(Constant.LOCATIONOBJECT).locationId,
                CardId: this.state.objCard.CardId,
            }
            this.props.cardDeleteApi(mainObj).then(() => {
                if (this.props.data.status == 1) {
                    var tempArray = this.state.cardArray
                    let index = tempArray.indexOf(this.state.objCard)
                    if (index >= -1) {
                        tempArray.splice(index,1)
                    }
                    this.setState({ cardArray: tempArray})  
                    
                    // const list = this.state.cardArray;
                    // list.splice(index, 1);
                    // if (list != null && list.length > 0) {
                    //     this.setState({ cardArray: list })
                    // }
                    // else {
                    //     this.setState({ cardArray: [], errorMessage: Strings.error_msg_no_card_react })
                    // }
                }
                else {
                }
            })
        } else if (this.state.dialogType == 3) {
            let objAdd = this.state.arrayAddressList[this.state.selectedAddressIndex]
            console.log('delivery address ------- ',objAdd)
            this.props.deleteAddressAPI(objAdd.deliveryaddressId).then(() => {
                console.log('deleteAddressAPI response ----- ',this.props.data.result)
                if (this.props.data.status == 1) {
                    setTimeout(() => {
                        
                        var tempArray = this.state.arrayAddressList
                        let index = tempArray.indexOf(objAdd)
                        if (index >= -1) {
                            tempArray.splice(index,1)
                        }
                        this.setState({arrayAddressList:tempArray,isAlertVisible: true, alertMessage:Strings.delete_address, isMultiButton: false, btnLeft:Strings.Ok_web})
                        
                    }, 100)
                } else {
    
                }
            })
        } else if (this.state.dialogType == 4) {
            let userObject = getSecureData(Constant.USEROBJECT)
            this.props.removeLinkedAPI(userObject.companyId, userObject.customerId, this.state.languageType).then(() => {
                console.log("removeLinkedAPI response --------- ", this.props.data)
                if (this.props.data.status == 1) {
                    if (this.props.data.result != null) {
                        
                            let result = userObject
                            result.companyId = 0
                            result.isLinkToCompany = false
                            result.isRequestedToLink = false
                            result.linkedCompanyName = ''
                            setSecureData(Constant.USEROBJECT, result)
                            
                            // alert(this.props.data.message)
                            this.setState({dialogType:0, isAlertVisible: true, alertMessage:this.props.data.message, isMultiButton: false, btnLeft:Strings.Ok_web,})
                            this.setState({linkedCompanyName:''})
                            
                    }
                } else {
                    alert(this.props.data.message)
                    console.log("sign up status else...")
                }
            })
        }
        
    }

    onSelectCompany(i) {
        console.log('this.state.arrSearchedCompany[i] ------- ',this.state.arrSearchedCompany[i])
        this.setState({objSelectedCompany: this.state.arrSearchedCompany[i]},()=> {
            this.setState({linkedCompanyName: this.state.objSelectedCompany.companyName,arrSearchedCompany:[]})
        })
    }
    
    renderCompanyList() {
        let arr = []
        if(!this.state.arrSearchedCompany) {
          arr.push( 
            <label className="noData">{Strings.no_record_address}</label>
          )
          return arr
        }
        this.state.arrSearchedCompany.map((company,index) => {
            arr.push(
                <li key={index}>
                    <button className="" onClick={()=>this.onSelectCompany(index)}><label className="container1">{company.companyName}</label></button>
                    {/* <button className="checked-btn" onClick={()=>this.onSelectCompany(index)}><img src={this.state.selectedIndex == index ? '/images/checked.png' : '/images/uncheck.png'}/></button><label className="container1">{company.companyName}</label> */}
                </li>
            )
        })
        return arr
    }

    onSearch(val) {
        this.setState({ linkedCompanyName:  val}, ()=>{
            
            if (val == '' || val == null) {
                return
            }
            let obj = this.state.arrCompanyList.filter(company => 
                company.companyName.toLowerCase().indexOf(this.state.linkedCompanyName) > -1
            )
            console.log('searched obj------------ ',obj)
            this.setState({arrSearchedCompany: obj})
        })
    }

    renderLinkButton() {
        return(
            <div className="buttons-checkout">
                    <a href="#" className="btn btn-primary btn-medium full" onClick={()=>{this.callCompanyLink()}}>{ getSecureData(Constant.USEROBJECT).isLinkToCompany ? Strings.manager_linked_company : (getSecureData(Constant.USEROBJECT).isRequestedToLink ? Strings.btn_request_pending : Strings.str_link)}</a>
                </div>
        )   
    }

    

    render() {

        // The forwardRef is important!!
        // Dropdown needs access to the DOM node in order to position the Menu
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="form-control"
            >
            {children}
            &#x25bc;
            </a>
        ));
        
        // forwardRef again here!
        // Dropdown needs access to the DOM of the Menu to measure it
        const CustomMenu = React.forwardRef(
            ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            // const [value, setValue] = useState('');
            const value="";
            return (
                <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
                >
                {/* <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => alert(e.target.value)}
                    value={value}
                /> */}
                <ul>
                    {React.Children.toArray(children).filter(
                    (child) =>
                        !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
                </div>
            );
            },
        );

        return (
            <div>
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
                <HeaderWhiteMenu
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/>
                <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={this.state.isMultiButton} btnLeftClick={() => { this.setState({ isAlertVisible: false }) }} btnRightClick={() => { this.onRightClick() }} btnLeftTitle={this.state.btnLeft} btnRightTitle={this.state.btnRight} />
                {this.state.isLinkVisible ? <LinkCompany isShow={this.state.isLinkVisible} addressList={this.state.objDeliveryAddresses} onHideAddressView={(data)=>{this.onCloseAddress(data)}} onAddNewAddress={()=>{this.onAddAddress()}} onDeleteAddress={()=>{this.onDeleteAddress()}}/> : null}
                <div className="content_wrap">
                    <div className="container">
                        <div className="user">

                            <div className="row">
                                <div className="col-md-12  col-lg-6 center">
                                    <h1>{Strings.edit_profile_web}</h1>
                                    <div className="form-group">
                                        <label>{Strings.lbl_name_react}*</label>
                                        <input type="text" className="form-control" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.nameError}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{Strings.lbl_phone_number_react}</label>
                                        <div className="row">
                                            <div className="col-5 col-md-3 pr-0">
                                            <Dropdown onSelect={(e) => this.setState({ countryCode: e })}>
                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                    {this.state.countryCode==0?"Code ":this.state.countryCode} 
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={CustomMenu} style={{height:"200px",overflow:"scroll"}}>
                                                        <Dropdown.Item eventKey="+7">+7</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+30">+30</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+31">+31</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+32">+32</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+33">+33</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+34">+34</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+36">+36</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+39">+39</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+40">+40</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+41">+41</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+43">+43</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+44">+44</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+45">+45</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+46">+46</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+47">+47</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+48">+48</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+49">+49</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+90">+90</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+298">+298</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+350">+350</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+351">+351</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+352">+352</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+353">+353</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+354">+354</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+355">+355</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+356">+356</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+357">+357</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+358">+358</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+359">+359</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+370">+370</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+371">+371</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+372">+372</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+373">+373</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+374">+374</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+375">+375</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+376">+376</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+377">+377</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+378">+378</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+379">+379</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+380">+380</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+381">+381</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+382">+382</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+383">+383</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+385">+385</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+386">+386</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+387">+387</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+389">+389</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+420">+420</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+421">+421</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+423">+423</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+994">+994</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+995">+995</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+3491">+3491</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+91">+91</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {/* <select className="form-control" value={this.state.countryCode} onChange={e => this.setState({ countryCode: e.target.value })}>
                                                    <option value="0">Code</option>
                                                    <option value="+7">+7</option>
                                                    <option value="+30">+30</option>
                                                    <option value="+31">+31</option>
                                                    <option value="+32">+32</option>
                                                    <option value="+33">+33</option>
                                                    <option value="+34">+34</option>
                                                    <option value="+36">+36</option>
                                                    <option value="+39">+39</option>
                                                    <option value="+40">+40</option>
                                                    <option value="+41">+41</option>
                                                    <option value="+43">+43</option>
                                                    <option value="+44">+44</option>
                                                    <option value="+45">+45</option>
                                                    <option value="+46">+46</option>
                                                    <option value="+47">+47</option>
                                                    <option value="+48">+48</option>
                                                    <option value="+49">+49</option>
                                                    <option value="+90">+90</option>
                                                    <option value="+298">+298</option>
                                                    <option value="+350">+350</option>
                                                    <option value="+351">+351</option>
                                                    <option value="+352">+352</option>
                                                    <option value="+353">+353</option>
                                                    <option value="+354">+354</option>
                                                    <option value="+355">+355</option>
                                                    <option value="+356">+356</option>
                                                    <option value="+357">+357</option>
                                                    <option value="+358">+358</option>
                                                    <option value="+359">+359</option>
                                                    <option value="+370">+370</option>
                                                    <option value="+371">+371</option>
                                                    <option value="+372">+372</option>
                                                    <option value="+373">+373</option>
                                                    <option value="+374">+374</option>
                                                    <option value="+375">+375</option>
                                                    <option value="+376">+376</option>
                                                    <option value="+377">+377</option>
                                                    <option value="+378">+378</option>
                                                    <option value="+379">+379</option>
                                                    <option value="+380">+380</option>
                                                    <option value="+381">+381</option>
                                                    <option value="+382">+382</option>
                                                    <option value="+383">+383</option>
                                                    <option value="+385">+385</option>
                                                    <option value="+386">+386</option>
                                                    <option value="+387">+387</option>
                                                    <option value="+389">+389</option>
                                                    <option value="+420">+420</option>
                                                    <option value="+421">+421</option>
                                                    <option value="+423">+423</option>
                                                    <option value="+994">+994</option>
                                                    <option value="+995">+995</option>
                                                    <option value="+3491">+3491</option>
                                                    <option value="+91">+91</option>
                                                </select> */}
                                            </div>
                                            <div className="col-7 col-md-9">
                                                <input type="text" className="form-control" maxLength={11} value={this.state.phoneNumber} onChange={e => this.setState({ phoneNumber: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.countryCodeError}</span>
                                            <span className='errorMsg'>{this.state.errors.phoneNumberError}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{Strings.lbl_email_react}*</label>
                                        <input type="text" className="form-control" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.emailError}</span>
                                        </div>
                                    </div>
                                    <div className="form-group pwd">
                                        <label>{Strings.lbl_password_react_sign_in}</label>
                                        <input type="Password" className="form-control" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.passwordError}</span>
                                        </div>
                                        {/* <a href="#" className="change-password">{Strings.lbl_change_password_react}</a> */}
                                    </div>

                                    <div className="signup">
                                        <button className="btn btn-primary btn-medium" onClick={this.onSaveClick}>{Strings.btn_save_changes_react}</button>
                                        <button className="btn btn-medium trans-btn" onClick={this.onDeleteClick}>{Strings.str_delete_account_web}</button>
                                    </div>
                                    
                                    <div className="row border-Top text-left">
                                        <div className="cmpDiv">
                                            
                                            <div className="form-group">
                                                <label>{Strings.lbl_link_company_react}</label>
                                                <input type="text" maxLength={30} className="form-control" placeholder={Strings.str_enter_company_code} value={this.state.linkedCompanyName} onChange={e => this.onSearch(e.target.value)} />
                                                <div className="errorDiv">
                                                    <span className='errorMsg'>{this.state.errors.errorLinkedCompanyName}</span>
                                                </div>
                                            </div>

                                            {/* <div className="address plate-div"> */}
                                            <div className="cmpList">
                                                <ul>
                                                    {this.state.arrCompanyList.length > 0 ? this.renderCompanyList() : null}
                                                </ul> 
                                            </div>
                                            
                                            {this.renderLinkButton()}
                                        </div>
                                    </div>

                                    

                                    {(this.state.objAvocadoDetails) ? (
                                        <div className="row border-Top">
                                            <div className="avocadoDiv">
                                                <h1>My Avocado</h1>
                                                <div className="imgAvocado"><img src="/images/avocadoes_black.png"/></div>
                                                <h5>Avocado Points : {this.state.objAvocadoDetails.useAvocadoPoint}</h5>
                                            </div>
                                        </div>) : null}
                                    
                                </div>

                                {this.state.arrayCards.length > 0 ? (
                                    <div className="col-md-12  col-lg-6 right">
                                        <h1>{Strings.lbl_credit_card_details_react}</h1>

                                        {this.renderCardView()}
                                        
                                        {this.renderDeliveryAddress()}
                                    </div>
                                ) : null}

                                
                            </div>
                        </div>
                    </div>
                </div>

                <FooterMenu />
            </div>
        )
    }

}

function mapStateToProps(state) {
    console.log('isLoaging MyAccount ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    UpdateCustomerAPI: (locationId,customerId, name, countrycode, phone, email, password, languageType) => dispatch(HGActions.initRegistrationCall(locationId,customerId, name, countrycode, phone, email, password, languageType)),
    removeLinkedAPI: (companyId, customerId, languageId) => dispatch(HGActions.initRemoveLinkedCompanyAPI(companyId, customerId, languageId)),
    DeleteAccountAPI: (customerId, languageType) => dispatch(HGActions.initDeleteUserAccountCall(customerId, languageType)),
    CompanyAccountLinkApi: (customerId, addressId, companyId, languageType) => dispatch(HGActions.CallCompanyAccountLinkApi(customerId, addressId, companyId, languageType)),
    getSavedCards: (customerId) => dispatch(HGActions.initGetSavedCardsAPI(customerId)),
    cardDeleteApi: (mainObj) => dispatch(HGActions.callCardDeleteApi(mainObj)),
    callCheckVersion: (customerId,languageType) => dispatch(HGActions.callApiCheckVersionAPI(customerId,languageType)),
    getDeliveryAddressAPI: (locationId,customerId,languageType) => dispatch(HGActions.initGetDeliveryAddressAPI(locationId,customerId,languageType)),
    getAvocadoDetailsAPI: (languageType) => dispatch(HGActions.initGetAvocadoDetailsAPI(languageType)),
    deleteAddressAPI: (addressId) => dispatch(HGActions.initDeleteDeliveryAddressAPI(addressId)),
    getCompanyAPI: (languageType) => dispatch(HGActions.initGetCompanyListAPI(languageType)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
import LocalizedStrings from 'react-localization';

export let Strings = new LocalizedStrings({
    //English language code and message
    en: {

        yes: 'YES',
        str_no: 'NO',

        msg_erase_item: 'Do you want to erase item?',
        msg_item_erased: 'Item erased successfully',
        str_order_now:'Order Now',
        str_order_later:'Order Later',
        sub_total:'Sub Total',
        str_packaging_costs:'Biodegradable Packaging',
        Checkout_web: 'CHECKOUT',
        str_erase: 'Erase',
        str_add_drink:'Add Drink',
        str_add_more_product:'Add more products',
        time_select:'Select Time',
        change_add_web:'Change Address',
        select_address:'SELECT ADDRESS',
        str_add_new_address:'Add New Address',
        str_add_address:'ADD ADDRESS',
        str_company_discount:'Company discount',
        cart_empty:'No item(s) in your order.',
        btn_save_card: 'Saved Cards',
        empty_cart_message:'By changing your location it will reset your order. Do you want to remove your items and continue with your order at your new chosen location?',
        btn_confirm:'Confirm',
        btn_cancel:'Cancel',
        payment_type:'PAYMENT TYPE',
        pay_by_cash:'PAY BY CASH',
        pay_by_card:'PAY BY CARD',
        past_time:'This time is no longer available, please select another time.',
        select_add_delivery_address:'Please select or add delivery address',
        enter_address:'Please enter address',
        address_comment:'Comment (House no., Street, Landmark)',
        str_loading:'Loading...',
        comments_web:'Comments',

        str_reorder:'Reorder',

        btn_change_location:'Change Location',
        str_locationname: 'Location Name',

        rate_a_waiter:'Rate A Waiter',
        msg_delete_account:'Are you sure you want to delete account?',

        cart_from_different_location:'Can\'t order from this location as your cart already have item(s) from different location. Do you want to remove all the items from the previous location and continue with your order?',

        please_select_location:'Please select location',
        msg_delete_card:'Are you sure you want to delete card?',

        str_your_order_is:'Your order number is',
        str_redeem_avocado_discount:'Redeem #points# avocados for #value# discount',

        card_name_web:'Full name (on the card)',
        ExpiryDate_web:'Expiry Date',
        str_mm:'MM',
        str_yyyy:'YYYY',
        str_cvv:'CVV',
        str_january:'January',
        str_february:'February',
        str_march:'March',
        str_april:'April',
        str_may:'May',
        str_june:'June',
        str_july:'July',
        str_august:'August',
        str_september:'September',
        str_october:'October',
        str_november:'November',
        str_december:'December',

        str_new_card: 'New Card',
        str_card_name_error:'Please enter card name',
        entercardnumber_web:'Please enter card number',
        Validcreditcardnumber_web:'Please enter valid card number',
        selectexpirymonth_web:'Please select expiry month',
        expiryyear_web:'Please select expiry year',
        str_card_cvv_error:'Please enter card cvv number',

        error_no_data : 'No data found! Please refresh browser or come back again',

        delete_delivery_address:'Are you sure you want to delete delivery address?',
        delete_address:'Address deleted successfully',
        no_record_address:'No Address Available',
        str_redeemed_avocados:'Redeemed Avocados',
        remove_cart_item_message:'Do you want to change your current order to #, then your cart items will be removed.',

        //Waiter listing page
        lbl_waiter_title_react: 'Waiter Details',
        lbl_no_waiter_react: 'No Record Found',

        //Waiter details page
        lbl_comment_react:'Comment',
        str_post_comment_web:'Send Evaluation',
        select_rating_react:'Please select rating',

        // Sign in page
        btn_signin_react: 'Sign in',
        btn_signup_react: 'Sign up',
        lbl_phone_number_react: 'Phone Number:',
        lbl_password_react_sign_in: 'Password:',
        lbl_no_account_react: 'Don’t have an account?',
        btn_google_plus_react: 'Sign in with Google',
        btn_facebook_react: 'Sign in with Facebook',

        //Forget password dialog
        lbl_forget_password_react: 'Forgot your password?',
        lbl_forget_phone_number_react: 'Phone Number',
        Submit_web: 'Submit',

        //Validation error message in sign in page
        error_msg_country_code_react: 'Please select country code!',
        error_msg_phone_no_react: 'Phone number must be 10 digit long!',
        error_msg_password_react: 'Please enter password!',

        //Sign up page
        lbl_name_react: 'Name:',
        lbl_email_react: 'E-mail:',
        lbl_or_sign_up_react: 'OR SIGN UP WITH',

        //Validation error message in sign up page
        error_msg_name_react: 'Please enter name!',
        error_msg_email_react: 'Please enter email address!',
        error_msg_valid_email_react: 'Please enter valid email address!',

        txtFeedback: 'Feedback',

        // Phone verification page
        lbl_verification_code_react: 'Mobile Verification Code:*',
        btn_verify: 'Verify',
        btn_resend: 'Resend',
        btn_edit: 'Edit',

        //My account page
        edit_profile_web: 'Edit Profile',
        lbl_link_company_react: 'Link to Company:',
        btn_save_changes_react: 'save changes',
        str_delete_account_web: 'Delete Account',
        lbl_change_password_react: 'Change password?',
        btn_edit_details_react: 'Edit details',
        btn_delete_card_react: 'delete card',

        //Validation message in my account page
        error_msg_company_account_react: 'Please enter company account!',

        // Category listing page
        lbl_select_location_react: 'Select location',
        btn_continue_react: 'Continue',
        lbl_select_order_type_react: 'Select Order Type',
        Delivery_web: 'Delivery',
        lbl_takeaway_react: 'TakeAway',
        error_msg_no_order_type_react: 'Order types not available, please reselect location',
        lbl_choose_react: 'CHOOSE',
        lbl_a_product_react: 'A PRODUCT',


        //Menu listing page
        lbl_edit_add_react: 'Edit/Add',
        lbl_quantity_react: 'Quantity',
        str_btn_update_react: 'Update',
        btn_add_to_cart_react: 'Add to cart',
        Total_web: 'Total',
        error_msg_item_added_react: 'Item added successfully',
        error_msg_item_update_react: 'Item updated successfully',
        Ok_web: 'OK',


        // Footer 
        lbl_part_group_react: 'part of the group',
        lbl_more_info_react: 'more info',
        lbl_newslatter_react: 'Join our newsletter',
        lbl_subscribe_react: 'SUBSCRIBE',
        lbl_stay_connected_react: 'stay connected',
        lbl_contact_us_react: 'Contact Us',
        lbl_career_react: 'Careers',
        lbl_privacy_policy_react: 'Privacy Policy',
        lbl_honest_react:'hello@honestgreens.com',
        lbl_honest_green_footer_react:'honest greens © 2020',
        lbl_all_right_react:'all rights reserved',
        lbl_apply_office_catering:'Apply for office catering',



        //My order listing page
        lbl_view_order_react: 'VIEW ALL ORDERS',
        lbl_order_react: 'ORDER #',
        lbl_date_react: 'DATE',
        lbl_location_react: 'LOCATION',
        lbl_order_type_react: 'ORDER TYPE',
        lbl_price_react: 'PRICE',
        support_web: 'Support',
        btn_view_order_react: 'View Order',
        lbl_no_order_react: 'No order found',
        lbl_credit_card_details_react: 'CREDIT CARD DETAILS',
        lbl_credit_card_name_react: 'Credit Card Name',
        lbl_details_react: 'Details',
        DeliveryAddress_web: 'Delivery Address',

        //My order listing page header menu(like Edit account,Logout)
        lbl_title_product_react: 'products',
        lbl_title_cart_react: 'Cart',
        lbl_my_account_react: 'My Account',
        EditAccount_Web: 'Edit Account',
        lbl_title_my_order_react: 'my orders',
        lbl_title_logout_react: 'Logout',

        //Order details page
        sub_total_order_amount_web: 'Sub Total Order Amount',
        lbl_delivery_charge_react: 'Delivery Charge',
        total_order_amount_web: 'Total Order Amount',
        lbl_discount_react: 'Discount',
        packaging_charge_web: 'Biodegradable Packaging',

        // User Feedback Page
        lbl_user_feedback_react:'Feedback',
        error_msg_feedback_react:'Please enter your feedback',

        //Get card listing page
        CardNumber_web:'CARD NUMBER',
        card_brand_web:'CARD BRAND',
        card_type_web:'CARD TYPE',
        card_cat_web:'CARD CATEGORY',
        view_card_details_web:'View Card Details',
        delete_card_details_web:'Delete Card Details',
        error_msg_no_card_react:'No card details found',
        card_details_web:'Card Details',

        //Get card details page
        Save_web:'Save',
        msg_card_type_category_react:'Please enter card category',


        msg_cancel_order:'Are you sure you want to cancel order?',

        btn_apply:'Apply',
        str_remove:'Remove',

        str_voucher_title: 'Apply Voucher Code',
        str_voucher_discount: 'Voucher Discount',
        hint_voucher_code:'Enter voucher code',
        errorMsgVoucherCode: 'Please enter valid voucher code',
        errorMsgVoucherCodeApply:'Your payble amount is greater than zero for apply voucher code',
        clear:'Clear',

        reset_pass_web:'Reset Password',
        new_password_web:'New Password',
        str_old_password:'Old Password',
        enter_new_pass_web:'Please enter new password',
        msg_error_old_password:'Please enter old password',
        confPassword_web:'Confirm password',
        enter_confirm_pass_web:'Please enter confirm password',
        confPassNotMatch_web:'Confirm password does not match',

        str_company_registration:'Company Registration',
        lable_compnayname_web:'Company Name',
        str_address:'Address',
        str_msg_enter_company:'Please enter company name',
        error_msg_address:'Please enter address',

        str_link:'Link',
        str_enter_company_code:'Search company here',
        manager_linked_company:'Manage linked company',
        btn_request_pending:'Request Pending',
        str_msg_next_day_order_confirm:'You are placing order after company’s cut off time, it will be considered as your next day order. Would you like to place the order?',
        company_link_delete_web:'Are you sure you want to remove company link?',
    },

    //Spanish language code and message
    es: {

        yes: 'Si',
        str_no: 'NO',

        // Sign in page
        btn_signin_react: 'registrarse',
        btn_signup_react: 'Regístrate',
        lbl_phone_number_react: 'Número de teléfono:',
        lbl_password_react_sign_in: 'Contraseña:',
        lbl_no_account_react: '¿No tienes una cuenta?',
        btn_google_plus_react: 'Inicia sesión con Google',
        btn_facebook_react: 'Iniciar sesión usando Facebook ',


        //Forget password dialog
        lbl_forget_password_react: '¿Olvidaste tu contraseña?',
        lbl_forget_phone_number_react: 'Número de teléfono',
        Submit_web: 'Enviar',


        //Validation error message in sign up page
        error_msg_name_react: 'Por favor ingrese el nombre!',
        error_msg_country_code_react: 'Por favor, seleccione el código del país!',
        error_msg_phone_no_react: '¡El número de teléfono debe tener 10 dígitos!',
        error_msg_password_react: '¡Por favor, ingrese contraseña!',
        error_msg_email_react: 'Por favor, introduzca la dirección de correo electrónico!',
        error_msg_valid_email_react: '¡Por favor ingrese una dirección de correo electrónico válida!',

        //Sign up page label 
        lbl_name_react: 'Nombre:',
        lbl_email_react: 'Correo electrónico:',
        lbl_or_sign_up_react: 'O REGÍSTRATE CON',


        txtFeedback: 'Realimentación',

        // Phone verification page
        lbl_verification_code_react: 'Código de verificación móvil: *',
        btn_verify: 'Verificar',
        btn_resend: 'Resand',
        btn_edit: 'Editar',

        //My account page
        edit_profile_web: 'Editar Perfil',
        lbl_link_company_react: 'Enlace a la empresa:',
        btn_save_changes_react: 'guardar cambios',
        str_delete_account_web: 'Borrar Cuenta',
        lbl_change_password_react: '¿Cambia la contraseña?',
        btn_edit_details_react: 'Editar detalles',
        btn_delete_card_react: 'eliminar tarjeta',
        lbl_credit_card_details_react: 'DETALLES DE LA TARJETA DE CRÉDITO',
        lbl_credit_card_name_react: 'Nombre de tarjeta de crédito',
        lbl_details_react: 'Detalles',
        DeliveryAddress_web: 'Dirección de entrega',

        //Validation message in my account page
        error_msg_company_account_react: 'Por favor ingrese la cuenta de la empresa!',

        // Category listing page
        lbl_select_location_react: 'Seleccionar ubicación',
        btn_continue_react: 'Continuar',
        lbl_select_order_type_react: 'Seleccionar tipo de orden',
        Delivery_web: 'Entrega',
        lbl_takeaway_react: 'Para llevar',
        error_msg_no_order_type_react: 'Tipos de pedido no disponibles, vuelva a seleccionar la ubicación',
        lbl_choose_react: 'ESCOGER',

        //Menu listing page
        lbl_edit_add_react: 'Editar / Agregar',
        lbl_quantity_react: 'Cantidad',
        str_btn_update_react: 'Actualizar',
        btn_add_to_cart_react: 'Añadir al carrito',
        Total_web: 'Total',
        error_msg_item_added_react: 'Artículo agregado exitosamente',
        error_msg_item_update_react: 'Artículo actualizado con éxito',
        Ok_web: 'De acuerdo',
        lbl_a_product_react: 'UN PRODUCTO',

        //Footer
        lbl_part_group_react: 'parte del grupo',
        lbl_more_info_react: 'más información',
        lbl_newslatter_react: 'Suscríbase a nuestro boletín',
        lbl_subscribe_react: 'SUSCRIBIR',
        lbl_stay_connected_react: 'Mantente conectada',
        lbl_contact_us_react: 'Contáctenos',
        lbl_career_react: 'Carreras',
        lbl_privacy_policy_react: 'Política de privacidad',
        lbl_honest_react:'hola@honestgreens.com',
        lbl_honest_green_footer_react:'verdes honestos © 2020',
        lbl_all_right_react:'todos los derechos reservados',
        lbl_apply_office_catering:'Solicitar catering en la oficina',


        //My order listing page
        lbl_view_order_react: 'VER TODOS LOS PEDIDOS',
        lbl_order_react: 'ORDEN #',
        lbl_date_react: 'CON FECHA DE',
        lbl_location_react: 'ALQUILER',
        lbl_order_type_react: 'TIPO DE ORDEN',
        lbl_price_react: 'PRECIO',
        support_web: 'Apoyo',
        btn_view_order_react: 'Ver pedido',
        lbl_no_order_react: 'No se ha encontrado ningún pedido.',

        //My order listing page header menu(like Edit account,Logout)
        lbl_title_product_react: 'productos',
        lbl_title_cart_react: 'Carro',
        lbl_my_account_react: 'Mi Cuenta',
        EditAccount_Web: 'Editar cuenta',
        lbl_title_my_order_react: 'Mis ordenes',
        lbl_title_logout_react: 'Cerrar sesión',

        //Order details page
        sub_total_order_amount_web: 'Sub Cantidad total de la orden',
        lbl_delivery_charge_react: 'Gastos de envío',
        total_order_amount_web: 'Importe total del pedido',
        lbl_discount_react: 'Descuento',
        packaging_charge_web: 'Empaque Biodegradable',

        lbl_user_feedback_react:'Realimentación',
        error_msg_feedback_react:'Playas Ingrese su opinión',

        //Get card listing page
        CardNumber_web:'NÚMERO DE TARJETA',
        card_brand_web:'MARCA DE CARRO',
        card_type_web:'TIPO DE TARJETA',
        card_cat_web:'CATEGORÍA DE TARJETA',
        view_card_details_web:'Ver detalles de la tarjeta',
        delete_card_details_web:'Eliminar detalles de la tarjeta',
        error_msg_no_card_react:'No se encontraron detalles de la tarjeta',
        card_details_web:'Detalles de tarjeta',

        //Get card details page
        Save_web:'Salvar',
        msg_card_type_category_react:'Por favor ingrese la categoría de la tarjeta',

        msg_erase_item: ' ¿Quieres borrar el elemento?',
        msg_item_erased: 'Artículo borrado con éxito',
        str_order_now: 'Ordenar ahora',
        str_order_later:'Ordene despues',
        sub_total:'Sub Total',
        str_packaging_costs:'Paquete biodegradable',
        Checkout_web:'Revisa',
        str_erase:'borrar',
        str_add_drink:'Añadir bebida',
        str_add_more_product:'Agregar más productos',
        time_select:'Seleccione tiempo',
        change_add_web:'Cambiar dirección',
        select_address:'SELECCIONE DIRECCION',
        str_add_new_address:'Agregar nueva dirección',
        str_add_address:'AÑADIR DIRECCIÓN',
        str_company_discount:'Descuento de la empresa',
        cart_empty:'No hay artículos en su pedido.',

        btn_save_card: 'Tarjetas guardadas',
        empty_cart_message:'Al cambiar su ubicación, restablecerá su pedido. ¿Desea eliminar sus artículos y continuar con su pedido en la nueva ubicación elegida?',
        btn_confirm:'Confirmar',
        btn_cancel:'Cancelar',
        payment_type:'TIPO DE PAGO',
        pay_by_cash:'PAGAR EN EFECTIVO',
        pay_by_card:'PAGAR CON TARJETA',
        past_time:'Esta hora ya no está disponible, seleccione otra hora.',
        select_add_delivery_address:'Seleccione o agregue la dirección de entrega.',
        enter_address:'Por favor ingrese la dirección',
        address_comment:'Comentario (Casa no., Calle, Punto de referencia)',
        str_loading:'Cargando...',
        comments_web:'Comentarios',

        //Waiter listing page
        lbl_no_waiter_react: 'ningún record fue encontrado',
        lbl_waiter_title_react: 'Detalles del camarero',

        //Waiter details page
        lbl_comment_react:'Comentario',
        str_post_comment_web:'Evacuación de arena',
        select_rating_react:'Por favor seleccione la calificación',

        str_reorder:'Reordenar',
        rate_a_waiter:'Califica a un camarero',

        cart_from_different_location:'No se puede realizar el pedido desde esta ubicación, ya que su carrito ya tiene artículos de una ubicación diferente. ¿Desea eliminar todos los artículos de la ubicación anterior y continuar con su pedido?',
        msg_delete_account:'¿Estás segura de que quieres eliminar la cuenta?',
        please_select_location:'Por favor seleccione ubicación',
        msg_delete_card:'¿Estás seguro de que quieres eliminar la tarjeta?',

        card_name_web:'Nombre completo (en la tarjeta)',
        ExpiryDate_web:'Fecha de caducidad',
        str_mm:'MM',
        str_yyyy:'AAAA',
        str_cvv:'CVV',
        str_january:'enero',
        str_february:'febrero',
        str_march:'marzo',
        str_april:'abril',
        str_may:'Mayo',
        str_june:'junio',
        str_july:'julio',
        str_august:'agosto',
        str_september:'septiembre',
        str_october:'octubre',
        str_november:'noviembre',
        str_december:'diciembre',
        str_new_card: 'Nueva tarjeta',

        str_card_name_error:'Por favor ingrese el nombre de la tarjeta',
        entercardnumber_web:'Por favor ingrese el número de tarjeta',
        Validcreditcardnumber_web:'Por favor ingrese un número de tarjeta válido',
        selectexpirymonth_web:'Seleccione mes de vencimiento',
        expiryyear_web:'Seleccione año de vencimiento',
        str_card_cvv_error:'Por favor ingrese el número de tarjeta cvv',

        error_no_data : '¡Datos no encontrados! actualiza el navegador o vuelve de nuevo',
        delete_delivery_address:'¿Estás seguro de que deseas eliminar la dirección de entrega?',
        delete_address:'Dirección eliminada correctamente',
        no_record_address:'No hay dirección disponible',
        str_your_order_is:'tu numero de orden es',
        str_redeem_avocado_discount:'Canjee #points# aguacates por #value# descuento',
        str_redeemed_avocados:'Aguacates Redimidos',
        remove_cart_item_message:'¿Desea cambiar su pedido actual a #? Luego, se eliminarán los artículos de su carrito.',

        msg_cancel_order:'¿Estás segura de que deseas cancelar el pedido?',

        btn_apply:'Aplicar',
        str_remove:'Eliminar',

        btn_change_location: 'Cambiar Ubicación',

        str_locationname:'Nombre del lugar',

        str_voucher_title: 'Aplicar código de cupón',
        str_voucher_discount: 'Cupón de descuento',
        hint_voucher_code:'Ingrese código de descuento',
        errorMsgVoucherCode: 'Ingrese un código de cupón válido',
        errorMsgVoucherCodeApply:'Su monto a pagar es mayor que cero para aplicar el código de cupón',
        clear:'Clara',

        reset_pass_web:'Restablecer La Contraseña',
        new_password_web:'Nueva Contraseña',
        str_old_password:'Contraseña anterior',
        enter_new_pass_web:'Por favor ingrese una nueva contraseña',
        msg_error_old_password:'Ingrese la contraseña anterior',
        confPassword_web:'Confirmar contraseña',
        enter_confirm_pass_web:'Por favor, introduzca la contraseña de confirmación',
        confPassNotMatch_web:'Confirme que la contraseña no coincida',

        str_company_registration:'Registro de la empresa',
        lable_compnayname_web:'Nombre de la compañia',
        str_address:'Habla a',
        str_msg_enter_company:'Ingrese el nombre de la empresa',
        error_msg_address:'Por favor ingrese la dirección',

        str_link:'Enlazar',
        str_enter_company_code:'Buscar empresa aquí',
        manager_linked_company:'Gestionar empresa vinculada',
        btn_request_pending:'Pedido pendiente',
        str_msg_next_day_order_confirm:'Si realiza el pedido después de la hora límite de la empresa, se considerará como su pedido del día siguiente. ¿Le gustaría realizar el pedido?',
        company_link_delete_web:'¿Está seguro de que desea eliminar el enlace de la empresa',
    }
});
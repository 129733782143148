import React, { Component } from "react";

import {withRouter} from 'react-router-dom';

import Constants from '../constants/baseConstants';
import Modal from 'react-bootstrap/Modal'


class AlertDialog extends Component {
  
  constructor(props) {
    super(props);


  }

  render() {
    return (
      
    <Modal
        show={this.props.isAlertVisible}
        // onHide={()=>{this.props.btnLeftClick()}}
        size="sm"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
      <Modal.Body>
        <div className="item-div">
            <p>{this.props.msgTitle}</p>
            <div className="alertButtonDiv">
              <a className={this.props.isMultibutton ? "btn btn-primary btn-small poupButton" : "btn btn-primary btn-small poupButton fullWidth"} onClick={()=>{this.props.btnLeftClick()}} tabIndex={1}>{this.props.btnLeftTitle}</a>
              {this.props.isMultibutton ? <a className="btn btn-primary btn-small poupButton" onClick={()=>{this.props.btnRightClick()}} tabIndex={2}>{this.props.btnRightTitle}</a> : null }
            </div>
        </div>
      </Modal.Body>
    </Modal>
    )
  }

}

export default AlertDialog;
import React, { Component } from "react";

import {withRouter} from 'react-router-dom';

import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';

import Constants from '../constants/baseConstants';
import {Strings} from '../constants/Localization';
import Modal from 'react-bootstrap/Modal'

import {setSecureData, getSecureData} from '../Utils/utils';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';


class AddAddressPopup extends Component {
  
  constructor(props) {
    super(props);

    this.state = { 
      address: '',
      txtComment:'',
      lat:0.0,
      long:0.0, 
      languageType:1,
    };
  }

  componentDidMount() {
    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
    let session = getSecureData(Constants.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
    let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
        // session = JSON.parse(session);
        if (mLanguageType != null && mLanguageType == 1) {
            Strings.setLanguage('en'); //Setting selected language code here
            this.setState({ languageType: mLanguageType })
        } else {
            if (mLanguageType != null) {    
                Strings.setLanguage('es'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
        }
    }
    else {
        Strings.setLanguage('en');//Setting default language code here
    }
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
    // .then(results => console.log(results))
    // .catch(error => console.error(error));
      .then(results => getLatLng(results[0]))
      .then((latLng) => {console.log('Success', latLng,address); this.setState({ address:address,lat:latLng.lat,long:latLng.lng }) })
      .catch((error) => {console.error('Error', error)})
  };
  
  onAddAddress() {

    // let locationId = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId
    let locationId = getSecureData(Constants.LOCATIONOBJECT).locationId
    var objAddress = {}
    objAddress['address1'] = this.state.address
    objAddress['address2'] = this.state.txtComment
    objAddress['geoCodeAdress'] = this.state.address
    objAddress['landmark'] = ''
    objAddress['city'] = ''
    objAddress['zipcode'] = ''
    objAddress['latitude'] = this.state.lat
    objAddress['longitude'] = this.state.long
    objAddress['iscompanyAddress'] = false
    // objAddress['customerId'] = JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId
    objAddress['customerId'] = getSecureData(Constants.USEROBJECT).customerId

    this.props.addDeliveryAddressAPI(locationId,objAddress,this.state.languageType).then(() => {
      if (this.props.data.status == 1) {
          setTimeout(() => {
              console.log('add delivery address response ----- ',this.props.data.result)
              this.props.onHideAddAddress(this.props.data.result)
          }, 500)
      } else {
        console.log('add delivery address status else ----- ',this.props.data.result)
        alert(this.props.data.message)
      }
  })
  }

  render() {
    return (
        
    <Modal
        show={this.props.isShow}
        onHide={()=>{this.props.onHideAddAddress()}}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
      <Modal.Body>
          <div className="location-popup">                   
               
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
            >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="location-popup">
                {/* <h5>Your Location</h5> */}
                  <div className="Loc">
                      <div className="form-group loc-input">
                        
                        <input type="text" className="form-control" value="Ecter Location"
                          {...getInputProps({
                            placeholder: Strings.enter_address,
                            className: 'form-control',
                          })}
                        />
                      </div>
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>{Strings.str_loading}</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer', margin: '0px 5px', borderBottom: 'solid 1px',marginBottom:'10px' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer', margin: '0px 5px', borderBottom: 'solid 1px',marginBottom:'10px' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                      <div className="form-group coment">
                        <input type="text" className="form-control" placeholder={Strings.address_comment} value={this.state.txtComment} onChange={(e)=>this.setState({txtComment: e.target.value})}/>
                      </div>
                      <div className="price-btns">
                        <a href="#" className="btn btn-primary btn-small" onClick={()=>{this.onAddAddress()}}>{Strings.str_add_address}</a>
                      </div>
                  </div>
                {/* <input
                  {...getInputProps({
                    placeholder: 'Enter address',
                    className: 'location-search-input',
                  })}
                /> */}
                
              </div>
            )}
          </PlacesAutocomplete>
            {/* <input type='text' placeholder='Comment' value={this.state.txtComment} onChange={(e)=>this.setState({txtComment: e.target.value})}/>
            <button onClick={()=>{this.onAddAddress()}}>Add Address</button> */}
        </div>
      </Modal.Body>
    </Modal>
    )
  }

}

function mapStateToProps(state) {
    console.log('isLoaging add Address Popup ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    addDeliveryAddressAPI: (locationId,DeliveryAddressInfo,languageType) => dispatch(HGActions.initAddDeliveryAddressAPI(locationId,DeliveryAddressInfo,languageType))
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddAddressPopup);
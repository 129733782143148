import React, {Component} from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";

import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import FooterMenu from '../Components/FooterMenu';

import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';

import moment from 'moment';

import ItemPopup from '../Components/ItemPopup';
import AlertDialog from '../Components/AlertDialog';
import AddressPopup from '../Components/AddressPopup';
import AddAddressPopup from '../Components/AddAddressPopup';
import PaymentMethod from '../Components/PaymentMethod';

import Constants from '../constants/baseConstants';
import {Strings} from '../constants/Localization';
import {getPriceFormat,bindhttps} from "../Utils/utils";
import {setSecureData, getSecureData} from '../Utils/utils';
import { Dropdown } from 'react-bootstrap';

import AOS from 'aos';
import 'aos/dist/aos.css';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
    href=""
    ref={ref}
    onClick={(e) => {
        e.preventDefault();
        onClick(e);
    }}
    className="form-control"
    >
    {children}
    &#x25bc;
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    // const [value, setValue] = useState('');
    const value="";
    return (
        <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
        >
        {/* <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => alert(e.target.value)}
            value={value}
        /> */}
        <ul>
            {React.Children.toArray(children).filter(
            (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
        </ul>
        </div>
    );
    },
);

class CheckoutDelivery extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isItemPopupVisible: false,
            
            arrayCartData: [],
            editItem: {},
            editAt: 0,

            eraseItem: {},

            isAddressVisible: false,
            isAddAddressVisible: false,

            isAlertVisible: false,
            isMultiButtom: false,
            alertMessage: '',
            btnLeft:'',
            btnRight:'',
            alertType: 0,

            objCompanyCredit: {},
            objCompanyAddress: {},
            isCompanyChecked:false,

            objDeliveryAddresses: {},
            selectedAddress: {},
            orderTimeType: 1,
            uktime: '',

            isPaymentMethodVisible: false,
            isPayPal: false,
            paymentMode: 0,

            customTime: '00:00',

            objCheckInfo: {},
            objOffers: {},
            languageType: 1,

            isAvocadoChecked: false,
            objAvocadoDetails: {},

            isPayByCash: false,
            isPayByCard: false,

            isVoucherApplied : false,
            strVoucher: '',
            objVoucher: {},

            objDeliveryCharge: {},
            deliveryChargeResult: null,
            oType: 0,
        }

    }

    componentDidMount() {
        
        
    }

    componentWillMount() {
        
        AOS.init({
            duration : 1000
        })

        if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
            // let userObject = localStorage.getItem(Constants.USEROBJECT);
            let userObject = getSecureData(Constants.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
        }
        else {
            this.props.history.push({ pathname: '/' })
            return
        }


        console.log('isCancel order --------------- ',this.props.location.isCancelOrder)
        // if (this.props.location.isCancelOrder) {
        if (getSecureData(Constants.ISCANCEL)) {
            let oId = getSecureData(Constants.PLACEDORDEROBJECT).orderId

            // console.log('getSecureData order object ------------------- ',getSecureData(Constants.PLACEDORDEROBJECT))
            
            var str = {
                "orderId": oId,
                "paymentstatus": "fail",
                "transactionID": "",
                "transactionfrom": 1,
                "response": "Manually Cancelled",
            }
            this.props.callCancelAPI(str,1).then(() => {
                if (this.props.data.status == 1) {
                    if (this.props.data.result != null) {
                        // this.setState({alertType:1, isAlertVisible: true, alertMessage:this.props.data.message, isMultiButton: false, btnLeft:Strings.Ok_web})
                        this.setState({alertType:0, isAlertVisible: true, alertMessage:this.props.data.message, isMultiButtom: false, btnLeft:Strings.Ok_web})
                        console.log('callCancelAPI status if---- ---- ',this.props.data.message)
                        // setTimeout(() => {
                            localStorage.removeItem(Constants.PLACEDORDEROBJECT)
                            localStorage.removeItem(Constants.ISCANCEL)
                            // window.location.reload();
                        // }, 200);
                    }
                } else {
                    alert(this.props.data.message)
                    // this.setState({alertType:1, isAlertVisible: true, alertMessage:this.props.data.message, isMultiButton: false, btnLeft:Strings.Ok_web})
                    this.setState({alertType:0, isAlertVisible: true, alertMessage:this.props.data.message, isMultiButtom: false, btnLeft:Strings.Ok_web})
                    console.log('callCancelAPI status 2 ---- ',this.props.data)
                }
            })
            .catch((error) => {

            })
        }

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constants.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        // this.setState({arrayCartData: JSON.parse(localStorage.getItem(Constants.USERCART)), objCheckInfo: JSON.parse(localStorage.getItem(Constants.CHECKINFO)) })
        this.setState({arrayCartData: getSecureData(Constants.USERCART), objCheckInfo: getSecureData(Constants.CHECKINFO)},()=> {
            
            console.log('getSecureData(Constants.CHECKINFO) ----------------------- ',this.state.arrayCartData,getSecureData(Constants.CHECKINFO))
            if (!this.state.arrayCartData) {
                return
            }
            this.props.getRestoInfo(this.state.languageType).then(() => {
                console.log('resto info api response -------------------- ',this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({isPayByCash: this.props.data.result.payByCash, isPayByCard: this.props.data.result.payByCard},()=>{
                        if (getSecureData(Constants.ORDERTYPE) == 'TakeAway') {
                            // this.onOffers()
                            let customer = getSecureData(Constants.USEROBJECT).customerId
                            let id = getSecureData(Constants.LOCATIONOBJECT).locationId
                            this.callGetDeliveryAddress(id,customer)
                        } else {
                            this.callCompanyAPI()
                        }                        
                    }) 
                } else {
                    if (getSecureData(Constants.ORDERTYPE) == 'TakeAway') {
                        // this.onOffers()
                        let customer = getSecureData(Constants.USEROBJECT).customerId
                        let id = getSecureData(Constants.LOCATIONOBJECT).locationId
                        this.callGetDeliveryAddress(id,customer)
                    } else {
                        this.callCompanyAPI()
                    }
                }
              })
              .catch(()=>{
                if (getSecureData(Constants.ORDERTYPE) == 'TakeAway') {
                    // this.onOffers()
                    let customer = getSecureData(Constants.USEROBJECT).customerId
                    let id = getSecureData(Constants.LOCATIONOBJECT).locationId
                    this.callGetDeliveryAddress(id,customer)
                } else {
                    this.callCompanyAPI()
                }
              })
        })

        
        
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("category page language type :" + type);
        // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constants.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constants.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constants.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constants.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constants.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constants.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);

                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    callCompanyAPI() {
        setTimeout(() => {
            
            if(this.state.arrayCartData == null) {
                return
            } else if(this.state.arrayCartData.length == 0) {
                return
            }
            let itemSum = 0
            this.state.arrayCartData.map(item => {
                itemSum += item.totalPrice
            })
            
            // let customer = JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId
            // let id = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId
            let customer = getSecureData(Constants.USEROBJECT).customerId
            let id = getSecureData(Constants.LOCATIONOBJECT).locationId

            this.props.getCompanyCreditAPI(customer,this.state.languageType).then((response) => {
                console.log('new response of company credit api ------------- ')
                if (this.props.data.status == 1) {
                    setTimeout(() => {


                        console.log(' Credit APi response object ----- ',this.props.data.result)
                        
                        //Check company is linked or not, if linked then call add company address api 
                        if (this.props.data.result.objScoreDetails.isLinked) {
                            
                            this.setState({objCompanyCredit: this.props.data.result,isCompanyChecked: true})
                            var addressObj = {}
                            addressObj['address1'] = this.props.data.result.geoCodeAdress
                            addressObj['address2'] = ''
                            addressObj['geoCodeAdress'] = this.props.data.result.geoCodeAdress
                            addressObj['landmark'] = ''
                            addressObj['city'] = ''
                            addressObj['zipcode'] = ''
                            addressObj['latitude'] = this.props.data.result.latitude
                            addressObj['longitude'] = this.props.data.result.longitude
                            addressObj['iscompanyAddress'] = true
                            addressObj['labaltext'] = this.props.data.result.labaltext
                            addressObj['customerId'] = customer
                            console.log('addressObj ----- ',addressObj)
                            
                            
                            //Call add company address api 
                            this.props.addCompnayAddressAPI(id,addressObj,this.state.languageType).then(() => {
                                if (this.props.data.status == 1) {
                                    // setTimeout(() => {
                                        // this.setState({objCompanyAddress: this.props.data.result,selectedAddress: this.props.data.result})
                                        this.setState({objCompanyAddress: this.props.data.result},()=>{
                                            this.callGetDeliveryAddress(id,customer)
                                        })
                                    // }, 100)
                                } else {
                                    this.callGetDeliveryAddress(id,customer)
                                }
                                
                            })

                        } else {
                            
                            this.setState({objCompanyCredit: this.props.data.result,isCompanyChecked: false},()=>{
                                this.callGetDeliveryAddress(id,customer)
                            })
                        }
                        
                    }, 100)
                } else {
                    this.callGetDeliveryAddress(id,customer)
                }
              })
              .catch((err) => {
                this.callGetDeliveryAddress(id,customer)
              })

        }, 100);
    }

    callGetDeliveryAddress(id,customer) {
        //Call get delivery addresses api 
        this.props.getDeliveryAddressAPI(id,customer,this.state.languageType).then(() => {
            if (this.props.data.status == 1) {
                // setTimeout(() => {
                    let uktime = moment(this.props.data.result.ukdatetime, "YYYY-MM-dd HH:mm:ss").format("HH:mm")
                    uktime =  moment(uktime, "HH:mm").add(15,'m').format("HH:mm");
                    
                    if(this.props.data.result.AddressLists) {
                        if (this.props.data.result.AddressLists.length > 0) {
                            // this.setState({uktime: uktime, customTime: uktime ,objDeliveryAddresses: this.props.data.result,selectedAddress: this.props.data.result.AddressLists[0]})
                            this.setState({uktime: uktime, customTime: uktime ,objDeliveryAddresses: this.props.data.result})//removed pre selected delivery address
                        } else {
                            this.setState({uktime: uktime, customTime: uktime ,objDeliveryAddresses: this.props.data.result, selectedAddress: {}})
                        }
                    } else {
                        this.setState({uktime: uktime, customTime: uktime ,objDeliveryAddresses: this.props.data.result, selectedAddress: {}})
                    }
                    
                // }, 500)
            } else {

            }

            setTimeout(() => {
                this.onOffers();
            }, 500);
        })
    }

    onOffers() {
        let itemSum = 0
                this.state.arrayCartData.map(item => {
                    itemSum += item.totalPrice
                })
                // let customer = JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId
                let customer = getSecureData(Constants.USEROBJECT).customerId
                this.props.getOffersAPI(itemSum,customer,this.state.languageType).then(() => {
                    console.log("getOffersAPI RESPONSE --------- ",this.props.data)
                    if (this.props.data.status == 1) {
                        if (this.props.data.result != null) {
                            //Offers available
                            this.setState({objOffers: this.props.data.result})
                        }
                    } else if (this.props.data.status == 2) {
                        if (this.props.data.result != null) {
                            //No Offers available
                            this.setState({objOffers: this.props.data.result})
                        }
                    } else {

                    }


                    //Call get avocado details API 
                    this.props.getAvocadoDetailsAPI(this.state.languageType).then(() => {
                        if (this.props.data.status == 1) {
                            this.setState({objAvocadoDetails: this.props.data.result})
                        }

                        setTimeout(() => {
                            if(this.state.isCompanyChecked) {
                                this.callDeliveryCharge()
                            }
                        }, 500);
                    })
                })
    }

    calculateFinalPrice() {

        var finalTotal = 0

        let itemTotal = 0
        this.state.arrayCartData.map(item => {
            itemTotal += item.totalPrice
        })
        finalTotal = itemTotal

        var discount = 0
        var discountValue = ''
        if (this.state.objOffers) {
            //Check offers available or not
            if (this.state.objOffers.isfirstOrder == 1) {
                //Check discount type 1=Percentage 2=Amount
                if (this.state.objOffers.firstOrder.discountType == 1) {
                    let str = this.state.objOffers.firstOrder.discountPercentage
                    let arrStr = str.split('.')
                    if (arrStr[1] == 0) {   
                        discountValue = '('+arrStr[0]+'%)'
                    } else {
                        discountValue = '('+str+'%)'
                    }
                    let temp = finalTotal * Number(str) / 100
                    discount = temp
                    finalTotal -= temp//Number(this.state.objOffers.firstOrder.discount)
                } else {
                    discount = Number(this.state.objOffers.firstOrder.discount)
                    finalTotal -= Number(this.state.objOffers.firstOrder.discount)
                }
            }
        }

        // let objLocation = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT))
        let objLocation = getSecureData(Constants.LOCATIONOBJECT)
        
        //Count Company discount
        var cmpDiscount = 0
        let obj = this.state.objCompanyCredit
        let compy = obj.objScoreDetails
        if (compy) {
            // if (compy.isLinked && JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery') {
            if (compy.isLinked && getSecureData(Constants.ORDERTYPE) == 'Delivery') {
                if (this.state.isCompanyChecked) {
                    if (finalTotal >= obj.objScoreDetails.perDayCredit) {
                        finalTotal -= obj.objScoreDetails.perDayCredit
                        cmpDiscount = obj.objScoreDetails.perDayCredit
                    } else {
                        cmpDiscount = finalTotal
                        finalTotal = 0
                    }
                } else {

                }
                
            }
        }

        //Count avocado point
        var avocadoDiscount = 0
        if(this.state.objAvocadoDetails.useAvocadoPoint >= this.state.objAvocadoDetails.minredemptionrequired) {
            if(this.state.isAvocadoChecked) {
                if (finalTotal >= this.state.objAvocadoDetails.minredemptionrequired * this.state.objAvocadoDetails.discountof1avocado) {
                    finalTotal -= this.state.objAvocadoDetails.minredemptionrequired * this.state.objAvocadoDetails.discountof1avocado
                    avocadoDiscount = this.state.objAvocadoDetails.minredemptionrequired * this.state.objAvocadoDetails.discountof1avocado
                } else {
                    avocadoDiscount = finalTotal
                    finalTotal -= finalTotal
                }
                
            }
        }
        
        {/*Voucher code comment*/}
        //Count voucher discount
        // var voucherDiscount = 0
        // if(this.state.isVoucherApplied) {
        //     voucherDiscount = this.state.objVoucher.discout
        //     finalTotal -= this.state.objVoucher.discout
        // } 

        //Count packaging charges
        var pCharge = Number(objLocation.packagingCharge)
        finalTotal += pCharge
        
        var dCharge = 0
        if (getSecureData(Constants.ORDERTYPE) == 'Delivery') {
            // dCharge = getSecureData(Constants.CHECKINFO).deliveryCharge
            // finalTotal += dCharge
            if(this.state.isCompanyChecked) {
                if (this.state.objDeliveryCharge.deliveryCharges) {
                    dCharge = Number(this.state.objDeliveryCharge.deliveryCharges)
                    finalTotal += dCharge
                }
            } else {
                // if (this.state.objDeliveryCharge.deliveryCharges) {
                if (this.state.selectedAddress.address1 && this.state.objDeliveryCharge.deliveryCharges) {
                    // if (this.state.deliveryChargeResult.status == 1) {
                        dCharge = Number(this.state.objDeliveryCharge.deliveryCharges)
                        finalTotal += dCharge
                    // }
                }
            }
            
        }

        return (
            <div className="total-div">
                <div className="row">                
                    <div className="col1">{Strings.sub_total}:</div>
                    <div className="col2">{getPriceFormat(itemTotal.toFixed(2))}</div>
                </div>
                {discount>0 ? <div className="row">                
                        <div className="col1">{Strings.lbl_discount_react} {discountValue}:</div>
                        <div className="col2">- {getPriceFormat(discount.toFixed(2))}</div>
                    </div> : null}
                
                {/*Voucher code comment*/}
                {/* { voucherDiscount > 0 ? <div className="row">                
                    <div className="col1">{Strings.str_voucher_discount}:</div>
                    <div className="col2">- {getPriceFormat(voucherDiscount.toFixed(2))}</div>
                </div> : null} */}

                {/* {(this.state.isCompanyChecked && JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery' && cmpDiscount > 0) ? <div className="row">                 */}
                {(this.state.isCompanyChecked && getSecureData(Constants.ORDERTYPE) == 'Delivery' && cmpDiscount > 0) ? <div className="row">                
                    <div className="col1">{Strings.str_company_discount}:</div>
                    <div className="col2">- {getPriceFormat(cmpDiscount.toFixed(2))}</div>
                </div> : null}
                {this.state.isAvocadoChecked ? <div className="row">                
                    <div className="col1">{Strings.str_redeemed_avocados}:</div>
                    <div className="col2">- {getPriceFormat(avocadoDiscount.toFixed(2))}</div>
                </div> : null}
                {/* {(JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery' && dCharge > 0) ? <div className="row">                 */}
                {(getSecureData(Constants.ORDERTYPE) == 'Delivery' && dCharge > 0) ? <div className="row">                
                    <div className="col1">{Strings.lbl_delivery_charge_react}:</div>
                    <div className="col2">{getPriceFormat(dCharge.toFixed(2))}</div>
                </div> : null}
                {pCharge>0 ? <div className="row"> <div className="col1">{Strings.str_packaging_costs}:</div>
                    <div className="col2">{getPriceFormat(pCharge.toFixed(2))}</div>
                </div>: null}
                    
                <div className="row total-last">                
                    <div className="col1">{Strings.Total_web}:</div>
                    <div className="col2">{getPriceFormat(finalTotal.toFixed(2))}</div>
                </div>
            </div>
        )
        
    }

    onCompanyCheck() {
        if (this.state.objDeliveryAddresses.AddressLists != null) {
            if (this.state.objDeliveryAddresses.AddressLists.length == 0) {
                this.setState({isCompanyChecked: !this.state.isCompanyChecked, selectedAddress: {}})
            } else {
                // this.setState({isCompanyChecked: !this.state.isCompanyChecked, selectedAddress: this.state.objDeliveryAddresses.AddressLists[0]})
                this.setState({isCompanyChecked: !this.state.isCompanyChecked, selectedAddress: {}},()=>{
                    if (this.state.isCompanyChecked) {
                        this.callDeliveryCharge()
                    }
                })//removed pre selected delivery address
            }
        } else {
            this.setState({isCompanyChecked: !this.state.isCompanyChecked, selectedAddress: {}})
        }
        
    }

    renderAddressView() {

        // if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'TakeAway') {
        if (getSecureData(Constants.ORDERTYPE) == 'TakeAway') {
            return
        }

        var arrReturn = []
        let objScore = this.state.objCompanyCredit.objScoreDetails
        if (objScore) {
            //Check company is linked or not
            if (objScore.isLinked) {
                //Check if company address as elected as delivery address or not
                if (this.state.isCompanyChecked) {
                    return(
                        <div>
                            <div className="radio-div">
                                <a onClick={()=>{this.onCompanyCheck()}}>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" name="customRadioo" id="customRadio2" checked={this.state.isCompanyChecked}/> 
                                        <label className="custom-control-label order-select" for="customRadio2">{this.state.objCompanyCredit.labaltext}</label>
                                    </div>
                                </a>
                            </div>
                            <p>{this.state.objCompanyCredit.geoCodeAdress}</p>
                        </div>
                    )
                } else {
                    //if not selected company address as delivery address then render normal delivery view
                    return(
                        <div>
                            <div className="radio-div">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <a onClick={()=>{this.onCompanyCheck()}}>
                                        <input type="radio" className="custom-control-input" name="customRadioC1" id="customRadio2" checked={this.state.isCompanyChecked} />
                                    </a>
                                    <label className="custom-control-label order-select" for="customRadio2">{this.state.objCompanyCredit.labaltext}</label>
                                </div>
                            </div>
                            {this.renderUserAddress()}
                        </div>
                    )
                }
                
            } else {
                //if not linked then render normal delivery address view
                return(this.renderUserAddress())
            }
        }
        return arrReturn
    }

    renderUserAddress() {
        let objAddress = this.state.objDeliveryAddresses
        var arrReturn = []
        /*//Check address list available or not
        if (objAddress.AddressLists) {
            if(objAddress.AddressLists.length > 0) {
                //push selected address view 
                arrReturn.push(
                    <div >
                        <p>{this.state.selectedAddress.address1}<br/>{this.state.selectedAddress.address2}</p>
                        <div className="checkout margin0">
                            <div className="check-div margin0">
                                <div className="single-prod margin0">
                                    <div className="buttons-checkout">
                                        <a href="#" className="btn btn-medium trans-btn" onClick={()=>this.setState({isAddressVisible:!this.state.isAddressVisible})}>{Strings.change_add_web}</a>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            //push only add address button 
            arrReturn.push(
                <div className="checkout margin0">
                    <div className="check-div margin0">
                        <div className="single-prod margin0">
                            <div className="buttons-checkout">
                                <a href="#" className="btn btn-medium trans-btn" onClick={()=>{this.setState({isAddAddressVisible:true})}}>{Strings.str_add_address}</a>  
                            </div>
                        </div>
                    </div>
                </div>
            )
        }*/
        arrReturn.push(
            <div key={0}>
                {this.state.selectedAddress.address1 != null ? <p>{this.state.selectedAddress.address1}<br/>{this.state.selectedAddress.address2}</p> : null} 
                <div className="checkout margin0">
                    <div className="check-div margin0">
                        <div className="single-prod margin0">
                            <div className="buttons-checkout">
                                <a href="#" className="btn btn-medium trans-btn" onClick={()=>{this.setState({isAddressVisible:true})}}>{Strings.select_address}</a>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        return arrReturn
    }


    onQtyChanged(val,index) {

        if (val.match("^[0-9]*$") != null) {
            if (val == "") {
                val = 1
            } else if (val == 0) {
                val = 1
            }
            var tempArray = this.state.arrayCartData
    
            let q = Number(tempArray[index].totalQty)
            let p = Number(tempArray[index].totalPrice)
            let QPrice = p / q
            tempArray[index].totalQty = Number(val)
            tempArray[index].totalPrice = val * QPrice
    
    
            this.setState({arrayCartData:tempArray},()=>{
                // localStorage.setItem(Constants.USERCART,JSON.stringify(this.state.arrayCartData))
                setSecureData(Constants.USERCART,this.state.arrayCartData)
                setTimeout(() => {
                    this.callDeliveryCharge()
                }, 100);
            })
            // setTimeout(() => {
                
            //     setSecureData(Constants.USERCART,this.state.arrayCartData)
            // }, 100);
        }

        
    }

    onEraseClick(item) {

        this.setState({eraseItem: item, isAlertVisible: true, alertMessage:Strings.msg_erase_item, btnLeft:Strings.yes, btnRight:Strings.str_no,isMultiButtom: true, alertType:1})
        return
        var tempArray = this.state.arrayCartData
        let index = tempArray.indexOf(item)
        if (index >= -1) {
            tempArray.splice(index,1)
        }
        this.setState({arrayCartData:tempArray})
        setTimeout(() => {
            localStorage.setItem(Constants.USERCART,JSON.stringify(this.state.arrayCartData))
        }, 100);
    }

    onEditClick(item,index) {
        //Check item added from reorder or menuitems list
        if (item.isReOrderedItem) {

            this.props.getItemDetailsAPI(item.menuitemId,this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    let cartItem = item
                    let menuDetails = this.props.data.result

                    let cartPriceList = cartItem.PriceList.filter(price => (price.isSelect == true))

                    let menuPriceList = menuDetails.PriceList.filter(price => (price.typeid == cartPriceList[0].typeid))

                    let optList = cartPriceList[0].OptionList.filter(opt => (opt.isSelect == true))
                    var arrOpt = []
                    optList.map(opt => (
                        arrOpt.push(opt.typeid)
                    ))
                    console.log(' Option List  ----- ',cartItem)

                    var arrayOption = []
                    menuPriceList[0].OptionList.map(options => {
                        if (arrOpt.includes(options.typeid)) {
                            options['isSelect'] = true
                        }
                        arrayOption.push(options)
                    })
                    
                    menuPriceList[0].isSelect = true
                    menuPriceList[0].OptionList = arrayOption
                    menuDetails.totalPrice = cartItem.totalPrice
                    menuDetails.totalQty = cartItem.totalQty
                    menuDetails.PriceList = menuPriceList
                    
                    this.setState({editItem: menuDetails, isItemPopupVisible: true, editAt: index})

                } else {
                    
                }
            })
        } else 
            this.setState({editItem: item, isItemPopupVisible: true, editAt: index})
    }

    renderPriceType(index) {
        let obj = this.state.arrayCartData[index].PriceList.filter((data)=>(data.isSelect == true))
        return( 
            <p>{obj[0].type}</p>
        )
    }

    renderOptions(index) {
        var arrayTitle = []
        let objPriceList = this.state.arrayCartData[index].PriceList.filter((data)=>(data.isSelect == true))
        let objOptList = objPriceList[0].OptionList.filter((data)=>(data.isSelect == true))

        objOptList.map((data) => {
          var temp = arrayTitle.filter(obj => (obj == data.labelText))
          if (temp.length == 0) {
            arrayTitle.push(data.labelText)
          }
        })
        
        var arrData = []
        arrayTitle.map((data,i)=>{
            var str = []
            let obj = objOptList.filter(flt=>(flt.labelText == data && flt.isSelect == true))
            obj.map((opt)=>{
                str.push(opt.type)
            })  
            arrData.push(
                <div key={i}>
                    <h5>{data}</h5>
                    <p>{str.join(', ')}</p>
                </div>
            )
        })
        return arrData
    }

    renderCartItems() {
        if(this.state.arrayCartData == null) {
            return (
                <label>{Strings.cart_empty}</label>
            )
        }
        if (this.state.arrayCartData.length == 0) {
            return (
                <label>{Strings.cart_empty}</label>
            )
        }

        var arrayItem = []
        this.state.arrayCartData.map((items,index) => {
            arrayItem.push(
                <div className="row single-prod" key={index}>
                    <div className="prod-img col-md-4">
                        <img src={bindhttps(items.imgurl)}/>
                    </div>
                    <div className="prod-img col-md-8">
                        <h2>{items.menuItemName}</h2>
                        {this.renderOptions(index)}
                        {this.renderPriceType(index)}
                        <div className="quontity">{Strings.lbl_quantity_react}:
                        <div className="form-group"><input type="text" className="form-control" maxLength={2} value={items.totalQty} onChange={e=>{this.onQtyChanged(e.target.value,index)}}/></div> 
                        <div className="price">{getPriceFormat(items.totalPrice.toFixed(2))}</div></div>
                        <div className="buttons-checkout menuItem">
                            <a href="#" className="btn btn-medium trans-btn" onClick={()=>{this.onEraseClick(items)}}>{Strings.str_erase}</a>   
                            <a href="#" className="btn btn-primary btn-medium" onClick={()=>{this.onEditClick(items,index)}}>{Strings.btn_edit}</a>    
                        </div>
                    </div>
                    <div style={{width:'80%',height:'1px',backgroundColor:'gray',marginTop:'50px',alignSelf:'center'}}></div>
                </div>
                
            )
        })
        return arrayItem
    }

    onPopupClose(popup) {
        console.log('popup close ---- ',popup)
        // this.setState({isItemPopupVisible: false, arrayCartData: JSON.parse(localStorage.getItem(Constants.USERCART)),isAlertVisible: popup.isHide, alertMessage: Constants.msgItemUpdated, btnLeft: Strings.Ok_web, isMultiButtom: false, alertType:0 })
        this.setState({isItemPopupVisible: false, arrayCartData: getSecureData(Constants.USERCART),isAlertVisible: popup.isHide, alertMessage: Constants.msgItemUpdated, btnLeft: Strings.Ok_web, isMultiButtom: false, alertType:0 },()=>{
            this.callDeliveryCharge()
        })
    }

    callchecknextday = async (type) => {

        let userObject = getSecureData(Constants.USEROBJECT)
        let locationObject = getSecureData(Constants.LOCATIONOBJECT)
        await this.props.checkNextDayOrder(userObject.customerId, this.state.objCompanyCredit.objScoreDetails.companyId ? this.state.objCompanyCredit.objScoreDetails.companyId : 0, locationObject.locationId, this.state.orderTimeType == 1 ? this.state.uktime : this.state.customTime, this.state.languageType).then(() =>{
            
            if (this.props.data.status == 1) {  
                if (this.props.data.result.isNextDayOrder) {
                    console.log('it is next day order -------- ')
                    this.setState({
                        isAlertVisible: true,
                        isMultiButtom: true,
                        alertMessage: Strings.str_msg_next_day_order_confirm,
                        btnLeft:Strings.btn_confirm,
                        btnRight:Strings.btn_cancel,
                        alertType: 3,
                        oType: type
                    })
                } else {
                    console.log('order today -------- ')
                    this.callAddOrder(type)
                }
            } else {
                alert(this.props.data.message)
            }
        })
        .catch((errro) => {

        })
    }

    onPaymentSelection(type) {

        if (type == 3)
            this.setState({isPaymentMethodVisible:false,isPayPal:true,paymentMode:3},()=>{
                if(this.state.isCompanyChecked) {
                    this.callchecknextday(type)
                } else {
                    this.callAddOrder(type)
                }
            })
        else if (type == 1)
            this.setState({isPaymentMethodVisible:false,isPayPal:false,paymentMode:1},()=>{
                if(this.state.isCompanyChecked) {
                    this.callchecknextday(type)
                } else {
                    this.callAddOrder(type)
                }
            })
        else 
            this.setState({isPaymentMethodVisible:false})
        
    }

    callAddOrder(type) {
        console.log('order api statement------------')
        setTimeout(() => {

            //Place order 
            if (type == 1 || type == 3) {
                var deliveryObject = {}

                // if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery') {
                if (getSecureData(Constants.ORDERTYPE) == 'Delivery') {
                    if (this.state.isCompanyChecked) {
                        deliveryObject['deliveryaddressId'] = this.state.objCompanyAddress.deliveryaddressId
                        deliveryObject['address1'] = this.state.objCompanyAddress.address1
                        deliveryObject['address2'] = this.state.objCompanyAddress.address2
                        deliveryObject['landmark'] = this.state.objCompanyAddress.landmark
                        deliveryObject['city'] = this.state.objCompanyAddress.city
                        deliveryObject['zipcode'] = this.state.objCompanyAddress.zipcode
                    } else {
                        if (this.state.selectedAddress['address1'] == null) {
                            this.setState({isAlertVisible: true, alertMessage:Strings.select_add_delivery_address, isMultiButtom: false, btnLeft:Strings.Ok_web})
                            return
                        } else {
                            deliveryObject['deliveryaddressId'] = this.state.selectedAddress.deliveryaddressId
                            deliveryObject['address1'] = this.state.selectedAddress.address1
                            deliveryObject['address2'] = this.state.selectedAddress.address2
                            deliveryObject['landmark'] = this.state.selectedAddress.landmark
                            deliveryObject['city'] = this.state.selectedAddress.city
                            deliveryObject['zipcode'] = this.state.selectedAddress.zipcode
                        }
                    }
                }

                // let userObject = JSON.parse(localStorage.getItem(Constants.USEROBJECT))
                // let locationObject = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT))
                // let cartItems = JSON.parse(localStorage.getItem(Constants.USERCART))
                let userObject = getSecureData(Constants.USEROBJECT)
                let locationObject = getSecureData(Constants.LOCATIONOBJECT)
                let cartItems = getSecureData(Constants.USERCART)

                //
                var arrCustomer = []
                let customerItem = cartItems.map(item => {
                    let dict = {}
                    dict['menuitemId'] = item.catId
                    dict['qty'] = item.totalQty
                    dict['subparameterid'] = 0
                    arrCustomer.push(dict)
                })
                var cartdetail = {}
                cartdetail['CustomeOfferMenuItem'] = arrCustomer
                cartdetail['restaurantId'] = 147
                cartdetail['locationId'] = locationObject.locationId

                
                //
                var arrOrderItems = []
                cartItems.map(item => {
                    
                    // console.log('Item data ----- ',item)

                    var objItem = {}
                    let selectedPrice = item.PriceList.filter(priceData => (priceData.isSelect == true))
                    
                    var price = {}
                    price['typeid'] = selectedPrice[0].typeid
                    price['type'] = selectedPrice[0].type
                    price['price'] = selectedPrice[0].price

                    var optList = []
                    selectedPrice[0].OptionList.map(option => {
                        let dict = {}
                        dict['type'] = option.type
                        dict['labelText'] = option.labelText
                        dict['thumbimgurl'] = option.thumbimgurl
                        dict['typeid'] =  option.typeid
                        dict['sizepid'] = option.sizepid
                        dict['parameterId'] = option.parameterId
                        dict['price'] = option.price
                        dict['isSelect'] = option.isSelect
                        dict['isSelectAll'] = option.isSelectAll
                        
                        if (option.isSelect) {
                            optList.push(dict)
                        } else {
                            if (option.isSelectAll) {
                                optList.push(dict)
                            }
                        }
                    })

                    objItem['itemname'] = item.menuItemName
                    objItem['orderitemId'] = item.menuitemId
                    objItem['catId'] = item.catId
                    objItem['qty'] = item.totalQty
                    objItem['rewardpoints'] = 0
                    objItem['ToppingsList'] = []
                    objItem['price'] = price
                    objItem['OptionList'] = optList

                    arrOrderItems.push(objItem)

                })


                let tDate = moment().format("YYYY-MM-DD HH:mm")

                var OrderInfo = {}
                
                OrderInfo['appVersion'] =  1.0
                OrderInfo['appName'] = 147
                OrderInfo['specialOfferTitle'] = ''
                OrderInfo['discout'] = 0.0
                OrderInfo['deliveryaddress'] = deliveryObject
                OrderInfo['packagingCharge'] = locationObject.packagingCharge
                OrderInfo['contactno'] = userObject.countryCode+userObject.phone
                OrderInfo['appfor'] = 'WEB'
                OrderInfo['offerItems'] = []
                OrderInfo['apnToken'] = ''
                OrderInfo['locationId'] = locationObject.locationId
                OrderInfo['paymentmode'] = this.state.paymentMode
                OrderInfo['redeempoints'] = 0
                OrderInfo['orderdate'] = tDate
                OrderInfo['orderId'] = -1
                OrderInfo['specialOfferId'] = 0
                OrderInfo['contactname'] = userObject.firstname
                OrderInfo['ispaypal'] = this.state.isPayPal
                OrderInfo['note'] = ''
                OrderInfo['specialOfferDescription'] = ''
                OrderInfo['contacttime'] = this.state.orderTimeType == 1 ? this.state.uktime : this.state.customTime
                OrderInfo['payAmountByCompany'] = this.state.isCompanyChecked ? this.state.objCompanyCredit.objScoreDetails.perDayCredit : 0.0
                OrderInfo['OrderedItems'] = arrOrderItems
                OrderInfo['customerId'] = userObject.customerId
                // OrderInfo['ordertype'] = JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'TakeAway' ? 1 : 2  //TAEKAWAY=1,DELIVERY=2
                OrderInfo['ordertype'] = getSecureData(Constants.ORDERTYPE) == 'TakeAway' ? 1 : 2  //TAEKAWAY=1,DELIVERY=2
                OrderInfo['companyId'] = this.state.isCompanyChecked ? this.state.objCompanyCredit.objScoreDetails.companyId : 0
                OrderInfo['cartdetail'] = cartdetail
                OrderInfo['tableNo'] = 0
                OrderInfo['locationId'] = locationObject.locationId
                
                {/*Voucher code comment*/}
                // OrderInfo['offerCode'] = this.state.isVoucherApplied ? this.state.objVoucher.discout : 0.0
                // OrderInfo['offercodeDiscount'] = this.state.isVoucherApplied ? this.state.strVoucher : ''

                // if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'TakeAway') {
                if (getSecureData(Constants.ORDERTYPE) == 'TakeAway') {
                    OrderInfo['deliveryCharges'] = 0
                } else {
                    // OrderInfo['deliveryCharges'] = getSecureData(Constants.CHECKINFO).deliveryCharge
                    OrderInfo['deliveryCharges'] = this.state.objDeliveryCharge.deliveryCharges ? this.state.objDeliveryCharge.deliveryCharges : 0
                }
                if(this.state.isAvocadoChecked) {
                    OrderInfo['avacadoRedeemDiscount'] = this.state.objAvocadoDetails.discountof1avocado * this.state.objAvocadoDetails.minredemptionrequired
                    OrderInfo['avacadoRedeemPoint'] = this.state.objAvocadoDetails.minredemptionrequired
                }
                console.log('Order Object ---- ',OrderInfo)
                
                
                this.props.addOrderAPI(OrderInfo,this.state.languageType).then(() => {
                    if (this.props.data.status == 1) {
                        setTimeout(() => {
                            let response = this.props.data.result
                            console.log('order response ========================= ',response)
                            //Check payment method type 
                            if(this.state.isPayPal) {
                                // this.props.history.push('/Categories')
                                setSecureData(Constants.PLACEDORDEROBJECT,response)
                                setTimeout(() => {
                                    this.props.history.push({pathname:'PaymentCardList'})    
                                }, 100);
                            } else {
                                var str = response.message + '\n' + Strings.str_your_order_is + ' : ' + response.orderNo
                                this.setState({alertType: 2, isAlertVisible: true, alertMessage:str, isMultiButtom:false, btnLeft:Strings.Ok_web})
                                //Move on category screen when click on ok popup


                                // localStorage.removeItem(Constants.USEROBJECT);
                                localStorage.removeItem(Constants.USERCART);
                                // localStorage.removeItem(Constants.ORDERTYPE);
                                // localStorage.removeItem(Constants.CHECKINFO);
                                // localStorage.removeItem(Constants.PRE_CARD_LIST);  
                                // localStorage.removeItem(Constants.PRE_WAITER_ITEM);  
                                // localStorage.removeItem(Constants.PRE_CAT_ID);
                            }  
                        }, 500)
                    } else {
                        console.log(' add order api else ',this.props.data)
                        this.setState({isAlertVisible: true, alertMessage:this.props.data.message, isMultiButtom:false, btnLeft:Strings.Ok_web})
                    }
                })
            }
        }, 200);
    }

    onLeftClick() {
        //alertType  0 == normal, 1 == erase, 2 == order place message,
        if (this.state.alertType == 0) {
            this.setState({isAlertVisible:false})
        } else if (this.state.alertType == 1) {
            
            var tempArray = this.state.arrayCartData
            let index = tempArray.indexOf(this.state.eraseItem)
            if (index >= -1) {
                tempArray.splice(index,1)
            }
            this.setState({arrayCartData:tempArray,isAlertVisible:false})
            
            setTimeout(() => {
                // localStorage.setItem(Constants.USERCART,JSON.stringify(this.state.arrayCartData))
                setSecureData(Constants.USERCART,this.state.arrayCartData)
                this.setState({eraseItem: {}, isAlertVisible: true, alertMessage:Strings.msg_item_erased, btnLeft:Strings.Ok_web, isMultiButtom: false, alertType:0})
                
                setTimeout(() => {
                    let cartItems = getSecureData(Constants.USERCART)
                    if (cartItems.length != 0) {
                        this.callDeliveryCharge()
                    }
                }, 300);
            }, 100);

        } else if (this.state.alertType == 2) {
            this.setState({isAlertVisible:false})
            this.props.history.push('/Categories')
        } else if (this.state.alertType == 3) {
            this.setState({
                isAlertVisible: false
            },()=>{
                this.callAddOrder(this.state.oType)
            })
        } else {
            this.setState({isAlertVisible:false})
        }
        
    }
    onCloseAddress(address) {
        console.log('on select address ---------- ',address)
        if (address) {
            this.setState({isAddressVisible:!this.state.isAddressVisible,selectedAddress:address},()=>{
                this.callDeliveryCharge()
            })
        } else {
            this.setState({isAddressVisible:!this.state.isAddressVisible})
        }
    }

    onDeleteAddress() {
        this.setState({isAddressVisible:false})
        // let customer = JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId
        // let id = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId
        let customer = getSecureData(Constants.USEROBJECT).customerId
        let id = getSecureData(Constants.LOCATIONOBJECT).locationId
        this.callGetDeliveryAddress(id,customer)
    }

    onAddNewAddress(address) {
        if (address) {
            console.log('address added ----- ',address)
            this.setState({isAddAddressVisible:false})

            // let customer = JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId
            // let id = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId
            let customer = getSecureData(Constants.USEROBJECT).customerId
            let id = getSecureData(Constants.LOCATIONOBJECT).locationId
            this.callGetDeliveryAddress(id,customer)

        } else {
            console.log('address closed ----- ',address)
            this.setState({isAddAddressVisible:false})
        }
        
    }

    onAddAddress() {
        this.setState({isAddressVisible:false,isAddAddressVisible:true})
    }

    onTimeSelect(type,e) {
        var val = e//.target.value

        let hrs = moment(this.state.customTime,'HH:mm').format('HH')
        let mns = moment(this.state.customTime,'HH:mm').format('mm')

        if (type==1) {
            this.setState({customTime:val+':'+mns})
        } else {
            this.setState({customTime:hrs+':'+val})
        }
    }

    renderTimeView(type) {

        let hrs = moment(this.state.uktime,'HH:mm').format('HH')
        let mns = moment(this.state.uktime,'HH:mm').format('mm')
        
        var arrMM = []
        var arrHH = []

        if (type==1) {
            for (var i=0;i<24;i++) {
                // arrHH.push(<option value={i} selected={hrs == i ? true : false}>{i}</option>)
                arrHH.push(<Dropdown.Item eventKey={i} key={i}>{i}</Dropdown.Item>)
            }  
            return arrHH  
        } else {
            for (var i=0;i<60;i++) {
                // arrMM.push(<option value={i} selected={mns == i ? true : false}>{i}</option>)
                arrMM.push(<Dropdown.Item eventKey={i} key={i}>{i}</Dropdown.Item>)
            }
            return arrMM
        }


        /*if (this.state.orderTimeType == 2) {
            return(
                // <div className="select-time">
                //     <label>Select time</label>
                //     <div className="form-group">
                //         <input type="text" className="form-control" placeholder="20:00"/>
                //     </div>
                // </div>
                <div className="select-time">
                    <label>Selecy time</label>
                    <div className="form-group"><input type="text" className="form-control" placeholder="20:00"/>
                        
                    </div>
                </div> 
            )
        }*/
    }

    onCheckOutClicked() {

        // if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'TakeAway') {
        if (getSecureData(Constants.ORDERTYPE) == 'TakeAway') {
            console.log('order type takeaway')
            if (this.state.orderTimeType == 2) {
                let uk = moment(this.state.uktime,'HH:mm')
                let custom = moment(this.state.customTime,'HH:mm')
                console.log('diffffff takeaway ------------------- ',custom.diff(uk, 'minutes'))
                // if(!uk.isBefore(custom)) {
                if(custom.diff(uk, 'minutes') < 0) {
                    this.setState({isAlertVisible: true, alertMessage:Strings.past_time, isMultibutton: false, btnLeft:Strings.Ok_web })
                    return
                } else 
                    this.setState({isPaymentMethodVisible: true})
            } else 
                this.setState({isPaymentMethodVisible: true})
        // } else if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery') {
        } else if (getSecureData(Constants.ORDERTYPE) == 'Delivery') {
            if (this.state.orderTimeType == 2) {
                
                let uk = moment(this.state.uktime,'HH:mm')
                let custom = moment(this.state.customTime,'HH:mm')
                console.log('diffffff ------------------- ',custom.diff(uk, 'minutes'))
                // if(!uk.isBefore(custom)) {
                if(custom.diff(uk, 'minutes') < 0) {
                    this.setState({isAlertVisible: true, alertMessage:Strings.past_time, isMultibutton: false, btnLeft:Strings.Ok_web })
                    return
                } else if (!this.state.isCompanyChecked) {
                    console.log('33333333333 ----------------- ')
                    if (this.state.selectedAddress['address1'] == null) {
                        this.setState({isAlertVisible: true, alertMessage:Strings.select_add_delivery_address, isMultiButtom: false, btnLeft:Strings.Ok_web})
                        return
                    } else if(this.state.deliveryChargeResult != null && this.state.deliveryChargeResult.status != 1){
                        this.setState({isAlertVisible: true, alertMessage: this.state.deliveryChargeResult.message, isMultiButtom: false, btnLeft:Strings.Ok_web})
                        return
                    } else 
                        this.setState({isPaymentMethodVisible: true})
                }  else {
                    console.log('check111111 ----------------- ')
                    if(this.state.deliveryChargeResult != null && this.state.deliveryChargeResult.status != 1) {
                        this.setState({isAlertVisible: true, alertMessage: this.state.deliveryChargeResult.message, isMultiButtom: false, btnLeft:Strings.Ok_web})
                        return
                    } else {
                        this.setState({isPaymentMethodVisible: true})
                    }
                }
                    
            } else {
                if (!this.state.isCompanyChecked) {
                    console.log('check ----------------- 222222222222------',this.state.deliveryChargeResult)
                    if (this.state.selectedAddress['address1'] == null) {
                        this.setState({isAlertVisible: true, alertMessage:Strings.select_add_delivery_address, isMultiButtom: false, btnLeft:Strings.Ok_web})
                        return
                    } else if(this.state.deliveryChargeResult != null && this.state.deliveryChargeResult.status != 1) {
                        this.setState({isAlertVisible: true, alertMessage: this.state.deliveryChargeResult.message, isMultiButtom: false, btnLeft:Strings.Ok_web})
                        return
                    } else {
                        this.setState({isPaymentMethodVisible: true})
                    }
                } else {
                    console.log('check ----------------- ')
                    if(this.state.deliveryChargeResult.status != 1) {
                        this.setState({isAlertVisible: true, alertMessage: this.state.deliveryChargeResult.message, isMultiButtom: false, btnLeft:Strings.Ok_web})
                        return
                    } else {
                        this.setState({isPaymentMethodVisible: true})
                    }
                }
            } 
        } else {
            // console.log('else order type ----- ',JSON.parse(localStorage.getItem(Constants.ORDERTYPE))) 
            console.log('else order type ----- ',getSecureData(Constants.ORDERTYPE))
        }
        



        /*if (this.state.orderTimeType == 2) {
                
            let uk = moment(this.state.uktime,'HH:mm')
            let custom = moment(this.state.customTime,'HH:mm')

            if(!uk.isBefore(custom)) {
                this.setState({isAlertVisible: true, alertMessage:Strings.past_time, isMultibutton: false, btnLeft:Strings.Ok_web })
                return
            } else if (!this.state.isCompanyChecked) {
                if (this.state.selectedAddress['address1'] == null) {
                    this.setState({isAlertVisible: true, alertMessage:Strings.select_add_delivery_address, isMultiButtom: false, btnLeft:Strings.Ok_web})
                    return
                } else 
                    this.setState({isPaymentMethodVisible: true})
            } else 
                this.setState({isPaymentMethodVisible: true})
                
        } else if (JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery') {
            if (!this.state.isCompanyChecked) {
                if (this.state.selectedAddress['address1'] == null) {
                    this.setState({isAlertVisible: true, alertMessage:Strings.select_add_delivery_address, isMultiButtom: false, btnLeft:Strings.Ok_web})
                    return
                } else 
                    this.setState({isPaymentMethodVisible: true})
            } else 
                this.setState({isPaymentMethodVisible: true})
        } else 
            this.setState({isPaymentMethodVisible: true})*/
        
    }

    callDeliveryCharge() {

        var addid = 0
        if (this.state.isCompanyChecked) { 
            addid = this.state.objCompanyAddress.deliveryaddressId            
        } else {
            if (!this.state.selectedAddress.address1) {
                return
            } else {
                addid = this.state.address ? this.state.address.deliveryaddressId : 0
            }
        }

        let customerid = getSecureData(Constants.USEROBJECT).customerId
        let locationid = getSecureData(Constants.LOCATIONOBJECT).locationId
                
        let itemTotal = 0
        this.state.arrayCartData.map(item => {
            itemTotal += item.totalPrice
        })
        
        this.props.calculateDeliveryCharge(addid,customerid,locationid,itemTotal,this.state.languageType).then(() => {
            console.log("calculateDeliveryCharge RESPONSE --------- ",this.props.data)
            // return
            if (this.props.data.status == 1) {
                if (this.props.data.result != null) {
                    this.setState({objDeliveryCharge: this.props.data.result, deliveryChargeResult: this.props.data})
                }                
            } else {
                this.setState({objDeliveryCharge: {}, deliveryChargeResult: this.props.data, isAlertVisible: true, alertMessage:this.props.data.message, isMultiButtom: false, btnLeft:Strings.Ok_web,})
            }
        })

    }

    onApplyVoucher() {
        if(!this.state.isVoucherApplied) {
            if(this.state.strVoucher == '') {
                this.setState({isAlertVisible: true, alertMessage:Strings.errorMsgVoucherCode, isMultiButtom: false, btnLeft:Strings.Ok_web})
            } else {

                //total amount logic same as calculate method
                var finalTotal = 0
                let itemTotal = 0
                this.state.arrayCartData.map(item => {
                    itemTotal += item.totalPrice
                })
                finalTotal = itemTotal

                var discount = 0
                var discountValue = ''
                if (this.state.objOffers) {
                    //Check offers available or not
                    if (this.state.objOffers.isfirstOrder == 1) {
                        //Check discount type 1=Percentage 2=Amount
                        if (this.state.objOffers.firstOrder.discountType == 1) {
                            let str = this.state.objOffers.firstOrder.discountPercentage
                            let arrStr = str.split('.')
                            if (arrStr[1] == 0) {   
                                discountValue = '('+arrStr[0]+'%)'
                            } else {
                                discountValue = '('+str+'%)'
                            }
                            let temp = finalTotal * Number(str) / 100
                            discount = temp
                            finalTotal -= temp//Number(this.state.objOffers.firstOrder.discount)
                        } else {
                            discount = Number(this.state.objOffers.firstOrder.discount)
                            finalTotal -= Number(this.state.objOffers.firstOrder.discount)
                        }
                    }
                }

                // let objLocation = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT))
                let objLocation = getSecureData(Constants.LOCATIONOBJECT)
                
                //Count Company discount
                var cmpDiscount = 0
                let obj = this.state.objCompanyCredit
                let compy = obj.objScoreDetails
                if (compy) {
                    // if (compy.isLinked && JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery') {
                    if (compy.isLinked && getSecureData(Constants.ORDERTYPE) == 'Delivery') {
                        if (this.state.isCompanyChecked) {
                            if (finalTotal >= obj.objScoreDetails.perDayCredit) {
                                finalTotal -= obj.objScoreDetails.perDayCredit
                                cmpDiscount = obj.objScoreDetails.perDayCredit
                            } else {
                                cmpDiscount = finalTotal
                                finalTotal = 0
                            }
                        } else {

                        }
                        
                    }
                }

                //Count avocado point
                var avocadoDiscount = 0
                if(this.state.objAvocadoDetails.useAvocadoPoint >= this.state.objAvocadoDetails.minredemptionrequired) {
                    if(this.state.isAvocadoChecked) {
                        if (finalTotal >= this.state.objAvocadoDetails.minredemptionrequired * this.state.objAvocadoDetails.discountof1avocado) {
                            finalTotal -= this.state.objAvocadoDetails.minredemptionrequired * this.state.objAvocadoDetails.discountof1avocado
                            avocadoDiscount = this.state.objAvocadoDetails.minredemptionrequired * this.state.objAvocadoDetails.discountof1avocado
                        } else {
                            avocadoDiscount = finalTotal
                            finalTotal -= finalTotal
                        }
                        
                    }
                }               


                let cartItems = getSecureData(Constants.USERCART)

               
                var arrOrderItems = []
                cartItems.map(item => {
                    
                    // console.log('Item data ----- ',item)

                    var objItem = {}
                    let selectedPrice = item.PriceList.filter(priceData => (priceData.isSelect == true))
                    
                    var price = {}
                    price['typeid'] = selectedPrice[0].typeid
                    price['type'] = selectedPrice[0].type
                    price['price'] = selectedPrice[0].price

                    var optList = []
                    selectedPrice[0].OptionList.map(option => {
                        let dict = {}
                        dict['type'] = option.type
                        dict['labelText'] = option.labelText
                        dict['thumbimgurl'] = option.thumbimgurl
                        dict['typeid'] =  option.typeid
                        dict['sizepid'] = option.sizepid
                        dict['parameterId'] = option.parameterId
                        dict['price'] = option.price
                        dict['isSelect'] = option.isSelect
                        dict['isSelectAll'] = option.isSelectAll
                        
                        if (option.isSelect) {
                            optList.push(dict)
                        } else {
                            if (option.isSelectAll) {
                                optList.push(dict)
                            }
                        }
                    })

                    objItem['itemname'] = item.menuItemName
                    objItem['orderitemId'] = item.menuitemId
                    objItem['catId'] = item.catId
                    objItem['qty'] = item.totalQty
                    objItem['rewardpoints'] = 0
                    objItem['ToppingsList'] = []
                    objItem['price'] = price
                    objItem['OptionList'] = optList

                    arrOrderItems.push(objItem)

                })

                // public enum OrderType
                // {
                //     TakeAway = 1,
                //     Delivery = 2,
                //     SitDown = 3,
                //     InWaiting = 4
                // }

                let customer = getSecureData(Constants.USEROBJECT).customerId
                let orderType = getSecureData(Constants.ORDERTYPE) == 'Delivery' ? 2 : 1
                this.props.ApplyVoucherCode(customer,finalTotal,this.state.strVoucher,orderType,arrOrderItems,this.state.languageType).then(() => {
                    console.log("ApplyVoucherCode RESPONSE --------- ",this.props.data)
                    // return
                    if (this.props.data.status == 1) {
                        if (this.props.data.result != null) {
                            //voucher available
                            this.setState({isVoucherApplied: true,objVoucher: this.props.data.result})
                        }
                    } else if (this.props.data.status == 2) {
                        this.setState({isVoucherApplied: false,isAlertVisible: true, alertMessage:this.props.data.message, isMultiButtom: false, btnLeft:Strings.Ok_web})
                    } else {

                    }
                })
            }
        } else {
            this.setState({isVoucherApplied: false, objVoucher: {}, strVoucher: ''})
        }
    }

    renderRightView() {

        let locationObject = getSecureData(Constants.LOCATIONOBJECT)

        if(this.state.arrayCartData == null) {
            return
        }
        if (this.state.arrayCartData.length == 0) {
            return
        } 
        return(
            <div className="col-md-12  col-lg-4 right">
                <h1>{Strings.lbl_order_type_react}</h1>
                <div className="delivery-div">
                    <div className="radio-div margin-bottom10">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input type="radio" className="custom-control-input" name="customRadio" id="customRadio1" checked={true}/>
                            {/* <label className="custom-control-label" for="customRadio1">{(JSON.parse(localStorage.getItem(Constants.ORDERTYPE)) == 'Delivery') ? Strings.Delivery_web : Strings.lbl_takeaway_react }</label> */}
                            <label className="custom-control-label">{(getSecureData(Constants.ORDERTYPE) == 'Delivery') ? Strings.Delivery_web : Strings.lbl_takeaway_react }</label>
                        </div>
                    </div>
                    <div className="" style={{margin: '20px 0px'}}>
                        <div className="">
                            <label className="control">{Strings.str_locationname} : {locationObject.locationName}</label>
                            {/* <a href="javascript:void(0)" className="btn btn-small trans-btn tiny" onClick={()=>{this.props.history.push({pathname:'Categories', isChangeLocation: true})}}>{Strings.btn_change_location}</a> */}
                            <a href="javascript:void(0)" className="btn btn-primary btn-small" onClick={()=>{this.props.history.push({pathname:'Categories', isChangeLocation: true})}}>{Strings.btn_change_location}</a>
                        </div>
                    </div>
                    <div className="form-group">
                        {this.renderAddressView()}
                    </div>
                    <div className="radio-div">
                        <a onClick={()=>{this.setState({orderTimeType:1})}}>
                            <div className="custom-control custom-radio custom-control-inline min-width-300">
                                <input type="radio" className="custom-control-input" name="customRadio1" id="customRadio3" checked={this.state.orderTimeType == 1 ? true : false}/>
                                <label className="custom-control-label">{Strings.str_order_now} - {this.state.uktime}</label>
                            </div>
                        </a>
                        <br/>
                        <a onClick={()=>{this.setState({orderTimeType:2})}}>
                            <div className="custom-control custom-radio custom-control-inline min-width-300">
                                <input type="radio" className="custom-control-input" name="customRadio1" id="customRadio4" checked={this.state.orderTimeType == 2 ? true : false}/>
                                <label className="custom-control-label" >{Strings.str_order_later}</label>
                            </div>
                        </a>
                    </div>
                    {(this.state.orderTimeType == 2) ? 
                        <div className="select-time" style={{display:'flex'}}>
                            <label>{Strings.time_select}</label>
                            {/* <div className="form-group"> */}
                            <div className="timerDiv">

                                <Dropdown onSelect={(e) => {this.onTimeSelect(1,e)}}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    {/* {this.state.forgetCountryCode==0?"Code ":this.state.forgetCountryCode}  */}
                                    {moment(this.state.customTime,'HH:mm').format('HH')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu} style={{height:"200px",overflow:"scroll"}}>
                                        {this.renderTimeView(1)}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <label style={{fontSize: '19px',marginTop: '4px'}}> : </label>
                                <Dropdown onSelect={(e)=>{this.onTimeSelect(2,e)}}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    {/* {this.state.forgetCountryCode==0?"Code ":this.state.forgetCountryCode}  */}
                                    {moment(this.state.customTime,'HH:mm').format('mm')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu} style={{height:"200px",overflow:"scroll"}}>
                                        {this.renderTimeView(2)}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <select onChange={(e)=>{this.onTimeSelect(1,e)}}>
                                    {this.renderTimeView(1)}
                                </select>
                                <label style={{fontSize: '19px',marginTop: '4px'}}> : </label>
                                <select onChange={(e)=>{this.onTimeSelect(2,e)}}>
                                    {this.renderTimeView(2)}
                                </select> */}
                            </div>
                        </div> : null}
                        {(this.state.objAvocadoDetails.minredemptionrequired > this.state.objAvocadoDetails.discountof1avocado) ? 
                            <div className="radio-div">
                            <a onClick={()=>{this.setState({isAvocadoChecked: !this.state.isAvocadoChecked})}}>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" name="customRadioo" id="customRadio5" checked={this.state.isAvocadoChecked}/> 
                                    {/* <label className="custom-control-label order-select" for="customRadio1">Redeem {this.state.objAvocadoDetails.minredemptionrequired} avocados for {getPriceFormat(this.state.objAvocadoDetails.minredemptionrequired * this.state.objAvocadoDetails.discountof1avocado.toFixed(2))} discount</label> */}
                                    <label className="custom-control-label order-select">{Strings.str_redeem_avocado_discount.replace('#points#',this.state.objAvocadoDetails.minredemptionrequired).replace('#value#',getPriceFormat(this.state.objAvocadoDetails.minredemptionrequired * this.state.objAvocadoDetails.discountof1avocado.toFixed(2)))}</label>
                                </div>
                            </a>
                        </div> : null}
                        {/*Voucher code comment*/}
                        {/* <div className="couponDiv" style={{marginTop: '20px'}}>
                            <div className="inpwrap">
                                <input type="text" className="form-control" placeholder={Strings.hint_voucher_code} readOnly={this.state.isVoucherApplied} maxLength={16} value={this.state.strVoucher} onChange={text => {this.setState({strVoucher: text.target.value})}}/>
                            </div>
                            <div className="btnwrap">
                                <a href="javascript:void(0)" className="btn btn-small trans-btn tiny" onClick={()=>{this.onApplyVoucher()}}>{this.state.isVoucherApplied ? Strings.clear : Strings.btn_apply}</a>   
                            </div>
                        </div> */}
                </div> 

                {this.calculateFinalPrice()}
                <div className="row checkout-div">
                    <div className="prod-img col-md-12">
                        <div className="buttons-checkout" >
                            <a href="#" className="btn btn-primary btn-medium" onClick={()=>{this.onCheckOutClicked()}}>{Strings.Checkout_web}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderDrinkButton() {
        if (this.state.arrayCartData) {
            if (this.state.arrayCartData.length > 0) {
                if (!this.state.objCheckInfo) {
                    return
                }
                var drinkId = this.state.objCheckInfo.drinksCatId
                var isShow = true
                for(var i=0;i<this.state.arrayCartData.length;i++) {
                    let obj = this.state.objCheckInfo.drinksSubCatIds.filter(ids => (ids==this.state.arrayCartData[i].catId))
                    // console.log('arrr --- ---- ',this.state.objCheckInfo.drinksSubCatIds)
                    if (obj.length > 0) {
                        isShow = false
                        break
                    }
                }
                if (isShow) {
                    return(
                        // <a href="#" className="btn btn-primary btn-small" onClick={()=>{localStorage.setItem(Constants.PRE_CAT_ID,JSON.stringify(this.state.objCheckInfo.drinksCatId)); this.props.history.push({ pathname: '/MenuItems', catId: this.state.objCheckInfo.drinksCatId})}}>{Strings.str_add_drink}</a>
                        <a href="#" className="btn btn-primary btn-small" onClick={()=>{setSecureData(Constants.PRE_CAT_ID,this.state.objCheckInfo.drinksCatId); this.props.history.push({ pathname: '/MenuItems', catId: this.state.objCheckInfo.drinksCatId})}}>{Strings.str_add_drink}</a>
                    )
                }
                
            }
        }  
    }

    render() {
        return (
            <div>
               {this.props.isLoading ? (
                <div className="loadermain" style={{ display: "block" }}>
                    <div className="loader"></div>
                </div>
                ) : (
                <div className="loadermain" style={{ display: "none" }}>
                    <div className="loader"></div>
                </div>
                )}
                
                <HeaderWhiteMenu changeLanguageEvent={this.languageChangeClickEvent} isHideOrderType= {true}/>
                {this.state.isAddAddressVisible ? <AddAddressPopup isShow={this.state.isAddAddressVisible} onHideAddAddress={(data)=>{this.onAddNewAddress(data)}}/> : null}
                {this.state.isAddressVisible ? <AddressPopup isShow={this.state.isAddressVisible} addressList={this.state.objDeliveryAddresses} onHideAddressView={(data)=>{this.onCloseAddress(data)}} onAddNewAddress={()=>{this.onAddAddress()}} onDeleteAddress={()=>{this.onDeleteAddress()}}/> : null}
                <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={this.state.isMultiButtom} btnLeftClick={()=>{this.onLeftClick()}} btnRightClick={()=>{this.setState({isAlertVisible:false})}} btnLeftTitle={this.state.btnLeft} btnRightTitle={this.state.btnRight} />
                {this.state.isItemPopupVisible ? <ItemPopup itemData={this.state.editItem} editIndex={this.state.editAt} isEdit={true} onClose={(popup) => {this.onPopupClose(popup)}} isVisible={this.state.isItemPopupVisible}/> : null}
                {this.state.isPaymentMethodVisible ? <PaymentMethod isShow={this.state.isPaymentMethodVisible} isHide={(type)=>{this.onPaymentSelection(type)}} isCard={this.state.isPayByCard} isCash={this.state.isPayByCash} /> : null}
                

                <div className="content_wrap">
                    <div className="container">
                        <div className="checkout">

                            <div className="row">
                            <div className={(this.state.arrayCartData) ? [(this.state.arrayCartData.length > 0) ? "col-md-12  col-lg-8 left" : "col-md-12  col-lg-8"] : 'col-md-12  col-lg-8'}>
                                    <h1>{Strings.Checkout_web}</h1>

                                <div className="check-div" >
                                    {this.renderCartItems()}
                                    <div className="row more-prod">
                                            <div className={ this.state.arrayCartData ? (this.state.arrayCartData.length > 0 ? "buttons-checkout" : "buttons-checkout textLeft") : "buttons-checkout textLeft" }>
                                                    <a href="#" className="btn btn-primary trans-btn" onClick={()=>{this.props.history.push('/Categories')}}>{Strings.str_add_more_product}</a>
                                                    {this.renderDrinkButton()}
                                            </div>
                                        <div className="prod-img col-md-4"></div>
                                        <div className="prod-img col-md-8">
                                                
                                        </div>
                                    </div>


                                    </div>
                                </div>
                                {this.renderRightView()}
                                
                            
                            </div>
                            {/* <!--row end--> */}
                        </div>    
                    </div>   
                </div> 
                <FooterMenu/>
            </div>
        )
    }

}


function mapStateToProps(state) {
    console.log('isLoaging Checkout ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    getOffersAPI: (amount,customerId,languageType) => dispatch(HGActions.initCheckSpecialOffersAPI(amount,customerId,languageType)),
    getRestoInfo: (languageType) => dispatch(HGActions.initGetRestoInfoAPI(languageType)),
    getCompanyCreditAPI: (customerId,languageType) => dispatch(HGActions.initCompanyCreditScoreAPI(customerId,languageType)),
    addCompnayAddressAPI: (locationid,address,languageType) => dispatch(HGActions.initAddCompanyAddressAPI(locationid,address,languageType)),
    getDeliveryAddressAPI: (locationId,customerId,languageType) => dispatch(HGActions.initGetDeliveryAddressAPI(locationId,customerId,languageType)),
    addOrderAPI: (OrderInfo,languageType) => dispatch(HGActions.initAddOrdersAPI(OrderInfo,languageType)),
    getItemDetailsAPI: (menuitemId,languageType) => dispatch(HGActions.initGetItemDetailsAPI(menuitemId,languageType)),
    getAvocadoDetailsAPI: (languageType) => dispatch(HGActions.initGetAvocadoDetailsAPI(languageType)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    callCancelAPI: (TransactionInfo,languageType) => dispatch(HGActions.initCancelOrderAPI(TransactionInfo,languageType)),
    calculateDeliveryCharge: (addressId,customerId,locationId,totalAmount,languageId) => dispatch(HGActions.initDeliveryChargeAPI(addressId,customerId,locationId,totalAmount,languageId)),
    checkNextDayOrder: (customerId, comapanyId, locationId, time, languageId) => dispatch(HGActions.initCheckNextDayOrderAPI(customerId, comapanyId, locationId, time, languageId)),
    ApplyVoucherCode: (customerId,totalAmount,offerCode,orderType,OrderedItems,languageType) => dispatch(HGActions.initApplyVoucherCode(customerId,totalAmount,offerCode,orderType,OrderedItems,languageType)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDelivery);
import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import FooterMenu from '../Components/FooterMenu';
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import { Strings } from '../constants/Localization';
import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import baseConstants from '../constants/baseConstants';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';

let customerId = 0;
var mainObj;
class CardListing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            customerId: '',
            cardArray: [],
            errorMessage: '',
            languageType: 1, //default language type
        }

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                if (userObject != null) {
                    customerId = userObject.customerId;
                    console.log('login user :' + JSON.stringify(userObject));
                }
            }
        }
    }

    componentDidMount() {
        //Init animation duration
        AOS.init({
            duration: 1000
        })
        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        this.callCardListApi()
    }

    //This method use get card list api
    callCardListApi() {
        setTimeout(() => {
            console.log("call card list api :")
            this.props.getCardListApi(customerId, this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var cardList = this.props.data.result;
                    if (cardList != null && cardList.length > 0) {
                        this.setState({ cardArray: cardList })
                    }
                    else {
                        this.setState({ cardList: [], errorMessage: Strings.error_msg_no_card_react })
                    }
                }
                else {
                    this.setState({ cardList: [], errorMessage: this.props.error })
                }
            })
        }, 500);
    }

    //This method use handle card delete api
    deleteCardDetailClickEvent(cardId, index) {
        console.log("cardId  :" + cardId)
        // setTimeout(() => {
        //     this.prepareRequest(cardId, index)
        // }, 100)
    }
    //Prepare request and call api
    prepareRequest(cardId, index) {
        mainObj = {
            restaurantId: 147,
            customerId: customerId,
            languageId: this.state.languageType,
            locationId: JSON.parse(JSON.stringify(Constant.LOCATIONOBJECT)).locationId,
            CardId: cardId,
        }

        // setTimeout(() => {
        //     console.log("call card delete api :")
        //     this.props.cardDeleteApi(mainObj).then(() => {
        //         if (this.props.data.status == 1) {
        //             const list = this.state.cardArray;
        //             list.splice(index, 1);
        //             if (list != null && list.length > 0) {
        //                 this.setState({ cardArray: list })
        //             }
        //             else {
        //                 this.setState({ cardArray: [], errorMessage: Strings.error_msg_no_card_react })
        //             }
        //         }
        //         else {
        //             alert(this.props.error)
        //         }
        //     })
        // }, 500);
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my order page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        console.log('language type  :' + languageType);
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType });
                        window.location.reload(false);
                        // setTimeout(() => {
                        //     this.callCardListApi()
                        // }, 500)
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }


    render() {
        return (
            <div>
                {this.props.isLoading ? <div class="loadermain" style={{ display: 'block' }}>
                    <div class="loader" ></div>
                </div> : <div class="loadermain" style={{ display: 'none' }}>
                        <div class="loader" ></div>
                    </div>}

                <HeaderWhiteMenu
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/>

                <div class="content_wrap">
                    <div class="container">
                        <div class="all-order card-listing">
                            <div class="row">
                                <div class="col-md-12  col-lg-12">
                                    <h1>{Strings.card_details_web}</h1>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>{Strings.CardNumber_web}</th>
                                                <th>{Strings.card_brand_web}</th>
                                                <th>{Strings.card_type_web}</th>
                                                <th>{Strings.card_cat_web}</th>
                                            </tr>
                                        </thead>
                                        {
                                            this.setCardListView()
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }

    //Setting user card listing
    setCardListView() {
        if (this.state.cardArray != null && this.state.cardArray.length > 0) {
            {
                return (
                    this.state.cardArray.map((item, index) => (
                        <tbody key={index}>
                            <tr>
                                <td data-column="ORDER #">{item.cardNo.length > 0 ? item.cardNo : null}</td>
                                <td data-column="DATE">{item.cardBrand.length > 0 ? item.cardBrand : null}</td>
                                <td data-column="LOCATION">{item.cardType.length > 0 ? item.cardType : null}</td>
                                <td data-column="ORDER TYPE">{item.cardCategory.length > 0 ? item.cardCategory : null}</td>

                                <td data-column="view-ordr">
                                    <button
                                        onClick={() => {
                                            // localStorage.setItem(baseConstants.PRE_CARD_LIST, JSON.stringify(item))
                                            setSecureData(Constant.PRE_CARD_LIST, item)
                                            this.props.history.push({ pathname: '/CardDetails' })
                                        }}
                                        className="btn btn-primary btn-small order-btn">{Strings.view_card_details_web}</button>
                                    <button
                                        className="btn btn-primary btn-small order-btn">{Strings.delete_card_details_web}</button>
                                </td>
                            </tr>
                        </tbody>
                    ))
                )
            }
        }
        else {
            return (
                <tr style={{ textAlign: 'center' }} class="error-no-record">
                    <td colspan="5">
                        <h3>{this.state.errorMessage}</h3>
                    </td>
                </tr>
            )
        }
    }
}

function mapStateToProps(state) {
    console.log('isLoaging Order ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}
const mapDispatchToProps = dispatch => ({
    getCardListApi: (customerId, languageType) => dispatch(HGActions.callGetCardListApi(customerId, languageType)),
    cardDeleteApi: (mainObj) => dispatch(HGActions.callCardDeleteApi(mainObj)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),

})
export default connect(mapStateToProps, mapDispatchToProps)(CardListing);



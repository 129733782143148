import React, { Component } from 'react';

import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import { Strings } from '../constants/Localization';
import FooterMenu from '../Components/FooterMenu';
import Constant from '../constants/baseConstants';
import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import AOS from 'aos';
import 'aos/dist/aos.css';
import baseConstants from '../constants/baseConstants';
import {setSecureData, getSecureData} from '../Utils/utils';
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

var mainObj;
var waiterItem;

class WaiterDetails extends Component {

    constructor(props) {
        super(props)
        // waiterItem = localStorage.getItem(baseConstants.PRE_WAITER_ITEM);
        waiterItem = getSecureData(baseConstants.PRE_WAITER_ITEM);
        if (waiterItem != null) {
            // waiterItem = JSON.parse(waiterItem);
            console.log("waiterItem :" + JSON.stringify(waiterItem))
        }
        this.state = {
            customerId: 0,
            comment: '',
            waiterId: 0,
            waiterImage: '',
            ratingValue: 0,
            selectIndex: -1,
            languageType: 1, //default language type
            ratingArray: [{
                imagePath: 'images/1b.png',
                defaultRating: 1,
                isSelected: false,
            },
            {
                imagePath: 'images/2b.png',
                defaultRating: 2,
                isSelected: false,
            },
            {
                imagePath: 'images/3b.png',
                defaultRating: 3,
                isSelected: false,
            },
            {
                imagePath: 'images/4b.png',
                defaultRating: 4,
                isSelected: false,
            },
            {
                imagePath: 'images/5b.png',
                defaultRating: 5,
                isSelected: false,
            }],

        }
    }

    componentDidMount() {
        //Init animation duration
        AOS.init({
            duration: 1000
        })
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            console.log('userObject=' + userObject);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                this.setState({ waiterId: waiterItem.waiterId, waiterImage: waiterItem.thumbimgurl, customerId: userObject.customerId })
            }
        } else {
            this.props.history.push({ pathname: '/' })
                return
        }

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }
    }


    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my order page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        console.log('language type  :' + languageType);
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType });
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    //Handle validation message and call add rating api
    isValidate = (event) => {
        event.preventDefault();
        // var ratingErrors = this.state.selectValue.length == 1 ? Strings.select_rating_react : 0;
        // this.setState({ errors: { ratingError: ratingErrors } })
        setTimeout(() => {
            if (this.state.selectIndex == -1) {
                alert(Strings.select_rating_react)
            }
            else {
                this.prepareRequest()
            }
        }, 100)
    }

    //Call user add rating api
    prepareRequest() {
        mainObj = {
            restaurantId: 147,
            waiterId: this.state.waiterId,
            customerId: this.state.customerId,
            rating: this.state.ratingValue,
            comment: this.state.comment,
            // locationId: JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT)).locationId,
            locationId: getSecureData(Constant.LOCATIONOBJECT).locationId,
            languageId: this.state.languageType,
        }

        setTimeout(() => {
            this.props.waiterDetailApi(mainObj).then(() => {
                if (this.props.data.status == 1) {
                    if (this.props.data.result != null) {
                        setTimeout(() => {
                            let result = this.props.data.result;
                            if (result != null) {
                                alert(result.message)
                                this.props.history.push({ pathname: '/WaiterListing' })
                            }
                        }, 100)
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 100)
    }

    //bind rating bar view
    renderRatingBar() {
        return (
            this.state.ratingArray.map((item, index) => (
                <div onClick={() => { this.itemClickEvent(index, item) }} class="smiles-s">
                    {
                        this.itemViewRender(item, index)
                    }
                </div>
            ))
        )
    }

    //Handle rating bar click event
    itemClickEvent(index, object) {
        var tempArray = [];
        for (let k = 0; k < this.state.ratingArray.length; k++) {
            var item = this.state.ratingArray[k];
            if (k == index) {
                item.isSelected = true
            }
            else {
                item.isSelected = false
            }
            tempArray.push(item);
        }
        this.setState({ selectIndex: index, ratingArray: tempArray, ratingValue: object.defaultRating })
    }

    //Bind select and unselect image
    itemViewRender(item, index) {
        console.log("index  :" + index);
        switch (index) {
            case 0:
                if (item.isSelected) {
                    return (
                        <img src="images/ic_pink.png" alt=""></img>
                    )
                }
                else {
                    return (
                        <img src="images/1b.png" alt=""></img>
                    )
                }
                break
            case 1:
                if (item.isSelected) {
                    return (
                        <img src="images/ic_orange.png" alt=""></img>
                    )
                }
                else {
                    return (
                        <img src="images/2b.png" alt=""></img>
                    )
                }
                break
            case 2:
                if (item.isSelected) {
                    return (
                        <img src="images/ic_white.png" alt=""></img>
                    )
                }
                else {
                    return (
                        <img src="images/3b.png" alt=""></img>
                    )
                }
                break
            case 3:
                if (item.isSelected) {
                    return (
                        <img src="images/ic_dark_green.png" alt=""></img>
                    )
                }
                else {
                    return (
                        <img src="images/4b.png" alt=""></img>
                    )
                }
                break
            case 4:
                if (item.isSelected) {
                    return (
                        <img src="images/ic_green.png" alt=""></img>
                    )
                }
                else {
                    return (
                        <img src="images/5b.png" alt=""></img>
                    )
                }
                break
        }
    }

    render() {
        return (
            <div>
                {this.props.isLoading ? <div class="loadermain" style={{ display: 'block' }}>
                    <div class="loader" ></div>
                </div> : <div class="loadermain" style={{ display: 'none' }}>
                        <div class="loader" ></div>
                    </div>}
                <HeaderWhiteMenu
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/>

                {/* <h2>{this.props.match.params.id}</h2> */}
                <div class="content_wrap">
                    <div class="container">
                        {/* data-aos='fade-up' */}
                        <div class="signupwrap">
                            <div class="row">
                                <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                    <div class="form-group">

                                        <div style={{ textAlign: 'center' }} class="prd-img grl-img"><a href="">
                                            <img src={this.state.waiterImage != null && this.state.waiterImage.length > 0
                                                ? this.state.waiterImage : null} /></a></div>

                                        <div class="smiles">
                                            {
                                                this.renderRatingBar()
                                            }
                                        </div>

                                        <label style={{ marginTop: 10 }}>{Strings.lbl_comment_react}</label>
                                        <textarea type="text" class="form-control" maxLength={200} value={this.state.comment} onChange={e => this.setState({ comment: e.target.value })} tabIndex="1" />
                                    </div>
                                    <div style={{ textAlign: 'center' }} class="evaluation">
                                        <a href="#" class="btn btn-primary btn-big" onClick={this.isValidate} tabIndex="2">{Strings.str_post_comment_web}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }
}
function mapStateToProps(state) {
    console.log('isLoaging SignUp ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    waiterDetailApi: (mainObj) => dispatch(HGActions.callAddRatingApi(mainObj)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WaiterDetails);
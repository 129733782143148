import React, {Component} from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";

import HeaderMenu from '../Components/HeaderMenu';
import FooterMenu from '../Components/FooterMenu';

class CheckoutTakeaway extends Component {

    constructor(props) {
        super(props)

    }

    render() {
        return (
            <div>
                <header> 
                    <div class="container">
                        <div class="hg_logo"><a href="#"><img src="/images/logo.png" alt=""/></a></div>    
                        <div class="navigation"> <a href="#" class="lines-button x2"><span class="lines"></span></a>
                            <div class="main_menu">
                                <ul class="firstul">
                                    <li class="current_page_item"><a href="#">products </a></li>
                                    <li><a href="#">my account</a>
                                        <ul>
                                            <li><a href="#">Edit account</a></li>
                                            <li><a href="#">my orders</a></li>
                                            <li><a href="#">Logout</a></li>
                                        </ul>
                                    </li> 
                                </ul>
                            </div>
                            <div class="hg_cart"><a href="#"></a></div>
                            <div class="lang_drop">
                                <div class="dropdown">
                                    <a href="#" class="" type="button" data-toggle="dropdown">ES <span class="caret"></span></a>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="javascript:void(0);">English</a></li>
                                        <li><a class="dropdown-item" href="javascript:void(0);">English</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0);">English</a></li>
                                    </ul>
                                </div>  
                            </div> 
                        </div> 
                    </div>
                </header> 

                <div class="content_wrap">
                    <div class="container">
                        <div class="checkout">

                            <div class="row">
                            <div class="col-md-12  col-lg-8 left">
                                    <h1>Check out</h1>

                                <div class="check-div">
                                    <div class="row single-prod">

                                    <div class="prod-img col-md-4"><img src="/images/p1.png"/></div>
                                    <div class="prod-img col-md-8">
                                        <h2>Ginger Goat's Cheese Crunch</h2>
                                        <p>Pear,Sweet Potato,Roasted Red Onion,Green Beans,Dried Cranberries,Goats Cheese,Caramelised Walnuts</p>
                                        <p>Regular</p>
                                        <div class="quontity">Quantity:
                                        <div class="form-group"><input type="text" class="form-control" placeholder="1"/></div> 
                                                    <div class="price">€6.90</div></div>
                                        <div class="buttons-checkout">
                                                
                                                <a href="#" class="btn btn-medium trans-btn">erase</a>   
                                                <a href="#" class="btn btn-primary btn-medium">EDIT</a>     

                                                </div>


                                    </div>
                                    </div>
                                    <div class="row single-prod">
                                    <div class="prod-img col-md-4"><img src="/images/p2.png"/></div>
                                    <div class="prod-img col-md-8">
                                        <h2>Ginger Goat's Cheese Crunch</h2>
                                        
                                        <div class="quontity">Quantity:
                                        <div class="form-group"><input type="text" class="form-control" placeholder="1"/></div> 
                                                    <div class="price">€6.90</div></div>
                                        <div class="buttons-checkout">
                                                
                                                <a href="#" class="btn btn-medium trans-btn">erase</a>   
                                                

                                                </div>


                                    </div>
                                    </div>
                                    <div class="row more-prod">
                                    <div class="prod-img col-md-4"></div>
                                    <div class="prod-img col-md-8">
                                            <div class="buttons-checkout">
                                                    <a href="#" class="btn btn-primary btn-medium">Add more products</a>     
                                            </div>
                                    </div>
                                    </div>


                                    </div>
                                </div>

                            
                            

                            <div class="col-md-12  col-lg-4 right">
                                        <h1>Select your option</h1>
                                        
                                        <div class="delivery-div">
                                            <div class="radio-div">
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" class="custom-control-input" name="customRadio" id="customRadio1"/>
                                                    <label class="custom-control-label" for="customRadio1">Delivery</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" class="custom-control-input" name="customRadio" id="customRadio2" checked/>
                                                    <label class="custom-control-label" for="customRadio2">Takeaway</label>
                                                </div>
                                            </div>
                                            
                                            
                                    

                                        <div class="form-group">
                                                    <label>Delivery Address:</label>
                                                    <div class="form-group btn p-0 select">
                                                        <div class="filtering">
                                                        <div class="filter-cat row">
                                                            <select class="chosen-select">
                                                            <option VALUE=" ">Select Location </option>
                                                            <option VALUE="cat-blue">Select Location</option>
                                                            <option VALUE=" ">Select Location</option>
                                                            
                                                            </select>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                <div class="form-group">
                                                    
                                                    
                                                    <p class="error">Please choose an Location</p>
                                                </div>
                                                <div class="radio-div">
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" class="custom-control-input" name="customRadio1" id="customRadio3" checked/>
                                                    <label class="custom-control-label" for="customRadio3">Order Now</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" class="custom-control-input" name="customRadio1" id="customRadio4" />
                                                    <label class="custom-control-label" for="customRadio4">Order Leter</label>
                                                </div>

                                            </div>
                                            
                                </div> 

                                <div class="total-div">
                                
                                <div class="row">                
                                    <div class="col1">Subtotal:</div>
                                    <div class="col2">10.80 €</div>
                                </div>
                                    <div class="row">                
                                    <div class="col1">Discount (50%):</div>
                                    <div class="col2">5.40 €</div>
                                </div>
                                
                                    <div class="row">                
                                    <div class="col1">Biodegradable Packaging:</div>
                                    <div class="col2">0 €</div>
                                </div>
                                <div class="row total-last">                
                                    <div class="col1">Total:</div>
                                    <div class="col2">15,20€</div>
                                </div>



                                </div>
                                <div class="row checkout-div">
                                    
                                    <div class="prod-img col-md-12">
                                            <div class="buttons-checkout">
                                                    <a href="#" class="btn btn-primary btn-medium">Checkout</a>     
                                            </div>
                                    </div>
                                    </div>
                                    </div>      

                            </div> 
                            {/* <!--row end--> */}
                        </div>    
                    </div>        
                </div>

                <FooterMenu/>

            </div>
        )
    }

}

export default CheckoutTakeaway;
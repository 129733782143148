import React, {Component} from 'react';
import Constant from '../constants/baseConstants';
import {setSecureData, getSecureData} from '../Utils/utils';

class Logout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
        }
        localStorage.removeItem(Constant.USEROBJECT);
        localStorage.removeItem(Constant.USERCART);
        localStorage.removeItem(Constant.CATEGORIESDATA);
        localStorage.removeItem(Constant.LOCATIONOBJECT);
        localStorage.removeItem(Constant.LOCATIONLISTOBJECT);
        localStorage.removeItem(Constant.ORDERTYPE);
        localStorage.removeItem(Constant.CHECKINFO);
        localStorage.removeItem(Constant.PRE_WAITER_ITEM)

        this.props.history.push('/')
    }
    render() {
        return(
            <div className="loginpage white" style={{}}>
                {this.state.isLoading ? <div className="loadermain" style={{display:'block'}}>
                    <div className="loader" ></div>
                    </div> : <div className="loadermain" style={{display:'none'}}>
                    <div className="loader" ></div>
                    </div>}
            </div>
        )
    }
}
export default Logout;
import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";

import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constants from '../constants/baseConstants';
import Modal from 'react-bootstrap/Modal'
import AlertDialog from '../Components/AlertDialog';
import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import {setSecureData, getSecureData, bindhttps} from '../Utils/utils';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

class Categories extends Component {

    constructor(props) {
        super(props)
        this.state = {
            arrayCategories: [],
            locationArray: [],
            isVisible: false,
            selectIndex: -1,
            orderType: false,
            delivery: false,
            takeaway: false,
            locationObj: null,

            selectedCategoryIndex: 0,
            isOrderTypeVisible: false,
            arrayOrderTypes: [],

            languageType: 1,


            isAlertVisible: false,
            isMultiButtom: false,
            alertMessage: '',
            btnLeft:'',
            btnRight:'',
            alertType: 0,
        }
    }

    componentDidMount() {
        // AOS.init({
        //     duration : 1000
        // })

        if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
            // let userObject = localStorage.getItem(Constants.USEROBJECT);
            let userObject = getSecureData(Constants.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
        }
        else {
            this.props.history.push({ pathname: '/' })
            return
        }

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constants.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            } else {
                if (mLanguageType != null) {    
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        setTimeout(() => {
            this.callLocationListingAPI()
        }, 200)
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("category page language type :" + type);
        // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constants.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constants.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constants.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constants.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constants.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constants.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                        setTimeout(() => {
                            this.callLocationListingAPI()
                        }, 200)
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    onClick(i) {
        // let locationObject = JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT))
        let locationObject = getSecureData(Constants.LOCATIONOBJECT)
        if (locationObject == null || locationObject == undefined) {
            alert(Strings.error_no_data)
            return
        }
        var arry = []
        if (locationObject.isDelivery) {
            arry.push({ title: Strings.Delivery_web, isSelect: false })
        }
        if (locationObject.isTakeAway) {
            arry.push({ title: Strings.lbl_takeaway_react, isSelect: false })
        }
        //check ordertypes selected or not
        if (getSecureData(Constants.ORDERTYPE) == null || getSecureData(Constants.ORDERTYPE) == undefined) {
            this.setState({ isOrderTypeVisible: true,  arrayOrderTypes: arry, selectedCategoryIndex: i })
        } else {
            this.setState({selectedCategoryIndex: i})
            setTimeout(() => {
                setSecureData(Constants.PRE_CAT_ID, this.state.arrayCategories[this.state.selectedCategoryIndex].catId)
                this.props.history.push({ pathname: '/MenuItems', catId: this.state.arrayCategories[this.state.selectedCategoryIndex].catId, Categorieslist: this.state.arrayCategories })                
            }, 200);
        }
    }

    callLocationListingAPI() {

        if (this.props.location.isChangeLocation) {
            this.fetchLocationList()
        } else {
            //check location is selected or not
            if (getSecureData(Constants.LOCATIONOBJECT)) {
                let locationObject = getSecureData(Constants.LOCATIONOBJECT)
                var arry = []
                if (locationObject.isDelivery) {
                    arry.push({ title: Strings.Delivery_web, isSelect: false })
                }
                if (locationObject.isTakeAway) {
                    arry.push({ title: Strings.lbl_takeaway_react, isSelect: false })
                }
                this.setState({ isVisible: false, arrayOrderTypes: arry })
                
                setTimeout(() => {
                    console.log('Call category api ....')
                    this.callGetCategoryListApi()
                }, 200);

            } else {
                this.fetchLocationList()
            }
        }
        
    }


    fetchLocationList() {
        this.props.locationListApi(this.state.languageType).then(() => {
            if (this.props.data.status == 1) {
                if (this.props.data.result != null) {
                    var locationList = this.props.data.result;
                    if (locationList != null && locationList.length > 0) {
                        // localStorage.setItem(Constants.LOCATIONLISTOBJECT, JSON.stringify(locationList))
                        setSecureData(Constants.LOCATIONLISTOBJECT,locationList)
                        this.setState({ locationArray: locationList, isVisible: true })
                    }
                }
            } else {
                    alert(this.props.data.message)
            }
        })
    }

    callGetCategoryListApi() {
        this.props.getCategoriesAPICall(this.state.languageType).then(() => {
            if (this.props.data.status == 1) {
                if (this.props.data.result != null) {
                    setTimeout(() => {
                        if (this.props.data.result.length > 0) {
                            this.setState({ arrayCategories: this.props.data.result })
                            // localStorage.setItem(Constants.CATEGORIESDATA,JSON.stringify(this.props.data.result))
                            setSecureData(Constants.CATEGORIESDATA,this.props.data.result)
                        }
                    }, 100)
                }
            } else {
                    alert(this.props.data.message)
            }
        }).then(()=>{
            console.log('Category error then ---- ',this.props.error)
        })
    }

    setLocationData() {
        setTimeout(() => {
            if (this.state.locationObj == null) {
            // if (item == null) {
                // alert(Strings.lbl_select_location_react)
            } else {
                
                // localStorage.setItem(Constants.LOCATIONOBJECT, JSON.stringify(this.state.locationObj))
                setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj)
                // setSecureData(Constants.LOCATIONOBJECT, item)
                Constants.locationId = this.state.locationObj.locationId
                // Constants.locationId = item.locationId
    
                let locationObject =this.state.locationObj
                var arry = []
                if (locationObject.isDelivery) {
                    arry.push({ title: Strings.Delivery_web, isSelect: false })
                }
                if (locationObject.isTakeAway) {
                    arry.push({ title: Strings.lbl_takeaway_react, isSelect: false })
                }
                this.setState({ isVisible: false, arrayOrderTypes: arry},()=>{
                    this.callGetCategoryListApi()
                })
                
                // setTimeout(() => {
                //     this.callGetCategoryListApi()
                // }, 500);
            }
        }, 200);
    }

    onContinue = (item,index) =>{

        if (getSecureData(Constants.USERCART)) {
            console.log('location object  ----------- ',getSecureData(Constants.LOCATIONOBJECT))
            console.log('select location object  ----------- ',item)

            if (getSecureData(Constants.LOCATIONOBJECT).locationId != item.locationId) {
                this.setState({isVisible:false, selectIndex: index, locationObj: item, orderType: true, isAlertVisible:true, alertMessage:Strings.empty_cart_message, isMultiButtom:true, btnLeft:Strings.btn_cancel, btnRight:Strings.btn_confirm, })
                
            } else {

                this.setState({ selectIndex: index, locationObj: item, orderType: true },()=>{
                    this.setLocationData()
                })               
                
            }

        } else {

            this.setState({ selectIndex: index, locationObj: item, orderType: true },()=>{
                this.setLocationData()
            })
            
        }
        
    }

    onContinueOrder(order) {
        if (order.length > 0) {
            if (order[0].title == Strings.Delivery_web) {
                Constants.OrderType = 'Delivery'//Strings.Delivery_web
            } else if (order[0].title == Strings.lbl_takeaway_react) {
                Constants.OrderType = 'TakeAway'//Strings.lbl_takeaway_react
            }
        }
        // localStorage.setItem(Constants.ORDERTYPE, JSON.stringify(Constants.OrderType))
        setSecureData(Constants.ORDERTYPE, Constants.OrderType)
        // return
        this.setState({ isOrderTypeVisible: false })
        // localStorage.setItem(Constants.PRE_CAT_ID, JSON.stringify(this.state.arrayCategories[this.state.selectedCategoryIndex].catId))
        setSecureData(Constants.PRE_CAT_ID, this.state.arrayCategories[this.state.selectedCategoryIndex].catId)
        this.props.history.push({ pathname: '/MenuItems', catId: this.state.arrayCategories[this.state.selectedCategoryIndex].catId, Categorieslist: this.state.arrayCategories })
    }

    //This method use show location dialog
    showLocationListDialog() {
        if(!this.state.isVisible) {return}
        if (this.state.locationArray != null && this.state.locationArray.length > 0) {
            return (
                <Modal
                    show={this.state.isVisible}
                    onHide={()=>{}}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    
                    <Modal.Body>
                    <div className="popupHeader"><label style={{ textAlign: 'center' }}>{Strings.lbl_select_location_react}</label></div>
                        {/* <!-- The pop --> */}
                        <div className="pop loc-popup" id="mypop">
                            <div className="pop-dialog">
                                <div className="pop-content">
                                    <div className="pop-body">
                                        <div className="prd-s">
                                            <div className="prd-cont">
                                                {
                                                    this.setLocationList(this.state.locationArray)
                                                }
                                            </div>
                                        </div>

                                        {/* {this.state.locationObj != null ? (
                                            <div className="continue-btn"> <button className="btn btn-primary btn-small order-btn" onClick={() => { this.onContinue() }}>{Strings.btn_continue_react}</button></div>
                                        ) : null} */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        }
        else {
            return;
        }
    }

    onSelectTypes(order) {
        this.state.arrayOrderTypes.map(data => {
            if (data.title == order.title) {
                data.isSelect = true
                Constants.OrderType = data.title
            } else {
                data.isSelect = false
            }
        })
        this.setState({ arrayOrderTypes: this.state.arrayOrderTypes })

        let obj = this.state.arrayOrderTypes.filter((data) => (data.isSelect == true))

        if (obj.length > 0) {
            this.onContinueOrder(obj)
            // Constants.OrderType = obj[0].title
            // console.log('Constants.OrderType = obj[0].title --------- ', Constants.OrderType)
            // return (
            //     <div className="continue-btn">
            //         <button className="btn btn-primary btn-small order-btn" onClick={() => { this.onContinueOrder(obj) }}>{Strings.btn_continue_react}</button>
            //     </div>
            // )
        }
    }

    renderOrderType() {
        if (this.state.arrayOrderTypes.length == 0) {
            return (<label>{Strings.error_msg_no_order_type_react}</label>)
        }
        var arry = []
        this.state.arrayOrderTypes.map((data,index) => {
            arry.push(
            // <div>
            //     <div className="chek-new" onClick={() => { this.onSelectTypes(data) }}>
            //         <label className="container1">
            //             <h3>{data.title}</h3>
            //             <input
            //                 type="checkbox"
            //                 checked="checked" />
            //             <span className={data.isSelect ? "checkmark" : ''}></span>
            //         </label>
            //     </div>
            // </div>
                <div className="optList" onClick={() => { this.onSelectTypes(data) }} key={index}>
                    <h3>{data.title}</h3>
                </div>
            )
        })
        return arry
    }

    renderButton() {

        let obj = this.state.arrayOrderTypes.filter((data) => (data.isSelect == true))
        if (obj.length > 0) {
            Constants.OrderType = obj[0].title
            return (
                <div className="continue-btn">
                    <button className="btn btn-primary btn-small order-btn" onClick={() => { this.onContinueOrder(obj) }}>{Strings.btn_continue_react}</button>
                </div>
            )
        }
    }

    renderOrderSelectionView() {

        return (
            <Modal
                show={this.state.isOrderTypeVisible}
                onHide={()=>{}}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Body>
                    <div className="popupHeader">
                        <label style={{ textAlign: 'center' }}>{Strings.lbl_select_order_type_react}</label>
                        <button className="btnClose" onClick={() => { this.setState({ isOrderTypeVisible: false }) }}>X</button>
                    </div>
                    <div className="pop loc-popup" id="mypop">
                        <div className="pop-dialog">
                            <div className="pop-content">
                                {/* <div className="modal-header modal-header-wht">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => { this.setState({ isOrderTypeVisible: false }) }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div> */}
                                <div className="pop-body">
                                    <div className="prd-s">
                                        {/* <p style={{ textAlign: 'center' }}>{Strings.lbl_select_order_type_react}</p> */}
                                        <div className="prd-cont">
                                            {
                                                this.renderOrderType()
                                            }
                                        </div>
                                    </div>
                                    {this.renderButton()}

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )

    }

    //This method use bind location data
    setLocationList(itemData) {
        if (itemData != null && itemData.length > 0) {
            return (
                itemData.map((item, index) => (
                    <div key={index}>
                        <div className="chek-new" onClick={() => {
                            this.onContinue(item,index)
                        }}>
                            <label className="container1">
                                <h3>{item.locationName}</h3>
                                <p>{item.distance > 0 ? item.distance : null}</p>
                                {/* <input
                                    type="checkbox"
                                    defaultChecked={true} />
                                <span className={this.state.selectIndex == index ? "checkmark" : ''}></span> */}
                            </label>
                        </div>
                    </div>
                ))
            )
        }
    }

    renderCategoriesData() {
        var arrayData = []
        for (var i = 0; i < this.state.arrayCategories.length; i++) {
            if (i == 0)
                arrayData.push(
                    <div className="product-single mp" key={i}>
                        <div className="ps-inn">
                            {/* 0 */}
                            <a href="#" id="#forgotmodal" onClick={() => { this.onClick(2) }}>
                                {/* this.props.history.push({pathname: '/MobileVerification',customerId: result.customerId,phone:this.state.countryCode+this.state.phoneNumber,message:this.props.data.message}) */}
                                {/* <img src={this.state.arrayCategories[2].fullimgurl.replace('http','http')}/> */}
                                <img src={bindhttps(this.state.arrayCategories[2].fullimgurl)}/>
                                <h2>{this.state.arrayCategories[2].catName}</h2>
                            </a>
                        </div>
                    </div>
                )

            else if (i == 1)
                arrayData.push(
                    <div className="product-single dr" key={i}>
                        <div className="ps-inn">
                        {/* 1 */}
                            <a href="#" id="#forgotmodal" onClick={() => { this.onClick(3) }}>
                                {/* <img src={this.state.arrayCategories[3].fullimgurl.replace('http','http')}/> */}
                                <img src={bindhttps(this.state.arrayCategories[3].fullimgurl)}/>
                                <h2>{this.state.arrayCategories[3].catName}</h2>
                            </a>
                        </div>
                    </div>
                )

            else if (i == 2)
                arrayData.push(
                    <div className="product-single ds" key={i}>
                        <div className="ps-inn">
                        {/* 2 */}
                            <a href="#" id="#forgotmodal" onClick={() => { this.onClick(5) }}>
                                {/* <img src={this.state.arrayCategories[5].fullimgurl.replace('http','http')}/> */}
                                <img src={bindhttps(this.state.arrayCategories[5].fullimgurl)}/>
                                <h2>{this.state.arrayCategories[5].catName}</h2>
                            </a>
                        </div>
                    </div>
                )

            else if (i == 3)
                arrayData.push(
                    <div className="product-single br" key={i}>
                        <div className="ps-inn">
                        {/* 3 */}
                            <a href="#" id="#forgotmodal" onClick={() => { this.onClick(0) }}>
                                {/* <img src={this.state.arrayCategories[0].fullimgurl.replace('http','http')} /> */}
                                <img src={bindhttps(this.state.arrayCategories[0].fullimgurl)}/>
                                <h2>{this.state.arrayCategories[0].catName}</h2>
                            </a>
                        </div>
                    </div>
                )

            else if (i == 4)
                arrayData.push(
                    <div className="product-single gb" key={i}>
                        <div className="ps-inn">
                        {/* 4 */}
                            <a href="#" id="#forgotmodal" onClick={() => { this.onClick(1) }}>
                                {/* <img src={this.state.arrayCategories[1].fullimgurl.replace('http','http')} /> */}
                                <img src={bindhttps(this.state.arrayCategories[1].fullimgurl)}/>
                                <h2>{this.state.arrayCategories[1].catName}</h2>
                            </a>
                        </div>
                    </div>
                )
        }
        return arrayData
    }

    onLeftClick() {
        this.setState({isAlertVisible:false,isVisible:true})
    }

    onRightClick() {

        localStorage.removeItem(Constants.USERCART)
        this.setLocationData()
        setTimeout(() => {
            this.setState({isAlertVisible:false})
        }, 300);

    }


    render() {
        return (
            <div>
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                </div>}
                <HeaderWhiteMenu changeLanguageEvent={this.languageChangeClickEvent} isCategory={true} onChangeLocation={()=>this.onRightClick()}/>
                <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={this.state.isMultiButtom} btnLeftClick={()=>{this.onLeftClick()}} btnRightClick={()=>{this.onRightClick()}} btnLeftTitle={this.state.btnLeft} btnRightTitle={this.state.btnRight} />
                { this.state.isVisible ? this.showLocationListDialog() : null }

                {this.state.isOrderTypeVisible ? this.renderOrderSelectionView() : null}

                <div className="content_wrap design-back">
                    <div className="container">
                        <div className="all-products">
                            <div className="row">
                                <div className="col-md-12  col-lg-12">
                                    
                                    <h1>{Strings.lbl_choose_react} <br/> {Strings.lbl_a_product_react}</h1>
                                    <div className="row">
                                        {this.renderCategoriesData()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterMenu/>
            </div>
        )
    }

}

function mapStateToProps(state) {
    console.log('isLoaging Categories ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    getCategoriesAPICall: (languageType) => dispatch(HGActions.initGetAllCategoriesCall(languageType)),
    locationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Categories);